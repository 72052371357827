<template>
  <div>
    <b-form @keydown.enter.prevent @submit.prevent="submitForm">
      <b-form-group label="Label">
        <b-form-input type="text" v-model="label" maxlength="200" placeholder="optional"></b-form-input>
      </b-form-group>
      <div class="text-right">
        <b-button type="submit" variant="primary" @click="beforeSubmit" :disabled="isSubmitting">Save</b-button>
      </div>
    </b-form>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
  import _ from "lodash";
  import BaseComponent from "@/components/BaseComponent";
  import * as savedPresetsService from "@/services/savedPresetsService";

  @Component
  export default class CreateNewPresetModal extends BaseComponent {

    @Prop({ required: true }) modalId!: string;

    label = "";
    isSubmitting = false;
    formErrorMsg = "";

    mounted() {

    }

    beforeSubmit() {
      this.label = this.label.trim();
    }

    async submitForm() {
      try {
        this.isSubmitting = true;
        await savedPresetsService.createNew(this.label);
        this.$bvModal.hide(this.modalId);
        this.done();

      } catch (e) {
        this.formErrorMsg = e.message;

      } finally {
        this.isSubmitting = false;
      }
    }

    @Emit()
    done() {

    }

  }
</script>
