import axios from "@/axios";
import * as authService from "@/services/authService";
import * as constants from "@/constants";

export async function getCexMarkets(params) {
  return (await axios.get("/cexMarkets", { params })).data.data;
}

export async function getCexAssets(params) {
  return (await axios.get("/cexAssets/all", { params })).data.data;
}

export async function getTickers(exchange: string, symbol?: string) {
  const params = { exchange, symbol };
  return (await axios.get("/cexSpotMarket/tickers", { params })).data.data;
}

export async function getDexPools(chainId: number, tokenAddress: string) {
  const params = { chainId, tokenAddress };
  return (await axios.get("/dexPool/getPools", {
    params
  })).data.data;
}

export async function getNativePrices() {
  return (await axios.get("/dexToken/nativePrices")).data.data;
}
