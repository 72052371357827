import { render, staticRenderFns } from "./QuickSetupDelistTradeOrdersModal.vue?vue&type=template&id=653935dd&scoped=true"
import script from "./QuickSetupDelistTradeOrdersModal.vue?vue&type=script&lang=ts"
export * from "./QuickSetupDelistTradeOrdersModal.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "653935dd",
  null
  
)

export default component.exports