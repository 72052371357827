import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://arb-trader.coinmatic.app/api/1inch-api-wrapper/swap/v6.0",
  timeout: 5000,
});

export async function getQuote(chainId: number, params: any) {
  return (await axiosInstance.get(`/${chainId}/quote`, { params })).data;
}


export async function getSwap(chainId: number, params: any) {
  const ret = (await axiosInstance.get(`/${chainId}/swap`, { params })).data;
  ret.srcAmount = params.amount;
  return ret;
}

export async function getApproveTransaction(chainId: number, tokenAddress: string, revoke = false) {
  const params: any = { tokenAddress };
  if (revoke) {
    params.amount = 0;
  }
  return (await axiosInstance.get(`/${chainId}/approve/transaction`, { params })).data;
}
