<template>
  <main>
    <b-container class="py-4">
      <b-form class="d-flex flex-row flex-wrap align-items-end mb-3" @submit.prevent="fetchPoolMetadata">
        <b-form-group class="flex-shrink-0 mb-0 mr-3" label="Chain">
          <b-form-select :options="chainOptions" v-model="chainId" required :disabled="isFormLocked"></b-form-select>
        </b-form-group>
<!--        <div>
          <label>Wallet</label>
          <v-select class="vs-normalizer mb-3" :hidden="!!selectedWallet"
                    label="label"
                    :getOptionKey="w => w.address"
                    :filter="addressSelectDropdownFilter"
                    :options="savedWallets"
                    :clearSearchOnBlur="() => false"
                    v-on:option:selecting="onPickSavedWallet">
            <template v-slot:search="{attributes, events}">
              <input v-bind="attributes" v-on="events" v-model="address" autocomplete="off"
                     class="form-control text-monospace" required/>
            </template>
            <template v-slot:option="w">
              <div class="py-1 border-bottom">
                <div class="text-secondary">{{ w.label }}</div>
                <div class="text-monospace text-break">{{ w.address }}</div>
              </div>
            </template>
          </v-select>
        </div>-->
        <b-form-group class="flex-shrink-0 mb-0 mr-3" style="width: 300px;">
          <div>
            <label>Wallet</label>
            ·
            <router-link to="/private-key-wallets" class="p-0">Manage</router-link>
          </div>
          <b-form-select :options="privateKeyWallets" v-model="walletAddress" required :disabled="isFormLocked" />
        </b-form-group>
        <b-form-group class="flex-grow-1 mb-0 mr-3" style="min-width: 360px;" label="Pool address">
          <b-form-input type="text" class="text-monospace" v-model="poolAddress" required :disabled="isFormLocked" />
        </b-form-group>
        <div>
          <b-button type="submit" class="flex-shrink-0" variant="primary" :disabled="isFormLocked">OK</b-button>
        </div>
      </b-form>
      <template v-if="poolMetadata">
        <b-row class="mb-4">
          <b-col lg="3">
            <div class="text-secondary">Token</div>
            <div>
              <a :href="poolMetadata.share | explorerUrl(chainId, 'token')" target="_blank">
                {{ poolMetadata.shareTokenInfo.name }} ({{ poolMetadata.shareTokenInfo.symbol }})
              </a>
            </div>
          </b-col>
          <b-col lg="3">
            <div class="text-secondary">Raise Asset</div>
            <div>
              <a :href="poolMetadata.asset | explorerUrl(chainId, 'token')" target="_blank">
                {{ poolMetadata.assetTokenInfo.name }} ({{ poolMetadata.assetTokenInfo.symbol }})
              </a>
            </div>
          </b-col>
          <b-col lg="3">
            <div class="text-secondary">
              Price {{ poolMetadata.assetTokenInfo.symbol }}
              <b-spinner small v-if="isFetchingPrices" />
            </div>
            <div class="cursor-pointer" @click="inputTriggerPrice = displayPrice">{{ displayPrice }}</div>
          </b-col>
          <b-col lg="3">
            <div class="text-secondary">Current balances</div>
            <div>
              <a :href="walletAddress | explorerUrl(chainId, 'address')" target="_blank">
                {{ displayWalletNativeBalance }} {{ nativeAssetSymbol }}
              </a>
            </div>
            <div>{{ displayWalletAssetBalance }} {{ poolMetadata.assetTokenInfo.symbol }}</div>
            <div>{{ displayPurchasedShares }} {{ poolMetadata.shareTokenInfo.symbol }}</div>
          </b-col>
<!--          <b-col cols="3">
            <label class="text-secondary">Sale end</label>
            <div>{{ displaySaleEnd }}</div>
          </b-col>-->
        </b-row>
        <b-form class="mb-4" @submit.prevent="checkBeforeStart">
          <b-row class="mb-4">
            <b-col lg="3">
              <b-form-group :label="'Input ' + poolMetadata.assetTokenInfo.symbol">
                <b-form-input type="number" :min="assetAmountStep" :step="assetAmountStep" required v-model="inputAssetAmount" @input="inputAmountChangedDebounced" :disabled="amountFormInputsDisabled" />
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <div class="text-secondary">Output {{ poolMetadata.shareTokenInfo.symbol }}</div>
              <div>{{ displayOutputShareAmount }}</div>
            </b-col>
            <b-col lg="3">
              <div class="text-secondary">Trade price</div>
              <div class="cursor-pointer" @click="inputTriggerPrice = displayTradePrice">
                1 {{ poolMetadata.shareTokenInfo.symbol }} = {{ displayTradePrice }} {{ poolMetadata.assetTokenInfo.symbol }}
              </div>
              <div>1 {{ poolMetadata.assetTokenInfo.symbol }} = {{ displayInverseTradePrice }} {{ poolMetadata.shareTokenInfo.symbol }}</div>
            </b-col>
            <b-col lg="3">
              <div class="text-secondary">Price impact</div>
              <div>{{ displayPriceImpact }}</div>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col lg="3">
              <b-form-group :label="'Trigger price ' + poolMetadata.assetTokenInfo.symbol" class="m-0">
                <b-form-input type="number" :min="assetAmountStep" step="1e-18" required v-model="inputTriggerPrice" :disabled="amountFormInputsDisabled" />
              </b-form-group>
            </b-col>
            <b-col lg="2">
              <b-form-group label="Slippage" class="m-0">
                <b-input-group>
                  <b-form-input type="number" min="0" step="0.01" max="50" required v-model="inputSlippageTolerance" :disabled="amountFormInputsDisabled" />
                  <b-input-group-append is-text>%</b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group class="m-0">
                <div class="d-flex flex-row align-items-start justify-content-between">
                  <label>Total spend {{ poolMetadata.assetTokenInfo.symbol }}</label>
                  <b-button variant="link" class="p-0" @click="onClickMaxSpend">Max</b-button>
                </div>
                <b-form-input type="number" :min="assetAmountStep" :step="assetAmountStep" required v-model="inputTotalSpend" :disabled="amountFormInputsDisabled" />
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group label="Gas price multiplier" class="m-0">
                <b-form-input type="number" min="1" step="0.01" max="15" required v-model="inputGasPriceMultiplier" :disabled="amountFormInputsDisabled" />
              </b-form-group>
            </b-col>
            <b-col lg="1">
              <span v-if="isWatchingAndBuying" class="btn btn-danger" style="margin-top: 31px" @click="onClickStop">Stop</span>
              <b-button v-else type="submit" variant="primary" style="margin-top: 31px" :disabled="amountFormInputsDisabled">Start</b-button>
            </b-col>
          </b-row>
          <div v-if="checkErrorMsg" class="d-flex flex-row align-items-center border border-danger p-2">
            <div class="flex-grow-1 mr-2">
              <div class="text-danger text-break">{{ checkErrorMsg }}</div>
              <div v-if="showApprove">
                <b-button variant="link" class="p-0 mr-4" @click="onClickApproveTotalSpend">Approve total spend</b-button>
                <b-button variant="link" class="p-0 mr-4" @click="onClickApproveUnlimited">Approve unlimited</b-button>
                <b-button variant="link" class="p-0 text-danger" @click="onClickRevoke">Revoke</b-button>
              </div>
            </div>
            <div class="flex-shrink-0">
              <b-button variant="link" class="p-0 text-danger" @click="checkErrorMsg = ''"><b-icon-x-circle /></b-button>
            </div>
          </div>
        </b-form>
        <label>Trades</label>
        <b-row class="py-2 bg-hover align-items-center border-bottom">
          <b-col cols="3">Date</b-col>
          <b-col cols="1">Type</b-col>
          <b-col cols="2">{{ poolMetadata.shareTokenInfo.symbol }}</b-col>
          <b-col cols="2">{{ poolMetadata.assetTokenInfo.symbol }}</b-col>
          <b-col cols="2">Price</b-col>
          <b-col cols="2">Tx fee {{ nativeAssetSymbol }}</b-col>
        </b-row>
        <b-row v-for="trade in trades" :key="trade.sendAt" class="py-2 bg-hover align-items-center border-bottom">
          <template v-if="trade.txHash">
            <b-col cols="3" class="overflow-hidden">
              <a :href="trade.txHash | explorerUrl(chainId, 'tx')" target="_blank">{{ trade.displayDateTime }}</a>
            </b-col>
            <b-col cols="1" class="overflow-hidden">{{ trade.displayType }}</b-col>
            <b-col cols="2" class="overflow-hidden">{{ trade.displayShareAmount }} <b-spinner v-if="!trade.displayShareAmount" small/></b-col>
            <b-col cols="2" class="overflow-hidden">{{ trade.displayAssetAmount }} <b-spinner v-if="!trade.displayAssetAmount" small/></b-col>
            <b-col cols="2" class="overflow-hidden">{{ trade.displayPrice }} <b-spinner v-if="!trade.displayPrice" small/></b-col>
            <b-col cols="2" class="overflow-hidden">{{ trade.displayTxFee }} <b-spinner v-if="!trade.displayTxFee" small/></b-col>
          </template>
        </b-row>
      </template>
      <b-modal id="tx-request-modal" hide-header hide-footer no-fade no-close-on-backdrop>
        <TransactionRequestModal modalId="tx-request-modal" :transactionRequest="txRequestModalData">
          {{ txRequestModalMessage }}
        </TransactionRequestModal>
      </b-modal>
    </b-container>
  </main>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import {Component, Vue} from 'vue-property-decorator';
  import _ from "lodash";
  import {ethers, Wallet} from "ethers";
  import BaseComponent from "@/components/BaseComponent";
  import * as constants from "@/constants";
  import * as web3Service from "@/services/web3Service";
  import * as privateKeyWalletService from "@/services/privateKeyWalletService";
  import * as liquidityBootstrapPoolService from "@/services/liquidityBootstrapPoolService";
  import * as utils from "@/utils";
  import {DateTime} from "luxon";
  import BigNumber from "bignumber.js";
  import TransactionRequestModal from "@/components/private-key-wallets/TransactionRequestModal.vue";
  import {TransactionRequest} from "ethers";

  @Component({
    components: {TransactionRequestModal}
  })
  export default class WatchSwapLiquidityBootstrapPool extends BaseComponent {

    chainOptions = [
      constants.CHAIN_ID_ETH,
      constants.CHAIN_ID_BSC,
      constants.CHAIN_ID_POLYGON,
      constants.CHAIN_ID_ARBITRUM,
      constants.CHAIN_ID_OPTIMISM,
      constants.CHAIN_ID_BASE,
      constants.CHAIN_ID_FANTOM,
    ].map(chainId => ({ value: chainId, text: constants.chainNames[chainId] }));
    chainId = 0;
    get nativeAssetSymbol() {
      return constants.nativeAssetTicker[this.chainId] || "";
    }

    privateKeyWallets = [];
    walletAddress = "";

    poolAddress = "";
    isFormLocked = false;

    isFetchingPrices = false;
    poolMetadata = null;
    displayPrice = "--";
    fetchPriceTimeoutHandler = null;

    get assetAmountStep() {
      const decimals = this.poolMetadata?.assetTokenInfo.decimals || 18;
      return BigNumber(1).shiftedBy(-decimals).toFixed();
    }
    inputAssetAmount = "";
    displayOutputShareAmount = "--";

    tradePriceBN: BigNumber = null;
    get displayTradePrice() {
      return this.tradePriceBN?.gt(0) ? this.tradePriceBN.precision(6).toFixed() : "--";
    }
    displayInverseTradePrice = "--";
    displayPriceImpact = "--";

    inputTriggerPrice = "";
    inputSlippageTolerance = "0.5";
    inputTotalSpend = "";
    inputGasPriceMultiplier = "1.25";

    walletNativeBalanceBN = BigNumber(0);
    get displayWalletNativeBalance() {
      return this.walletNativeBalanceBN.decimalPlaces(8, BigNumber.ROUND_DOWN).toFixed();
    }

    walletAssetBalanceBN = BigNumber(0);
    get displayWalletAssetBalance() {
      return this.walletAssetBalanceBN.decimalPlaces(8, BigNumber.ROUND_DOWN).toFixed();
    }

    purchasedSharesBN = BigNumber(0);
    get displayPurchasedShares() {
      return this.purchasedSharesBN.decimalPlaces(8, BigNumber.ROUND_DOWN).toFixed();
    }

    isAmountFormLocked = false;
    checkErrorMsg = "";
    showApprove = false;
    isWatchingAndBuying = false;
    isSendingSwap = false;

    trades = [];

    readonly txRequestModalId = "tx-request-modal";
    txRequestModalMessage = "";
    txRequestModalData: TransactionRequest = null;

    get amountFormInputsDisabled() {
      return this.isAmountFormLocked || this.isWatchingAndBuying;
    }

    isDestroyed = false;


    get displaySaleEnd() {
      return DateTime.fromSeconds(Number(this.poolMetadata.saleEnd)).toFormat("yyyy-MM-dd HH:mm:ss");
    }

    mounted() {
      document.title = "LBP Watch & Swap";
      this.privateKeyWallets = privateKeyWalletService.getWallets().filter(w => w.type === "evm").map(w => {
        let text = w.address;
        if (w.label) {
          text = `${w.label} (${w.address})`;
        }
        return { text, value: w.address };
      });

      const savedSettings = JSON.parse(localStorage.getItem("watchSwapLiquidityBootstrapPool"));
      Object.assign(this, _.pick(savedSettings, ["chainId", "walletAddress", "poolAddress"]));
    }

    async fetchPoolMetadata() {
      try {
        this.isFormLocked = true;
        const poolMetadata = await liquidityBootstrapPoolService.getPoolMetadata(this.chainId, this.poolAddress);
        console.log(poolMetadata);
        this.poolMetadata = poolMetadata;

      } catch (e) {
        this.isFormLocked = false;
      }

      localStorage.setItem("watchSwapLiquidityBootstrapPool", JSON.stringify(_.pick(this, ["chainId", "walletAddress", "poolAddress"])));
      this.refreshInfo();

      const apiTradesItems = await liquidityBootstrapPoolService.getTrades(this.chainId, this.poolAddress, this.walletAddress);
      this.trades = apiTradesItems.map(apiTradeItem => {
        const ret = {
          datetime: apiTradeItem.timestamp,
          displayDateTime: DateTime.fromISO(apiTradeItem.timestamp).toFormat("yyyy-MM-dd HH:mm:ss"),
          displayType: apiTradeItem.type,
          displayShareAmount: "",
          displayAssetAmount: "",
          displayPrice: "",
          displayTxFee: "-",
          txHash: apiTradeItem.txHash
        };
        const tokenAmountInBN = BigNumber(apiTradeItem.tokenAmountIn);
        const tokenAmountOutBN = BigNumber(apiTradeItem.tokenAmountOut);

        if (apiTradeItem.type === "buy") {
          ret.displayShareAmount = tokenAmountOutBN.decimalPlaces(8).toFixed();
          ret.displayAssetAmount = tokenAmountInBN.decimalPlaces(8).toFixed();
          ret.displayPrice = tokenAmountInBN.div(tokenAmountOutBN).precision(6).toFixed();
        } else if (apiTradeItem.type === "sell") {
          ret.displayAssetAmount = tokenAmountOutBN.decimalPlaces(8).toFixed();
          ret.displayShareAmount = tokenAmountInBN.decimalPlaces(8).toFixed();
          ret.displayPrice = tokenAmountOutBN.div(tokenAmountInBN).precision(6).toFixed();
        }
        return ret;
      });
    }

    async refreshInfo() {
      clearTimeout(this.fetchPriceTimeoutHandler);
      if (this.isDestroyed) return;

      try {
        this.isFetchingPrices = true;
        const inputAssetAmountBN = BigNumber(this.inputAssetAmount);
        const { priceBN, purchasedSharesBN, walletAssetBalanceBN, walletNativeBalanceBN, sharesOutBN } =
          await liquidityBootstrapPoolService.getCurrentPriceAndPreviewSharesOut(this.poolMetadata, this.walletAddress, inputAssetAmountBN);

        if (priceBN?.gt(0)) {
          this.displayPrice = priceBN.precision(6).toFixed();
          if (!this.inputTriggerPrice) {
            this.inputTriggerPrice = this.displayPrice;
          }
        } else {
          this.displayPrice = "--";
        }

        this.walletNativeBalanceBN = walletNativeBalanceBN;
        this.walletAssetBalanceBN = walletAssetBalanceBN;
        this.purchasedSharesBN = purchasedSharesBN;

        let documentTitlePrice = "";

        if (sharesOutBN?.gt(0)) {
          this.displayOutputShareAmount = sharesOutBN.decimalPlaces(8).toFixed();
          const tradePriceBN = inputAssetAmountBN.div(sharesOutBN);
          this.tradePriceBN = tradePriceBN;
          const inverseTradePriceBN = sharesOutBN.div(inputAssetAmountBN);
          this.displayInverseTradePrice = inverseTradePriceBN.precision(6).toFixed();
          const priceImpactBN = BigNumber.max(0, tradePriceBN.div(priceBN).minus(1).multipliedBy(2));
          this.displayPriceImpact = priceImpactBN.multipliedBy(100).decimalPlaces(2).toFixed() + "%";
          documentTitlePrice = this.displayTradePrice;
        } else {
          this.displayOutputShareAmount = "--";
          this.tradePriceBN = null;
          this.displayInverseTradePrice = "--";
          this.displayPriceImpact = "--";
          documentTitlePrice = this.displayPrice;
        }

        document.title = [
          documentTitlePrice,
          " ",
          this.poolMetadata.shareTokenInfo.symbol,
          "/",
          this.poolMetadata.assetTokenInfo.symbol,
          " | LBP Watch & Swap"
        ].join("").trim();

        if (this.isWatchingAndBuying) {
          this.checkAndExecute();
        }

        /*const provider = await web3Service.getProviderForChain(this.chainId, false);
        const feeData = await provider.getFeeData();
        console.log("feeData", {
          gasPrice: ethers.formatUnits(feeData.gasPrice, "gwei"),
          maxFeePerGas: ethers.formatUnits(feeData.maxFeePerGas, "gwei"),
          maxPriorityFeePerGas: ethers.formatUnits(feeData.maxPriorityFeePerGas, "gwei"),
        });*/

      } catch (e) {
        console.error(e);

      } finally {
        this.isFetchingPrices = false;
      }

      clearTimeout(this.fetchPriceTimeoutHandler);
      this.fetchPriceTimeoutHandler = setTimeout(() => this.refreshInfo(), 3500);
    }

    inputAmountChangedDebounced = _.debounce(this.inputAmountChanged, 300);
    inputAmountChanged() {
      this.refreshInfo();
    }

    onClickMaxSpend() {
      this.inputTotalSpend = this.displayWalletAssetBalance;
    }

    async checkBeforeStart() {
      try {
        this.checkErrorMsg = "";
        this.showApprove = false;
        this.isAmountFormLocked = true;

        const checkRet = await liquidityBootstrapPoolService.checkRequirements(this.poolMetadata, this.walletAddress, this.inputTotalSpend);
        console.log("checkRet", checkRet);

        if (!checkRet.balanceIsOk) {
          const balanceUiAmount = BigNumber(checkRet.balance.toString()).shiftedBy(-this.poolMetadata.assetTokenInfo.decimals).toFixed();
          throw new Error(`Not enough ${this.poolMetadata.assetTokenInfo.symbol} balance. Required ${this.inputTotalSpend}, have ${balanceUiAmount}`);
        }
        if (!checkRet.allowanceIsOk) {
          const allowanceUiAmount = BigNumber(checkRet.allowance.toString()).shiftedBy(-this.poolMetadata.assetTokenInfo.decimals).toFixed();
          this.showApprove = true;
          throw new Error(`Not enough ${this.poolMetadata.assetTokenInfo.symbol} allowance. Required ${this.inputTotalSpend}, have ${allowanceUiAmount}`);
        }

        this.isWatchingAndBuying = true;

      } catch (e) {
        this.checkErrorMsg = e.message;
        this.isAmountFormLocked = false;
      }
    }

    async onClickApproveTotalSpend() {
      this.txRequestModalMessage = `Approve ${this.inputTotalSpend} ${this.poolMetadata.assetTokenInfo.symbol} to ${this.poolMetadata.address}`;
      this.txRequestModalData = await liquidityBootstrapPoolService.createApproveTotalSpendTx(this.poolMetadata, this.walletAddress, this.inputTotalSpend);
      this.$bvModal.show(this.txRequestModalId);
    }

    async onClickApproveUnlimited() {
      this.txRequestModalMessage = `Approve unlimited ${this.poolMetadata.assetTokenInfo.symbol} to ${this.poolMetadata.address}`;
      this.txRequestModalData = await liquidityBootstrapPoolService.createApproveUnlimitedTx(this.poolMetadata, this.walletAddress);
      this.$bvModal.show(this.txRequestModalId);
    }

    async onClickRevoke() {
      this.txRequestModalMessage = `Revoke ${this.poolMetadata.assetTokenInfo.symbol} to ${this.poolMetadata.address}`;
      this.txRequestModalData = await liquidityBootstrapPoolService.createRevokeTx(this.poolMetadata, this.walletAddress);
      this.$bvModal.show(this.txRequestModalId);
    }

    async checkAndExecute() {
      if (!this.isWatchingAndBuying) return;
      if (this.isSendingSwap) return;

      const totalSpendBN = BigNumber(this.inputTotalSpend);
      const assetInUiAmountBN = BigNumber.min(this.inputAssetAmount, totalSpendBN);
      if (assetInUiAmountBN.lte(0)) {
        this.isWatchingAndBuying = false;
        this.isAmountFormLocked = false;
        return;
      }

      if (this.tradePriceBN?.lte(this.inputTriggerPrice)) {
        // 1 => 0.99, 2 => 0.98
        const outputMultiplierBN = BigNumber(100).minus(this.inputSlippageTolerance).div(100);
        const minSharesOutUiAmountBN = assetInUiAmountBN.div(this.inputTriggerPrice).multipliedBy(outputMultiplierBN);

        console.log(
          "checkAndExecute assetInUiAmountBN", assetInUiAmountBN.toFixed(),
          "minSharesOutUiAmountBN", minSharesOutUiAmountBN.toFixed()
        );

        const buyTxRequest = await liquidityBootstrapPoolService.createBuyTx(this.poolMetadata, this.walletAddress, assetInUiAmountBN, minSharesOutUiAmountBN);
        const provider = await web3Service.getProviderForChain(this.chainId, false);

        const privateKeyWallet = privateKeyWalletService.getWallets().find(w =>
          w.type === "evm" &&
          w.address.toLowerCase() === buyTxRequest.from.toString().toLowerCase()
        );
        const wallet = new Wallet(privateKeyWallet.privateKey, provider);

        // use "latest" tag instead
        buyTxRequest.nonce = await wallet.getNonce("latest");

        const walletPopulatedTxRequest = await wallet.populateTransaction(buyTxRequest);

        // bump gas limit by 25%
        walletPopulatedTxRequest.gasLimit = ethers.getUint(walletPopulatedTxRequest.gasLimit) * 125n / 100n;

        // bump gas price
        if (walletPopulatedTxRequest.gasPrice) {
          walletPopulatedTxRequest.gasPrice = BigNumber(walletPopulatedTxRequest.gasPrice.toString())
            .multipliedBy(this.inputGasPriceMultiplier)
            .decimalPlaces(0)
            .toFixed();
        }
        if (walletPopulatedTxRequest.maxFeePerGas) {
          walletPopulatedTxRequest.maxFeePerGas = BigNumber(walletPopulatedTxRequest.maxFeePerGas.toString())
            .multipliedBy(this.inputGasPriceMultiplier)
            .decimalPlaces(0)
            .toFixed();
        }
        if (walletPopulatedTxRequest.maxPriorityFeePerGas) {
          walletPopulatedTxRequest.maxPriorityFeePerGas = BigNumber(walletPopulatedTxRequest.maxPriorityFeePerGas.toString())
            .multipliedBy(this.inputGasPriceMultiplier)
            .decimalPlaces(0)
            .toFixed();
        }

        const purchasedSharesBeforeBN = this.purchasedSharesBN;

        try {
          this.isSendingSwap = true;
          const txResponse = await wallet.sendTransaction(walletPopulatedTxRequest);

          const tradeItem = {
            datetime: new Date().toISOString(),
            displayDateTime: DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss"),
            displayType: "buy",
            displayShareAmount: "",
            displayAssetAmount: assetInUiAmountBN.toFixed(),
            displayPrice: "",
            displayTxFee: "",
            txHash: txResponse.hash
          };
          this.trades.unshift(tradeItem);

          const transactionReceipt = await txResponse.wait(1, 60000);
          tradeItem.displayTxFee = BigNumber(ethers.formatEther(transactionReceipt.fee)).decimalPlaces(8).toFixed();

          if (transactionReceipt.status === 1) {
            const startTime = Date.now();
            while (true) {
              const purchasedSharesBN = this.purchasedSharesBN.minus(purchasedSharesBeforeBN);
              if (purchasedSharesBN.gt(0)) {
                tradeItem.displayShareAmount = purchasedSharesBN.decimalPlaces(8).toFixed();
                tradeItem.displayPrice = assetInUiAmountBN.div(purchasedSharesBN).precision(6).toFixed();
                this.inputTotalSpend = BigNumber(this.inputTotalSpend).minus(assetInUiAmountBN).toFixed();
                break;
              }

              if (Date.now() - startTime > 60000) {
                throw new Error("Timeout");
              }
              await utils.delay(500);
            }

          } else {
            tradeItem.displayShareAmount = "";
            tradeItem.displayAssetAmount = "";
          }
          this.trades[0] = { ...tradeItem };

        } catch (e) {
          this.toastError(e);
          this.isWatchingAndBuying = false;

        } finally {
          this.isSendingSwap = false;
        }
      }
    }

    onClickStop() {
      this.isWatchingAndBuying = false;
      this.isAmountFormLocked = false;
    }

    destroyed() {
      this.isDestroyed = true;
    }

  }
</script>
