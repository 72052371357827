<template>
  <div>
    <b-form-group label="Account">
      <b-form-select :options="cexAccountOptions" v-model="cexAccountId" @input="onChangeNetwork"></b-form-select>
    </b-form-group>
    <div class="mb-2" v-if="shouldShowViewAddressHistoryBtn">
      <span class="text-primary cursor-pointer" v-b-modal:deposit-address-history-modal>View address history</span>
    </div>
    <b-form-group v-if="selectedAccount" label="Network">
      <b-form-radio-group :options="networkOptions" v-model="network" @input="onChangeNetwork" required></b-form-radio-group>
    </b-form-group>
    <b-form-group v-if="address">
      <template v-slot:label>
        <div class="d-flex flex-row align-items-center justify-content-between">
          <div>Address</div>
          <div>
            <span class="text-primary cursor-pointer" @click="onClickCopyAddress" v-b-tooltip.click.blur title="Copied">Copy</span>
          </div>
        </div>
      </template>
      <b-input-group>
        <b-form-textarea readonly :value="address" class="text-monospace"></b-form-textarea>
      </b-input-group>
    </b-form-group>
    <b-form-group v-if="memo">
      <template v-slot:label>
        <div class="d-flex flex-row align-items-center justify-content-between">
          <div>Memo</div>
          <div>
            <span class="text-primary cursor-pointer" @click="onClickCopyMemo" v-b-tooltip.click.blur title="Copied">Copy</span>
          </div>
        </div>
      </template>
      <b-input-group>
        <b-form-textarea readonly :value="memo" class="text-monospace"></b-form-textarea>
      </b-input-group>
    </b-form-group>

    <b-modal id="deposit-address-history-modal" size="xl" :title="'Deposit ' + asset.toUpperCase()" hide-footer no-fade>
      <DepositCexAssetModal modal-id="deposit-cex-asset-modal" :cex-account-id="cexAccountId" :asset="asset" />
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import {Component, Prop, Vue} from 'vue-property-decorator';
  import _ from "lodash";
  import BaseComponent from "@/components/BaseComponent";
  import * as cexAccountService from "@/services/cexAccountService";
  import * as marketDataService from "@/services/marketDataService";
  import * as web3Service from "@/services/web3Service";
  import DepositCexAssetModal from "@/components/DepositCexAssetModal.vue";

  @Component({
    components: { DepositCexAssetModal }
  })
  export default class ArbitrageDepositCexAssetModal extends BaseComponent {

    @Prop() p_cexAccountId;
    @Prop({ required: true }) modalId: string;
    @Prop({ required: true }) exchange: string;
    @Prop({ required: true }) asset: string;

    cexAccounts = [];
    get cexAccountOptions() {
      return this.cexAccounts.map(a => ({ value: a.id, text: a.label }));
    }
    cexAccountId = null;
    get selectedAccount() {
      return this.cexAccounts.find(a => a.id === this.cexAccountId);
    }
    get shouldShowViewAddressHistoryBtn() {
      return ["binance", "okx"].includes(this.selectedAccount?.exchange);
    }

    assetDetails = null;
    get networkOptions() {
      return (this.assetDetails?.networks || []).map(it => ({
        value: this.exchange === "kucoin"? it.name : it.network,
        text: this.exchange === "binance"? it.network : it.name,
        disabled: !it.depositEnabled
      }));
    }
    network = "";

    get selectedNetwork() {
      return this.assetDetails.networks.find(it => it.network === this.network);
    }

    address = "";
    memo = "";

    mounted() {
      this.setup();
    }

    async setup() {
      this.cexAccounts = (await cexAccountService.getAll()).filter(a => a.exchange === this.exchange);
      if (!this.cexAccounts.length) return;

      if (this.p_cexAccountId){
        this.cexAccountId = this.p_cexAccountId;
      } else {
        const lastUsedAccId = cexAccountService.getLastUsedAccountIdByExchange(this.exchange);
        this.cexAccountId = lastUsedAccId || this.cexAccounts[0]?.id;
      }

      const fetchCexAsset = marketDataService.getCexAssets({
        exchange: this.exchange,
        asset: this.asset
      });
      this.assetDetails = (await fetchCexAsset)[0];

      const chainId = await web3Service.getConnectedEvmChainId();

      // networks to find default
      const networks = this.assetDetails.networks.filter(it => it.depositEnabled);
      const networkObj = networks.find(it => it.evmChainId === chainId);
      this.network = this.exchange === "kucoin"? networkObj?.name : networkObj?.network;

      if (!this.network) {
        // use default network
        this.network = this.assetDetails.networks.find(it => it.isDefault && it.depositEnabled)?.network;
      }
    }

    async onChangeNetwork() {
      if (!this.network) return;
      console.log("onChangeNetwork", this.network);
      this.address = this.memo = "";
      const addressObj = await cexAccountService.fetchDepositAddress(this.cexAccountId, this.asset, this.network);
      this.address = addressObj.address;
      this.memo = addressObj.memo;

      cexAccountService.setLastUsedAccountIdByExchange(this.exchange, this.cexAccountId);
    }

    onClickCopyAddress() {
      navigator.clipboard.writeText(this.address);
    }

    onClickCopyMemo() {
      navigator.clipboard.writeText(this.memo);
    }

  }
</script>
