import axios from "@/axios";
import * as authService from "@/services/authService";
import * as constants from "@/constants";

const chainIds = Object.keys(constants.chainNames);
const chainSpecificStorageKeys = ["swap", "customTokens", "pinnedTokens", "swapDestReceiverByToken"];
const otherStorageKeys = ["savedWallets"];
const allStorageKeys: string[] = [];

for (const chainId of chainIds) {
  for (const key of chainSpecificStorageKeys) {
    allStorageKeys.push(key + "-" + chainId);
  }
}
allStorageKeys.push(...otherStorageKeys);

export async function getSavedPresets() {
  return (await axios.get("/savedPreset/all", {
    headers: await authService.getAuthHeaders()
  })).data.data;
}

export function dumpStorageData() {
  const data = {};

  for (const key of allStorageKeys) {
    const value = localStorage.getItem(key);
    if (value) {
      try {
        data[key] = JSON.parse(value);
      } catch (e) {

      }
    }
  }

  return data;
}

export async function createNew(label: string) {
  const data = dumpStorageData();

  return (await axios.post("/savedPreset/create", { label, data }, {
    headers: await authService.getAuthHeaders()
  })).data;
}

export async function update(id: number) {
  const data = dumpStorageData();

  return (await axios.post("/savedPreset/update", { id, data }, {
    headers: await authService.getAuthHeaders()
  })).data;
}

export async function del(id: number) {
  return (await axios.post("/savedPreset/delete", { id }, {
    headers: await authService.getAuthHeaders()
  })).data;
}
