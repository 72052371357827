<template>
  <div>
    <b-form @keydown.enter.prevent @submit.prevent="submitForm">
      <b-row class="mb-3">
        <b-col cols="4" class="text-right">Delisting from</b-col>
        <b-col cols="8">
          <b-form-checkbox-group :options="delistExchangeOptions" v-model="delistExchanges" />
        </b-col>
      </b-row>
      <b-row class="mb-3" v-for="exchange in exchanges" :key="exchange">
        <b-col cols="4" class="text-right mt-2">Account ({{ cexAccOptions[exchange].exchangeName }})</b-col>
        <b-col cols="8">
          <b-form-select :options="cexAccOptions[exchange].options" v-model="tradeCexAccountIds[exchange]"></b-form-select>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col cols="4" class="text-right mt-2">Notional volume</b-col>
        <b-col cols="8">
          <b-input-group>
            <b-form-input class="text-right" type="number" required v-model="notional" min="10" autocomplete="off"></b-form-input>
            <b-input-group-append is-text>USDT</b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col cols="4" class="text-right mt-2">Max price impact</b-col>
        <b-col cols="8">
          <b-input-group>
            <b-form-input class="text-right" type="number" required v-model="maxDropPercent" min="0"></b-form-input>
            <b-input-group-append is-text>%</b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col cols="4" class="text-right">Time in force</b-col>
        <b-col cols="8">
          <b-form-radio-group :options="timeInForceOptions" v-model="timeInForce"></b-form-radio-group>
        </b-col>
      </b-row>
      <template v-if="['ioc', 'fok'].includes(timeInForce)">
        <b-row class="mb-3">
          <b-col cols="4" class="text-right mt-2">
            <b-form-checkbox v-model="enableTakeProfit">Take profit</b-form-checkbox>
          </b-col>
          <b-col cols="8" v-if="enableTakeProfit">
            <b-input-group>
              <b-form-input class="text-right" type="number" required v-model="takeProfitPercent" min="1" autocomplete="off"></b-form-input>
              <b-input-group-append is-text>%</b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col cols="4" class="text-right mt-2">
            <b-form-checkbox v-model="enableStopLoss">Stop loss</b-form-checkbox>
          </b-col>
          <b-col cols="8" v-if="enableStopLoss">
            <b-input-group>
              <b-form-input class="text-right" type="number" required v-model="stopLossPercent" min="1" autocomplete="off"></b-form-input>
              <b-input-group-append is-text>%</b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </template>
      <div v-if="formErrorMsg" class="text-danger text-center">{{ formErrorMsg }}</div>
      <div class="text-center mt-4">
        <b-button type="submit" variant="primary" @click="beforeSubmit" :disabled="isSubmitting">Place orders</b-button>
      </div>
    </b-form>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
  import _ from "lodash";
  import BaseComponent from "@/components/BaseComponent";
  import * as cexAccountService from "@/services/cexAccountService";
  import * as delistTradeWatchOrderService from "@/services/delistTradeWatchOrderService";
  import * as utils from "@/utils";
  import * as constants from "@/constants";

  @Component
  export default class QuickSetupDelistTradeOrdersModal extends BaseComponent {

    @Prop() modalId: string;

    delistExchangeOptions = [
      { value: "binance", text: "Binance" },
      { value: "upbit", text: "Upbit" },
      { value: "bybit", text: "Bybit" },
      { value: "bybit-futures", text: "Bybit Futures" },
    ];
    delistExchanges = [];

    readonly exchanges = ["binance", "okx", "bybit"];
    cexAccounts = [];
    cexAccOptions = {};

    tradeCexAccountIds = {};
    notional = "";
    maxDropPercent = 15;

    timeInForceOptions = [
      { value: "ioc", text: "IOC" },
      { value: "fok", text: "FOK" },
      { value: "gtc", text: "GTC" },
    ];
    timeInForce = "ioc";

    enableTakeProfit = true;
    takeProfitPercent = "";

    enableStopLoss = true;
    stopLossPercent = "";

    formErrorMsg = "";
    isSubmitting = false;

    created() {
      for (const exchange of this.exchanges) {
        // const lastUsedAccId = cexAccountService.getLastUsedAccountIdByExchange(exchange) || 0;
        // this.$set(this.tradeCexAccountIds, exchange, lastUsedAccId);
        this.$set(this.cexAccOptions, exchange, {
          exchangeName: constants.exchangeNames[exchange],
          options: []
        });
      }
    }

    mounted() {
      this.setup();
    }

    async setup() {
      this.cexAccounts = await cexAccountService.getAll();
      for (const exchange of this.exchanges) {
        this.cexAccOptions[exchange].options = [
          { value: 0, text: "" },
          ...this.cexAccounts.filter(a => a.exchange === exchange).map(a => ({ value: a.id, text: a.label }))
        ];
      }
    }

    beforeSubmit() {

    }

    async submitForm() {
      try {
        this.isSubmitting = true;
        await delistTradeWatchOrderService.placeAll({
          delistExchanges: this.delistExchanges,
          tradeCexAccountIds: this.tradeCexAccountIds,
          notional: +this.notional,
          maxDropPercent: +this.maxDropPercent,
          timeInForce: this.timeInForce,
          takeProfitPercent: this.enableTakeProfit? +this.takeProfitPercent : undefined,
          stopLossPercent: this.enableStopLoss? +this.stopLossPercent : undefined,
          stopLossType: this.enableStopLoss? "market" : undefined
        });

        this.$bvModal.hide(this.modalId);
        this.done();
        this.toastSuccessDelay("Order placed");

      } catch (e) {
        console.error(e);
        this.formErrorMsg = e.response?.data?.message || e.message;

      } finally {
        this.isSubmitting = false;
      }
    }

    @Emit()
    done() {

    }

  }
</script>
