import {HDNodeWallet, Wallet} from "ethers";
import {Keypair} from "@solana/web3.js";
import {HDKey} from "micro-ed25519-hdkey";
import * as bip39 from "bip39";
import bs58 from "bs58";
import _ from "lodash";

const storageKey = "privateKeyWallets";

export function evmSeedPhraseWallets(phrase: string, fromIndex: number, toIndex: number) {
  const ret: HDNodeWallet[] = [];
  for (let i = fromIndex; i < toIndex; i++) {
    const hdPath = `m/44'/60'/0'/0/${i}`;
    const w = HDNodeWallet.fromPhrase(phrase, null, hdPath);
    ret.push(w);
  }
  return ret;
}

export function solanaSeedPhraseWallets(phrase: string, fromIndex: number, toIndex: number) {
  const seed = bip39.mnemonicToSeedSync(phrase, ""); // (mnemonic, password)
  const hd = HDKey.fromMasterSeed(seed);
  const ret: Keypair[] = [];
  for (let i = fromIndex; i < toIndex; i++) {
    const hdPath = `m/44'/501'/${i}'/0'`;
    const keypair = Keypair.fromSeed(hd.derive(hdPath).privateKey);
    ret.push(keypair);
  }
  return ret;
}


export function getWallets() {
  const data = JSON.parse(localStorage.getItem(storageKey));
  return Array.isArray(data) ? data : [];
}

export function saveWallets(inputWallets: { type: string, label: string, privateKey: string }[]) {
  if (!inputWallets?.length) return;
  const privateKeyWallets = getWallets();

  for (const inputWallet of inputWallets) {
    if (inputWallet.type === "evm") {
      const w = new Wallet(inputWallet.privateKey);
      const pushItem = { type: inputWallet.type, label: inputWallet.label, address: w.address, privateKey: w.privateKey };
      // check existing
      const existingItemIndex = privateKeyWallets.findIndex(({ address }) => address.toLowerCase() === pushItem.address.toLowerCase());
      if (existingItemIndex >= 0) {
        privateKeyWallets[existingItemIndex] = pushItem;
      } else {
        privateKeyWallets.push(pushItem);
      }

    } else if (inputWallet.type === "solana") {
      const keyPair = Keypair.fromSecretKey(bs58.decode(inputWallet.privateKey));
      const pushItem = { type: inputWallet.type, label: inputWallet.label, address: keyPair.publicKey.toBase58(), privateKey: inputWallet.privateKey };
      // check existing
      const existingItemIndex = privateKeyWallets.findIndex(({ address }) => address === pushItem.address);
      if (existingItemIndex >= 0) {
        privateKeyWallets[existingItemIndex] = pushItem;
      } else {
        privateKeyWallets.push(pushItem);
      }
    }
  }
  saveWalletsNoValidate(privateKeyWallets);
}

export function saveWalletsNoValidate(privateKeyWallets) {
  localStorage.setItem(storageKey, JSON.stringify(privateKeyWallets));
}

export function editWalletLabel(type: string, address: string, label: string) {
  const privateKeyWallets = getWallets();
  const item = type === "evm"?
    privateKeyWallets.find(w => w.type === type && w.address.toLowerCase() === address.toLowerCase()) :
    privateKeyWallets.find(w => w.type === type && w.address === address);
  item.label = label;
  localStorage.setItem(storageKey, JSON.stringify(privateKeyWallets));
}

export function deleteWallet(type: string, address: string) {
  const privateKeyWallets = getWallets().filter(w => w.type !== type || w.address !== address);
  localStorage.setItem(storageKey, JSON.stringify(privateKeyWallets));
}
