import {Component, Vue} from 'vue-property-decorator';
import _ from "lodash";
import { ethers } from "ethers";
import * as utils from "@/utils";
import * as constants from "@/constants";

@Component({
  filters: {
    shortened(value: string) {
      return utils.getShortenedAddress(value);
    },
    explorerUrl(value: string, chainId: number | "all", type: "address" | "tx" | "token") {
      return utils.getExplorerUrl(chainId, type, value);
    }
  }
})
export default class BaseComponent extends Vue {

  publicPath = process.env.BASE_URL;
  readonly loadingModalId = "modal-loading";

  toastError(e) {
    const message = e.response?.data?.message || e.message;
    this.$bvToast.toast(message, {
      toaster: "b-toaster-bottom-right",
      title: "Error",
      autoHideDelay: 7500,
      variant: "danger",
      headerClass: "bg-danger text-white"
    });
  }

  toastSuccess(title: string, message: string) {
    return this.$bvToast.toast(message, {
      toaster: "b-toaster-bottom-right",
      title,
      autoHideDelay: 7500,
      variant: "success",
      headerClass: "bg-info text-white"
    });
  }

  toastSuccessDelay(message: string, title?: string, delay?: number) {
    title = title || "Alert";
    if (_.isNil(delay)) delay = 300;

    return setTimeout(() => this.toastSuccess(title, message), delay);
  }

  showLoading() {
    this.$bvModal.show(this.loadingModalId);
  }

  hideLoading() {
    this.$bvModal.hide(this.loadingModalId);
  }



}
