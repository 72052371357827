<template>
  <main role="main">
    <b-container fluid class="py-5">
      <div class="d-flex flex-row align-items-center justify-content-between mb-4">
        <h4 class="m-0">CEX balance watch orders</h4>
        <div>
          <b-button variant="link" class="mr-3" @click="fetchOrders" :disabled="isLoading">
            <template v-if="isLoading">Loading orders <b-spinner small/></template>
            <template v-else>Refresh</template>
          </b-button>
          <b-button variant="outline-primary" @click="onClickNewOrder">
            <b-icon-plus/> New order
          </b-button>
        </div>
      </div>
      <b-table :items="orders" :fields="tableFields" :busy="isLoading" hover>
        <template v-slot:cell(symbol)="data">
          <span v-if="data.item.baseAsset" class="text-primary cursor-pointer" @click="onClickNewOrderFromExistingOrder(data.item)">
            {{ data.item.baseAsset.toUpperCase() }}/{{ data.item.quoteAsset.toUpperCase() }}
          </span>
          <template v-else>{{ data.value }}</template>
        </template>
        <template v-slot:cell(status)="data">
          {{ data.item.status }}
          <b-icon-info-circle v-if="data.item.status === 'failed'" class="ml-1 cursor-pointer" @click="onClickViewDetails(data.item)" />
        </template>
        <template v-slot:cell(actions)="data">
          <b-spinner small v-if="data.item.busy"/>
          <b-button v-else-if="data.item.status === 'pending'" variant="link" class="text-danger" size="sm" @click="onClickCancel(data.item)">
            Cancel
          </b-button>
        </template>
      </b-table>
    </b-container>

    <b-modal id="new-cex-balance-watch-order-modal" title="New CEX balance watch order" hide-footer no-fade no-close-on-backdrop>
      <NewCexBalanceWatchOrderModal
        modal-id="new-cex-balance-watch-order-modal"
        :p_evm-address="newOrderCexAccId"
        :p_base-asset="newOrderBaseAsset"
        :p_quote-asset="newOrderQuoteAsset"
        @done="fetchOrders" />
    </b-modal>

    <b-modal id="order-details-modal" title="Exception" size="xl" hide-footer no-fade>
      <OrderDetailsModal modal-id="order-details-modal" :order="viewDetailsOrder"></OrderDetailsModal>
    </b-modal>
  </main>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import {Component, Vue} from 'vue-property-decorator';
  import _ from "lodash";
  import BaseComponent from "@/components/BaseComponent";
  import NewCexBalanceWatchOrderModal from "@/components/NewCexBalanceWatchOrderModal.vue";
  import * as cexBalanceWatchOrderService from "@/services/cexBalanceWatchOrderService";
  import {BvTableFieldArray} from "bootstrap-vue";
  import * as utils from "@/utils";
  import {DateTime} from "luxon";
  import OrderDetailsModal from "@/components/OrderDetailsModal.vue";

  @Component({
    components: {OrderDetailsModal, NewCexBalanceWatchOrderModal}
  })
  export default class CexBalanceWatchOrders extends BaseComponent {

    isLoading = true;

    tableFields: BvTableFieldArray = [
      {
        key: "id",
        label: "#",
        thClass: "text-nowrap",
        tdClass: "text-nowrap",
      },
      {
        key: "createdAt",
        label: "Created",
        thClass: "text-nowrap",
        formatter: value => DateTime.fromISO(value).toFormat("yyyy-MM-dd HH:mm:ss")
      },
      {
        key: "cexAccountLabel",
        label: "Acc label",
        thClass: "text-nowrap",
        tdClass: "text-break",
      },
      {
        key: "asset",
        label: "Coin",
        thClass: "text-nowrap",
        tdClass: "text-nowrap",
        formatter: value => value.toUpperCase()
      },
      /*{
        key: "triggerBalance",
        label: "Trigger balance",
        thClass: "text-nowrap",
        tdClass: "text-nowrap",
      },*/
      {
        key: "symbol",
        label: "Market",
        thClass: "text-nowrap",
        tdClass: "text-nowrap",
      },
      {
        key: "type",
        label: "Type",
        thClass: "text-nowrap",
        tdClass: "text-nowrap",
      },
      {
        key: "price",
        label: "Price",
        thClass: "text-nowrap",
        tdClass: "text-nowrap",
        formatter: (value, key, item) => item.type === "limit" ? value : undefined
      },
      {
        key: "expireAt",
        label: "Expires at",
        thClass: "text-nowrap",
        formatter: value => value? DateTime.fromISO(value).toFormat("yyyy-MM-dd HH:mm:ss") : ""
      },
      {
        key: "status",
        label: "Status",
        thClass: "text-nowrap",
        tdClass: "text-nowrap",
      },
      {
        key: "actions",
        label: "",
        thClass: "text-nowrap",
        tdClass: "text-nowrap"
      }
    ];
    orders = [];

    viewDetailsOrder = null;
    newOrderCexAccId = null;
    newOrderBaseAsset = "";
    newOrderQuoteAsset = "";

    mounted() {
      document.title = "CEX balance watch orders";
      this.fetchOrders();
    }

    async fetchOrders() {
      try {
        this.isLoading = true;
        const orders = await cexBalanceWatchOrderService.getAll();
        for (const order of orders) {
          order.busy = false;
        }
        this.orders = orders;

      } catch (e) {
        console.error(e);
        this.toastError(e);

      } finally {
        this.isLoading = false;
      }
    }

    onClickViewDetails(order) {
      this.viewDetailsOrder = order;
      this.$bvModal.show("order-details-modal");
    }

    onClickNewOrder() {
      this.newOrderCexAccId = this.newOrderBaseAsset = this.newOrderQuoteAsset = null;
      this.$bvModal.show("new-cex-balance-watch-order-modal");
    }

    onClickNewOrderFromExistingOrder(order) {
      this.newOrderCexAccId = order.cexAccountId.toString();
      this.newOrderBaseAsset = order.baseAsset;
      this.newOrderQuoteAsset = order.quoteAsset;
      this.$bvModal.show("new-cex-balance-watch-order-modal");
    }

    async onClickCancel(order) {
      try {
        order.busy = true;
        await cexBalanceWatchOrderService.cancel(order.id);
        this.toastSuccess("Info", `Order #${order.id} cancelled`);

      } catch (e) {
        console.error(e);
        this.toastError(e);

      } finally {
        order.busy = false;
        this.fetchOrders();
      }
    }

  }
</script>
