import axios from "@/axios";
import * as authService from "@/services/authService";
import * as constants from "@/constants";
import * as utils from "@/utils";

export async function getAll() {
  return (await axios.get("/cexAccount/all", {
    headers: await authService.getAuthHeaders()
  })).data.data;
}

export async function editShowOrder(updateAccs: { id: number, showOrder: number }[]) {
  return (await axios.post("/cexAccount/editShowOrder", updateAccs, {
    headers: await authService.getAuthHeaders()
  })).data.data;
}

export async function getBalances(id: number, asset: string, showAll: boolean) {
  return (await axios.get("/cexAccount/balances", {
    params: utils.deleteNilProps({ id, asset, showAll }),
    headers: await authService.getAuthHeaders()
  })).data.data;
}

export async function getDepositAddresses(accountId: number, asset: string) {
  return (await axios.get("/cexAccount/depositAddresses", {
    params: { accountId, asset },
    headers: await authService.getAuthHeaders()
  })).data.data;
}

export async function fetchDepositAddress(accountId: number, asset: string, network: string) {
  return (await axios.get("/cexAccount/fetchDepositAddress", {
    params: { accountId, asset, network },
    headers: await authService.getAuthHeaders()
  })).data.data;
}

export async function add(data) {
  return (await axios.post("/cexAccount/add", data, {
    headers: await authService.getAuthHeaders()
  })).data.data;
}

export async function editLabel(data) {
  return (await axios.post("/cexAccount/editLabel", data, {
    headers: await authService.getAuthHeaders()
  })).data.data;
}

export async function del(id: number) {
  return (await axios.post("/cexAccount/delete", { id }, {
    headers: await authService.getAuthHeaders()
  })).data.data;
}

export async function withdraw(data) {
  return (await axios.post("/cexAccount/withdraw", data, {
    headers: await authService.getAuthHeaders()
  })).data.data;
}

export async function changeUsdtFuturesAllSymbolsSettings(data) {
  return (await axios.post("/cexAccount/changeUsdtFuturesAllSymbolsSettings", data, {
    headers: await authService.getAuthHeaders()
  })).data.data;
}


export function getLastUsedAccountIdByExchange(exchange: string) {
  try {
    const lastUsedAccountIdByExchange = JSON.parse(localStorage.getItem("cexLastUsedAccountIdByExchange"));
    return lastUsedAccountIdByExchange[exchange];
  } catch (e) {
  }
}

export function setLastUsedAccountIdByExchange(exchange: string, id: number) {
  let lastUsedAccountIdByExchange = {};
  try {
    lastUsedAccountIdByExchange = JSON.parse(localStorage.getItem("cexLastUsedAccountIdByExchange")) || {};
  } catch (e) {
  }
  lastUsedAccountIdByExchange[exchange] = id;
  localStorage.setItem("cexLastUsedAccountIdByExchange", JSON.stringify(lastUsedAccountIdByExchange));
  return lastUsedAccountIdByExchange;
}
