import _ from "lodash";
import {ethers} from "ethers";
import {PublicKey} from "@solana/web3.js";
import * as web3Service from "@/services/web3Service";

export interface SavedWallet {
  [key: string]: any;
  address: string;
  memo?: string;
  label: string;
}

const storageKey = "savedWallets";

export function getAllSavedWallets2(type?: string) {
  try {
    const data = JSON.parse(localStorage.getItem(storageKey));
    const arr: SavedWallet[] = Array.isArray(data)? data : [];
    if (type === "evm") {
      return arr.filter(it => ethers.isAddress(it.address));
    } else if (type === "solana") {
      return arr.filter(it => {
        try {
          return new PublicKey(it.address);
        } catch (e) {
          return false;
        }
      });
    } else if (type === "tron") {
      return arr.filter(it => web3Service.getTronWeb().isAddress(it.address));
    } else {
      return arr;
    }

  } catch (e) {
    return [];
  }
}

export function addSavedWallet2(data: SavedWallet, type?: string) {
  data.label = data.label.trim();
  if (type === "evm") {
    data.address = ethers.getAddress(data.address);
  } else if (type === "solana") {
    new PublicKey(data.address);
  } else if (type === "tron") {
    if (!web3Service.getTronWeb().isAddress(data.address)) {
      throw new Error("Invalid Tron address");
    }
  }
  data.memo = data.memo || undefined;

  const savedWallets = getAllSavedWallets2();

  const index = savedWallets.findIndex(t => t.address.toLowerCase() === data.address.toLowerCase() && t.memo === data.memo);
  if (index >= 0) {
    savedWallets.splice(index, 1, data);
  } else {
    savedWallets.push(data);
  }

  const saveSavedWallets = savedWallets.map(token => _.pick(token, ["address", "label", "memo"]));
  localStorage.setItem(storageKey, JSON.stringify(saveSavedWallets));
}

export function deleteSavedWallet(address: string, memo?: string) {
  const savedWallets = getAllSavedWallets2();
  const saveSavedWallets = savedWallets.filter(t => t.address.toLowerCase() !== address.toLowerCase() || t.memo !== memo);
  localStorage.setItem(storageKey, JSON.stringify(saveSavedWallets));
}
