import { render, staticRenderFns } from "./CexHedgingConfirmMarketModal.vue?vue&type=template&id=73abce44&scoped=true"
import script from "./CexHedgingConfirmMarketModal.vue?vue&type=script&lang=ts"
export * from "./CexHedgingConfirmMarketModal.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73abce44",
  null
  
)

export default component.exports