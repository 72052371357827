<template>
  <div id="app">
    <Header></Header>
    <router-view/>
    <Footer></Footer>

    <b-modal :id="loadingModalId"
             hide-header hide-footer
             centered size="sm" dialog-class="loading-modal-dialog"
             no-close-on-esc no-close-on-backdrop
             no-fade>
      <div class="py-3 text-center">
        <b-spinner variant="light" style="width: 3rem; height: 3rem;"/>
      </div>
    </b-modal>
  </div>
</template>

<style lang="scss">

</style>

<script lang="ts">
  import Header from "@/components/Header.vue";
  import Footer from "@/components/Footer.vue";
  import {Component} from "vue-property-decorator";
  import BaseComponent from "@/components/BaseComponent";

  @Component({
    components: { Header, Footer }
  })
  export default class App extends BaseComponent {

    mounted() {

    }

  }
</script>
