import {ethers, Network} from "ethers";
import * as utils from "@/utils";
import * as constants from "@/constants";
import * as customTokensService from "@/services/customTokensService";
import _ from "lodash";
import axios from "@/axios";
import BigNumber from "bignumber.js";
import {PhantomProvider} from "@/types";

import {AddressLookupTableAccount, Connection, PublicKey, Transaction, VersionedTransaction} from "@solana/web3.js";
import {createUmi} from "@metaplex-foundation/umi-bundle-defaults";
import {deserializeMetadata, findMetadataPda} from "@metaplex-foundation/mpl-token-metadata";
import {publicKey, RpcAccount} from "@metaplex-foundation/umi";
import {MintLayout} from "@solana/spl-token";

import erc20Abi from "./abi/erc20Abi.json";
import multiCall3Abi from "./abi/multiCall3Abi.json";

const erc20Interface = new ethers.Interface(erc20Abi);
const multiCall3Interface = new ethers.Interface(multiCall3Abi);

const evmRpcUrlByChain = {
  1: "https://mainnet.infura.io/v3/b6bf7d3508c941499b10025c0776eaf8",
  56: "https://bsc-dataseed.binance.org",
  137: "https://polygon-mainnet.infura.io/v3/b6bf7d3508c941499b10025c0776eaf8",
  43114: "https://avalanche-mainnet.infura.io/v3/b6bf7d3508c941499b10025c0776eaf8",
  42161: "https://arbitrum-mainnet.infura.io/v3/b6bf7d3508c941499b10025c0776eaf8",
  10: "https://optimism-mainnet.infura.io/v3/b6bf7d3508c941499b10025c0776eaf8",
  8453: "https://mainnet.base.org",
  250: "https://rpc.ftm.tools",
};

const web3ProviderByChain = Object.keys(evmRpcUrlByChain).reduce((ret, chainId) => {
  ret[chainId] = new ethers.JsonRpcProvider(evmRpcUrlByChain[chainId], Network.from(+chainId), { staticNetwork: true, batchMaxCount: 1 });
  return ret;
}, {} as Record<number, ethers.JsonRpcProvider>);


let web3Provider: ethers.BrowserProvider;

const cachedTokens = {};
// chainId -> walletAddress -> tokenAddress -> balance
const cachedWalletBalances: Record<string, Record<string, Record<string, string>>> = {};

let cachedEvmChainId;

let cachedEvmWalletAddress;
let cachedSolanaWalletAddress;
let cachedTronWalletAddress;

export const solanaWeb3 = new Connection("https://melony-h3b322-fast-mainnet.helius-rpc.com/");
export const umi = createUmi(solanaWeb3);

export function getBrowserProvider(): ethers.BrowserProvider {
  if (!web3Provider && window.ethereum) {
    web3Provider = new ethers.BrowserProvider(window.ethereum);
  }
  return web3Provider;
}

export async function waitForBrowserProvider() {
  while(true) {
    const provider = getBrowserProvider();
    if (provider) return provider;
    await utils.delay(50);
  }
}

export function getSolanaProvider(): PhantomProvider {
  return window.solana || window.phantom?.solana || window.solflare || window.backpack;
}

export async function waitForSolanaProvider() {
  while(true) {
    const provider = getSolanaProvider();
    if (provider) return provider;
    await utils.delay(50);
  }
}

export async function getProviderForChain(chainId: number, preferBrowserProvider = true): Promise<ethers.JsonRpcApiProvider> {
  if (preferBrowserProvider && !document.hidden) {
    const browserProvider = getBrowserProvider();
    if (browserProvider) {
      // const startTime = Date.now();
      const browserProviderChainId = Number((await getBrowserProvider().getNetwork()).chainId);
      // const timeTaken = Date.now() - startTime;
      // console.log("getNetwork timeTaken", timeTaken);
      if (browserProviderChainId === chainId) {
        return browserProvider;
      }
    }
  }
  return web3ProviderByChain[chainId];
}

export function getTronWeb() {
  return window.tronLink?.tronWeb || window.tronWeb;
}

export async function getConnectedEvmAddress(): Promise<string | undefined> {
  if (!document.hidden || !cachedEvmWalletAddress) {
    cachedEvmWalletAddress = (await getBrowserProvider().listAccounts())[0]?.address;
  }
  return cachedEvmWalletAddress;
}

export async function getConnectedSolanaAddress() {
  try {
    await getSolanaProvider().connect({ onlyIfTrusted: true });
  } catch (e) {}
  const address = getSolanaProvider()?.publicKey?.toString();
  if (address) {
    cachedSolanaWalletAddress = address;
  }
  return cachedSolanaWalletAddress;
}


export async function getConnectedTronAddress() {
  const address = getTronWeb()?.defaultAddress.base58;
  if (address) {
    cachedTronWalletAddress = address;
  }
  return cachedTronWalletAddress;
}

export async function getConnectedEvmChainId(): Promise<number> {
  if (!document.hidden || !cachedEvmChainId) {
    cachedEvmChainId = Number((await getBrowserProvider().getNetwork()).chainId);
  }
  return cachedEvmChainId;
}


type GetTokensReturnType = Record<string, { address: string, symbol: string, name: string, decimals: number, logoURI?: string, tags?: string[] }>;
export async function getListedTokens(chainId: number): Promise<GetTokensReturnType> {
  if (cachedTokens[chainId]) {
    return cachedTokens[chainId];
  }

  if (utils.isEvmChain(chainId)) {
    cachedTokens[chainId] = (await axios.get(`https://tokens.1inch.io/v1.1/${chainId}`)).data;

  } else if (chainId === constants.CHAIN_ID_SOLANA) {
    const tokens = (await axios.get("https://tokens.jup.ag/tokens?tags=verified")).data;
    const tokensKeyedByMint = _.keyBy(tokens, "address");
    tokensKeyedByMint[constants.WSOL].name = "Solana";
    cachedTokens[chainId] = tokensKeyedByMint;

  } else if (chainId === constants.CHAIN_ID_TRON) {
    const tokens = (await axios.get("https://list.justswap.link/justswap.json")).data.tokens;
    for (const token of tokens) {
      token.chainId = constants.CHAIN_ID_TRON; // fix chainId
    }
    const tokensKeyed = _.keyBy(tokens, "address");
    tokensKeyed[constants.ZERO_ADDRESS_TRON] = {
      address: constants.ZERO_ADDRESS_TRON,
      symbol: "TRX",
      name: "Tron",
      decimals: 6,
      logoURI: constants.NETWORK_LOGO[constants.CHAIN_ID_TRON]
    };
    cachedTokens[chainId] = tokensKeyed;
  }

  return cachedTokens[chainId];
}

export async function getEvmTokenMetadata(chainId: number, tokenAddress: string): Promise<{ name: string, symbol: string, decimals: number }> {
  try {
    const multiCall3Contract = new ethers.Contract(
      constants.MULTICALL3_CONTRACT[chainId],
      multiCall3Interface,
      await getProviderForChain(chainId, false)
    );
    const calls = [
      { target: tokenAddress, callData: erc20Interface.encodeFunctionData("name") },
      { target: tokenAddress, callData: erc20Interface.encodeFunctionData("symbol") },
      { target: tokenAddress, callData: erc20Interface.encodeFunctionData("decimals") },
    ];
    const multiCallRet = (await multiCall3Contract.aggregate(calls))[1];

    return {
      name: erc20Interface.decodeFunctionResult("name", multiCallRet[0])[0],
      symbol: erc20Interface.decodeFunctionResult("symbol", multiCallRet[1])[0],
      decimals: Number(erc20Interface.decodeFunctionResult("decimals", multiCallRet[2])[0])
    };

  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function getSolanaTokenMetadata(mint: string): Promise<{ name: string, symbol: string, decimals: number, logo: string }> {
  if (mint === constants.WSOL) {
    return { name: "Solana", symbol: "SOL", decimals: 9, logo: null };
  }

  const mintPk = publicKey(mint);
  const metadataPda = findMetadataPda(umi, {
    mint: mintPk
  });
  const metadataPk = publicKey(metadataPda);

  const accounts = await umi.rpc.getAccounts([mintPk, metadataPk]);

  const decodedMintAccount = MintLayout.decode((accounts[0] as RpcAccount).data);
  const ret = { name: null, symbol: null, decimals: decodedMintAccount.decimals, logo: null };

  const metadataAccount = accounts[1];
  if (metadataAccount.exists) {
    const decodedMetadataAccount = deserializeMetadata(metadataAccount);
    ret.symbol = decodedMetadataAccount.symbol;
    ret.name = decodedMetadataAccount.name;
    const uri = decodedMetadataAccount.uri;
    if (uri) {
      try {
        const extraMetadata = await axios.get(uri, { timeout: 3000 });
        const logo = extraMetadata.data?.image;
        if (logo) ret.logo = logo;
      } catch (e) {}
    }
  }

  return ret;
}

export async function getAddressLookupTableAccounts(publicKeys: PublicKey[]): Promise<AddressLookupTableAccount[]> {
  const addressLookupTableAccountInfos = await solanaWeb3.getMultipleAccountsInfo(publicKeys);

  return addressLookupTableAccountInfos.reduce((acc, accountInfo, index) => {
    const addressLookupTableAddress = publicKeys[index];
    if (accountInfo) {
      const addressLookupTableAccount = new AddressLookupTableAccount({
        key: addressLookupTableAddress,
        state: AddressLookupTableAccount.deserialize(accountInfo.data),
      });
      acc.push(addressLookupTableAccount);
    }
    return acc;
  }, []);
}

export async function getTronTokenMetadata(tokenAddress: string): Promise<{ name: string, symbol: string, decimals: number }> {
  try {
    const multiCall3Address = constants.MULTICALL3_CONTRACT[constants.CHAIN_ID_TRON];
    const multiCall3Contract = getTronWeb().contract(multiCall3Abi, multiCall3Address);
    const tokenAddressHex = tronAddressToHex(tokenAddress);
    const calls = [
      [ tokenAddressHex, erc20Interface.encodeFunctionData("name") ],
      [ tokenAddressHex, erc20Interface.encodeFunctionData("symbol") ],
      [ tokenAddressHex, erc20Interface.encodeFunctionData("decimals") ],
    ];
    const multiCallRet = (await multiCall3Contract.aggregate(calls).call())[1];

    return {
      name: erc20Interface.decodeFunctionResult("name", multiCallRet[0])[0],
      symbol: erc20Interface.decodeFunctionResult("symbol", multiCallRet[1])[0],
      decimals: Number(erc20Interface.decodeFunctionResult("decimals", multiCallRet[2])[0])
    };

  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function getEvmTokenBalance(chainId: number, walletAddress: string, tokenAddress: string): Promise<bigint> {
  if (!walletAddress) return 0n;
  const provider = await getProviderForChain(chainId, false);
  if (tokenAddress.toLowerCase() === constants.NATIVE_ASSET_ADDRESS.toLowerCase()) {
    return provider.getBalance(walletAddress);
  } else {
    const contract = new ethers.Contract(tokenAddress, erc20Abi, provider);
    const balance = await contract.balanceOf(walletAddress);
    return balance;
  }
}

export async function getTronTokenBalances(walletAddress: string, tokenAddresses: string[]): Promise<Record<string, bigint>> {
  if (!walletAddress) return {};

  const walletAddressHex = tronAddressToHex(walletAddress);
  const multiCall3Address = constants.MULTICALL3_CONTRACT[constants.CHAIN_ID_TRON];
  const multiCall3AddressHex = tronAddressToHex(multiCall3Address);

  try {
    const multiCall3Contract = getTronWeb().contract(multiCall3Abi, multiCall3Address);

    const calls = [];
    const applyResultFunctions = [];
    const ret: Record<string, bigint> = {};
    for (const tokenAddress of tokenAddresses) {
      if (tokenAddress === constants.ZERO_ADDRESS_TRON) {
        calls.push([
          multiCall3AddressHex,
          multiCall3Interface.encodeFunctionData("getEthBalance", [walletAddressHex])
        ]);
        applyResultFunctions.push(data => {
          ret[tokenAddress] = multiCall3Interface.decodeFunctionResult("getEthBalance", data)[0];
        });
      } else {
        const tokenAddressHex = tronAddressToHex(tokenAddress);
        calls.push([
          tokenAddressHex,
          erc20Interface.encodeFunctionData("balanceOf", [walletAddressHex])
        ]);
        applyResultFunctions.push(data => {
          ret[tokenAddress] = erc20Interface.decodeFunctionResult("balanceOf", data)[0];
        });
      }
    }

    const multiCallRet = (await multiCall3Contract.tryAggregate(false, calls).call())[0];
    for (let i = 0; i < calls.length; i++) {
      if (multiCallRet[i][0]) {
        applyResultFunctions[i](multiCallRet[i][1]);
      }
    }
    return ret;

  } catch (e) {
    console.error(e);
    return {};
  }
}

export async function getEvmTokenBalances(chainId: number, walletAddress: string, tokenAddresses: string[]): Promise<Record<string, bigint>> {
  if (!walletAddress) return {};

  try {
    const provider = await getProviderForChain(chainId, false);
    const multiCall3Address = constants.MULTICALL3_CONTRACT[chainId];
    const multiCall3Contract = new ethers.Contract(multiCall3Address, multiCall3Interface, provider);

    const calls = [];
    const applyResultFunctions = [];
    const ret: Record<string, bigint> = {};
    for (const tokenAddress of tokenAddresses) {
      if (tokenAddress.toLowerCase() === constants.NATIVE_ASSET_ADDRESS.toLowerCase()) {
        calls.push({
          target: multiCall3Address,
          callData: multiCall3Interface.encodeFunctionData("getEthBalance", [walletAddress])
        });
        applyResultFunctions.push(data => {
          ret[tokenAddress.toLowerCase()] = multiCall3Interface.decodeFunctionResult("getEthBalance", data)[0];
        });
      } else {
        calls.push({
          target: tokenAddress,
          callData: erc20Interface.encodeFunctionData("balanceOf", [walletAddress])
        });
        applyResultFunctions.push(data => {
          ret[tokenAddress.toLowerCase()] = erc20Interface.decodeFunctionResult("balanceOf", data)[0];
        });
      }
    }

    const multiCallRet = await multiCall3Contract.tryAggregate(false, calls);
    for (let i = 0; i < calls.length; i++) {
      if (multiCallRet[i][0]) {
        applyResultFunctions[i](multiCallRet[i][1]);
      }
    }
    return ret;

  } catch (e) {
    console.error(e);
    return {};
  }
}

export function getCachedWalletTokenBalances(chainId: number, address: string) {
  const addressKey = utils.isEvmChain(chainId) ? address.toLowerCase() : address;
  return utils.jsonClone(cachedWalletBalances[chainId]?.[addressKey] || {});
}

export async function getEvmWalletListedAndCustomTokenBalances(chainId: number, address: string) {
  const tokenBalances: Record<string, string> = {};

  if (address && chainId) {
    const fetchListedTokenBalances = axios.get(`https://balances.1inch.io/v1.1/${chainId}/balances/${address}`);

    const customTokenAddresses = customTokensService.getAllCustomTokens(chainId).map(it => it.address);
    const fetchCustomTokenBalances = getEvmTokenBalances(chainId, address, customTokenAddresses);

    const listedTokenBalances = (await fetchListedTokenBalances).data;
    const customTokenBalances = await fetchCustomTokenBalances;

    const balanceObjs = [listedTokenBalances, customTokenBalances];

    for (const balanceObj of balanceObjs) {
      for (const address in balanceObj) {
        const balanceBN = BigNumber(balanceObj[address]);
        if (balanceBN.gt(0)) {
          tokenBalances[address.toLowerCase()] = balanceBN.toFixed();
        }
      }
    }
  }

  _.set(cachedWalletBalances, [chainId, address.toLowerCase()], tokenBalances);

  return getCachedWalletTokenBalances(chainId, address);
}

let maxKnownSolanaSlot = 0;
export async function getSolanaWalletTokenBalances(address: string) {
  const tokenBalances: Record<string, string> = {};

  if (address) {
    const rpcResults = (await axios.post(solanaWeb3.rpcEndpoint, [
      {
        method: "getAccountInfo",
        jsonrpc: "2.0",
        params: [
          address,
          {
            encoding: "base64",
            commitment: "confirmed",
            minContextSlot: maxKnownSolanaSlot
          }
        ],
        id: crypto.randomUUID()
      },
      {
        method: "getTokenAccountsByOwner",
        jsonrpc: "2.0",
        params: [
          address,
          {
            programId: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
          },
          {
            encoding: "jsonParsed",
            commitment: "confirmed",
            minContextSlot: maxKnownSolanaSlot
          }
        ],
        id: crypto.randomUUID()
      }
    ])).data;

    for (const rpcResult of rpcResults) {
      const slot = rpcResult.result.context.slot;
      if (slot > maxKnownSolanaSlot) {
        maxKnownSolanaSlot = slot;
      }
    }

    for (const account of rpcResults[1].result.value) {
      const info = account.account.data.parsed.info;
      tokenBalances[info.mint] = info.tokenAmount.amount;
      /*const ata = getAssociatedTokenAddressSync(new PublicKey(info.mint), new PublicKey(address));
      const tokenAccountAddress = account.pubkey;
      if (ata.toString() === tokenAccountAddress) {
      }*/
    }

    // This overrides WSOL balance with SOL balance
    tokenBalances[constants.WSOL] = rpcResults[0].result.value?.lamports || 0;
  }

  _.set(cachedWalletBalances, [constants.CHAIN_ID_SOLANA, address], tokenBalances);

  return getCachedWalletTokenBalances(constants.CHAIN_ID_SOLANA, address);
}



export async function solanaSpamSendTx(transaction: Transaction | VersionedTransaction, times = 3) {
  const serialized = Buffer.from(transaction.serialize()).toString("base64");
  const signature = await solanaWeb3.sendEncodedTransaction(serialized, { skipPreflight: true, maxRetries: 0 });

  (async () => {
    const postBody = JSON.stringify(serialized);
    for (let i = 0; i < times; i++) {
      axios.post("https://worker.jup.ag/send-transaction", postBody, {
        headers: {
          "content-type": "application/json"
        }
      }).then(_.noop).catch(_.noop);
      solanaWeb3.sendEncodedTransaction(serialized, { skipPreflight: true, maxRetries: 0 }).then(_.noop).catch(_.noop);
      await utils.delay(2000);
    }
  })();

  return signature;
}

export async function getTronWalletTokenBalances(address: string) {
  const chainId = constants.CHAIN_ID_TRON;

  const tokenBalances: Record<string, string> = {};

  if (address && chainId) {
    const listedTokens = await getListedTokens(chainId);
    const customTokenAddresses = customTokensService.getAllCustomTokens(chainId).map(it => it.address);

    const allTokenAddresses = _.uniq([
      ...Object.keys(listedTokens),
      ...customTokenAddresses
    ]);

    const balances = await getTronTokenBalances(address, allTokenAddresses);
    for (const tokenAddress in balances) {
      const balance = balances[tokenAddress];
      if (balance > 0) {
        tokenBalances[tokenAddress] = balance.toString();
      }
    }
  }

  _.set(cachedWalletBalances, [chainId, address], tokenBalances);

  return getCachedWalletTokenBalances(chainId, address);
}

export async function getEvmGasPrice(chainId: number) {
  const provider = await getProviderForChain(chainId, false);
  return ethers.getBigInt(await provider.send("eth_gasPrice", []));
}

export function switchEvmChain(chainId: number) {
  return getBrowserProvider().send("wallet_switchEthereumChain", [{ chainId: "0x" + chainId.toString(16) }]);
}

export function tronAddressToHex(value): string {
  return (window.tronLink?.tronWeb || window.tronWeb).address.toHex(value).replace("41", "0x");
}

export function hexToTronAddress(value): string {
  return (window.tronLink?.tronWeb || window.tronWeb).address.fromHex(value.replace("0x", "41"));
}
