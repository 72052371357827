<template>
  <b-row no-gutters class="bg-highlight-hover px-2 py-1">
    <b-col cols="7">
      <div class="d-flex flex-row align-items-center">
        <img class="img-exchange" :src="exchangeLogoSrc">
        <div>
          <div>
            <small class="text-secondary">
              {{ exchangeName }}
              <template v-if="type === 'dex'">
                ·
                <span class="cursor-pointer" @click="copyTokenAddress">
                  <b-icon-files /> {{ tokenAddressShortened }}
                </span>
              </template>
            </small>
          </div>
          <template v-if="showAllPrices">
            <div class="text-success">{{ displayBuyPrice }}</div>
            <div>{{ displayIndexPrice }}</div>
            <div class="text-danger">{{ displaySellPrice }}</div>
          </template>
          <div v-else :class="priceTextClass">{{ displayPrice }}</div>
        </div>
      </div>
    </b-col>
    <b-col cols="5">
      <div><small class="text-secondary">{{ displayVolLiqType }}</small></div>
      <div>{{ volLiqDisplayValue }}</div>
      <template v-if="displayFundingRate && showAllPrices">
        <div><small class="text-secondary">Funding / Countdown</small></div>
        <div>
          <span class="mr-3" :class="displayFundingRateTextClass">{{ displayFundingRate }}%</span>
          <span>{{ displayFundingCountdown }}</span>
        </div>
      </template>
    </b-col>
    <b-col>
      <small v-if="type === 'dex'" class="text-secondary">
        <router-link v-if="solanaSwapRouterLinkObject" class="text-secondary" :to="solanaSwapRouterLinkObject">Swap</router-link>
        <a v-else-if="tronSwapUrl" class="text-secondary" :href="tronSwapUrl" target="_blank">Swap</a>
        <a v-else-if="tonSwapUrl" class="text-secondary" :href="tonSwapUrl" target="_blank">Swap</a>
        <router-link v-else class="text-secondary" :to="swapRouterLinkObject">Swap</router-link> ·
        <a class="text-secondary" :href="explorerUrl" target="_blank">Explorer</a>
        <template v-if="dexScreenerUrl && dexToolsUrl">
          ·
          <a :href="dexScreenerUrl" target="_blank" class="text-secondary">DEXScreener</a> ·
          <a :href="dexToolsUrl" target="_blank" rel="noopener noreferrer" class="text-secondary">DEXTools</a>
        </template>
      </small>
      <small v-if="type === 'cex'" class="text-secondary">
        <span class="cursor-pointer underline-hover" @click="cexOpenTrade">Trade</span>
        <template v-if="!item.exchange.endsWith('-futures')">
          ·
          <span class="cursor-pointer underline-hover" @click="onClickDeposit">Deposit ({{ depositText }})</span> ·
          <span class="cursor-pointer underline-hover" @click="onClickWithdraw">Withdraw ({{ withdrawText }})</span>
        </template>
      </small>
    </b-col>
  </b-row>
</template>

<style lang="scss" scoped>
  .img-exchange {
    width: 28px;
    height: 28px;
    margin-right: 0.5em;
  }
  .bg-highlight-hover:hover {
    background-color: lightyellow;
  }
</style>

<script lang="ts">
  import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
  import _ from "lodash";
  import { DateTime } from "luxon";
  import * as constants from "@/constants";
  import BaseComponent from "@/components/BaseComponent";
  import BigNumber from "bignumber.js";
  import * as marketDataService from "@/services/marketDataService";
  import * as utils from "@/utils";
  import ArbitrageWithdrawCexAssetModal from "@/components/arbitrage/ArbitrageWithdrawCexAssetModal.vue";
  import ArbitrageDepositCexAssetModal from "@/components/arbitrage/ArbitrageDepositCexAssetModal.vue";

  const volNumberFormatUsd = new Intl.NumberFormat("en", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  const futuresAssetSymbolOverride = {
    "mexc-futures": {
      "STEPN": "GMT" // Futures is GMT, spot is STEPN
    }
  };

  @Component({
    components: {ArbitrageDepositCexAssetModal, ArbitrageWithdrawCexAssetModal}
  })
  export default class ArbitrageSubItem extends BaseComponent {

    @Prop() coingeckoId;
    @Prop() item;
    @Prop() type;
    @Prop() priceType;
    @Prop() showAllPrices;

    fundingCountdownIntervalHandler;
    displayFundingCountdown = "";

    get exchangeName() {
      if (this.type === "dex") {
        return constants.chainNames[this.item.chainId];
      } else if (this.type === "cex") {
        return constants.exchangeNames[this.item.exchange];
      }
    }

    get exchangeLogoSrc() {
      if (this.type === "dex") {
        return constants.NETWORK_LOGO[this.item.chainId];
      } else if (this.type === "cex") {
        return constants.EXCHANGE_LOGO[this.item.exchange];
      }
    }

    get priceTextClass() {
      if (this.priceType === "trade") {
        if (this.item.buyHere) {
          return "text-success";
        } else if (this.item.sellHere) {
          return "text-danger";
        }
      }
    }

    get displayPrice() {
      if (this.priceType === "trade") {
        if (this.item.buyHere && this.item.priceBuying) {
          return BigNumber(this.item.priceBuying).precision(6).toFixed();
        } else if (this.item.sellHere && this.item.priceSelling) {
          return BigNumber(this.item.priceSelling).precision(6).toFixed();
        }
      }
      return BigNumber(this.item.price).precision(6).toFixed();
    }

    get displayBuyPrice() {
      if (this.item.priceBuying) {
        return BigNumber(this.item.priceBuying).precision(6).toFixed();
      }
    }

    get displayIndexPrice() {
      return BigNumber(this.item.price).precision(6).toFixed();
    }

    get displaySellPrice() {
      if (this.item.priceSelling) {
        return BigNumber(this.item.priceSelling).precision(6).toFixed();
      }
    }

    get displayVolLiqType() {
      if (this.type === "dex") {
        return "Liquidity";
      } else if (this.type === "cex") {
        if (this.item.depthN2 && this.item.depthP2) {
          if (this.item.buyHere) {
            return "+2% depth";
          } else if (this.item.sellHere) {
            return "-2% depth";
          } else {
            return "±2% depth";
          }
        } else {
          return "24h volume";
        }
      }
    }

    get volLiqDisplayValue() {
      if (this.type === "dex") {
        return volNumberFormatUsd.format(this.item.liquidity);
      } else if (this.type === "cex") {
        let val;
        if (this.item.depthN2 && this.item.depthP2) {
          if (this.item.buyHere) {
            val = this.item.depthP2;
          } else if (this.item.sellHere) {
            val = this.item.depthN2;
          } else {
            val = Math.min(this.item.depthN2, this.item.depthP2);
          }
        } else {
          val = this.item.volume24h;
        }
        return volNumberFormatUsd.format(val);
      }
    }

    get displayFundingRate() {
      const { exchange, nextFundingRate } = this.item;
      if (this.type === "cex" && exchange.endsWith("-futures")) {
        return BigNumber(nextFundingRate).multipliedBy(100).toFixed(4);
      }
    }

    get displayFundingRateTextClass() {
      const { exchange, nextFundingRate } = this.item;
      if (this.type === "cex" && exchange.endsWith("-futures")) {
        const nextFundingRateBN = BigNumber(nextFundingRate);
        if (nextFundingRateBN.gt(0)) {
          return "text-success";
        } else if (nextFundingRateBN.lt(0)) {
          return "text-danger";
        }
      }
    }

    get explorerUrl() {
      if (this.type === "dex") {
        return utils.getExplorerUrl(this.item.chainId, "token", this.item.address);
      }
    }

    get dexScreenerUrl() {
      if (this.type === "dex") {
        const { chainId, address } = this.item;
        const dexScreenerChainId = constants.dexScreenerChainIds[chainId];
        if (dexScreenerChainId) {
          return `https://dexscreener.com/${dexScreenerChainId}/${address}`;
        }
      }
    }

    get dexToolsUrl() {
      if (this.type === "dex") {
        const { chainId, address } = this.item;
        const dexToolsChainId = constants.dexToolsChainIds[chainId];
        if (dexToolsChainId) {
          return `https://www.dextools.io/app/en/${dexToolsChainId}/pair-explorer/${address}`;
        }
      }
    }

    get tokenAddressShortened() {
      if (this.type === "dex") {
        return this.item.address.slice(0, 6) + "…" + this.item.address.slice(-4);
      }
    }

    get depositText() {
      if (this.type === "cex" && !this.item.exchange.endsWith("-futures")) {
        const networks = this.item.networks;
        const knownNetworks = [];
        const unknownNetworks = [];
        for (const n of networks) {
          if (n.depositEnabled) {
            if (n.evmChainId) {
              knownNetworks.push(n);
            } else {
              unknownNetworks.push(n);
            }
          }
        }
        const textParts = knownNetworks.map(n => constants.chainNamesShort[n.evmChainId]);
        if (unknownNetworks.length) {
          textParts.push(unknownNetworks.length + " more");
        }
        if (textParts.length) {
          return textParts.join(", ");
        } else {
          return "disabled";
        }
      }
    }

    get withdrawText() {
      if (this.type === "cex" && !this.item.exchange.endsWith("-futures")) {
        const networks = this.item.networks;
        const knownNetworks = [];
        const unknownNetworks = [];
        for (const n of networks) {
          if (n.withdrawEnabled) {
            if (n.evmChainId) {
              knownNetworks.push(n);
            } else {
              unknownNetworks.push(n);
            }
          }
        }
        const textParts = knownNetworks.map(n => constants.chainNamesShort[n.evmChainId]);
        if (unknownNetworks.length) {
          textParts.push(unknownNetworks.length + " more");
        }
        if (textParts.length) {
          return textParts.join(", ");
        } else {
          return "disabled";
        }
      }
    }

    get swapRouterLinkObject() {
      if (this.type === "dex") {
        return {
          path: "/arbitrage-focus",
          query: {
            coingeckoId: this.coingeckoId,
            chainId: this.item.chainId,
            side: this.item.buyHere ? "buy" : "sell"
          }
        };
      }
    }

    get solanaSwapRouterLinkObject() {
      if (this.type === "dex") {
        const {chainId, address, buyHere} = this.item;
        if (chainId === constants.CHAIN_ID_SOLANA) {
          return {
            path: "/arbitrage-focus-solana",
            query: {
              coingeckoId: this.coingeckoId,
              side: this.item.buyHere ? "buy" : "sell"
            }
          };
        }
      }
    }

    get tonSwapUrl() {
      if (this.type === "dex") {
        const {chainId, address, buyHere} = this.item;
        if (chainId === constants.CHAIN_ID_TON) {
          if (buyHere) {
            return `https://app.ston.fi/swap?chartVisible=false&chartInterval=1w&ft=USD%E2%82%AE&tt=${address}&fa=5000`;
          } else {
            return `https://app.ston.fi/swap?chartVisible=false&chartInterval=1w&tt=USD%E2%82%AE&ft=${address}`;
          }
        }
      }
    }

    get tronSwapUrl() {
      if (this.type === "dex") {
        const {chainId, address, buyHere} = this.item;
        if (chainId === constants.CHAIN_ID_TRON) {
          return `https://sun.io`;
        }
      }
    }

    @Watch("item", { immediate: true, deep: true })
    onItemChange() {
      if (this.item.exchange?.endsWith("-futures")) {
        this.refreshDisplayFundingCountdown();
        clearInterval(this.fundingCountdownIntervalHandler);
        this.fundingCountdownIntervalHandler = setInterval(this.refreshDisplayFundingCountdown, 500);
      }
    }

    refreshDisplayFundingCountdown() {
      const diff = DateTime.fromISO(this.item.nextFundingTime).diffNow();
      if (diff.valueOf() >= 0) {
        this.displayFundingCountdown = diff.toFormat("hh:mm:ss");
      }
    }

    async copyTokenAddress() {
      if (this.type === "dex") {
        await navigator.clipboard.writeText(this.item.address);
        this.toastSuccess("Address copied", this.item.address);
      }
    }

    async cexOpenTrade() {
      if (this.type === "cex") {
        const { exchange, asset } = this.item;
        if (exchange.endsWith("-futures")) {
          const futuresAsset = futuresAssetSymbolOverride[exchange]?.[asset] || asset;
          if (exchange === "binance-futures") {
            open(`https://www.binance.com/en/futures/${futuresAsset.toUpperCase()}USDT`);
          } else if (exchange === "huobi-futures") {
            open(`https://www.huobi.com/en-us/futures/linear_swap/exchange#contract_code=${futuresAsset.toUpperCase()}-USDT`);
          } else if (exchange === "okx-futures") {
            open(`https://www.okx.com/trade-swap/${futuresAsset.toLowerCase()}-usdt-swap`);
          } else if (exchange === "kucoin-futures") {
            open(`https://www.kucoin.com/futures/trade/${futuresAsset.toUpperCase()}USDTM`);
          } else if (exchange === "mexc-futures") {
            open(`https://futures.mexc.com/exchange/${futuresAsset.toUpperCase()}_USDT`);
          } else if (exchange === "gate-futures") {
            open(`https://www.gate.io/futures/USDT/${futuresAsset.toUpperCase()}_USDT`);
          } else if (exchange === "bybit-futures") {
            open(`https://www.bybitglobal.com/trade/usdt/${futuresAsset.toUpperCase()}USDT`);
          }

        } else {
          const tryQuoteAssets = ["USDT", "USDC", "FDUSD", "BTC", "ETH", "BNB"];
          try {
            this.showLoading();
            const spotMarkets = await marketDataService.getCexMarkets({
              exchange,
              baseAsset: asset
            });
            let openUrl;
            for (const quoteAsset of tryQuoteAssets) {
              const spotMarket = spotMarkets.find(it => it.quoteAsset.toUpperCase() === quoteAsset);
              if (spotMarket) {
                if (exchange === "binance") {
                  openUrl = `https://www.binance.com/en/trade/${spotMarket.baseAsset}_${quoteAsset}`;
                } else if (exchange === "huobi") {
                  openUrl = `https://www.huobi.com/en-us/exchange/${spotMarket.baseAsset}_${spotMarket.quoteAsset}`;
                } else if (exchange === "okx") {
                  openUrl = `https://www.okx.com/trade-spot/${spotMarket.symbol.toLowerCase()}`;
                } else if (exchange === "kucoin") {
                  openUrl = `https://www.kucoin.com/trade/${spotMarket.symbol}`;
                } else if (exchange === "mexc") {
                  openUrl = `https://www.mexc.com/exchange/${spotMarket.baseAsset}_${quoteAsset}`;
                } else if (exchange === "gate") {
                  openUrl = `https://www.gate.io/trade/${spotMarket.symbol}`;
                } else if (exchange === "bybit") {
                  openUrl = `https://www.bybitglobal.com/trade/spot/${spotMarket.baseAsset}/${quoteAsset}`;
                }
                if (openUrl) {
                  open(openUrl);
                  break;
                }
              }
            }
          } catch (e) {
            this.toastError(e);
          } finally {
            this.hideLoading();
          }
        }
      }
    }

    /*async dexOpenPairExplorer(explorer: "dexScreener" | "dexTools") {
      if (this.type === "dex") {
        try {
          this.showLoading();
          const { chainId, address } = this.item;
          const dexPools = await marketDataService.getDexPools(chainId, address);
          const pool = dexPools[0];
          if (pool) {
            if (explorer === "dexScreener") {
              open(`https://dexscreener.com/${constants.dexScreenerChainIds[chainId]}/${pool.address}`);
            } else if (explorer === "dexTools") {
              const url = `https://www.dextools.io/app/en/${constants.dexToolsChainIds[chainId]}/pair-explorer/${pool.address}`;
              open(url, '_blank', 'noopener,noreferrer');
            }
          }

        } catch (e) {
          this.toastError(e);
        } finally {
          this.hideLoading();
        }
      }
    }*/

    destroyed() {
      clearInterval(this.fundingCountdownIntervalHandler);
    }

    @Emit("onClickWithdraw")
    onClickWithdraw() {
      // console.log("ArbitrageSubItem onClickWithdraw", this.item);
      return this.item; // pass back to parent
    }

    @Emit("onClickDeposit")
    onClickDeposit() {
      // console.log("ArbitrageSubItem onClickDeposit", this.item);
      return this.item; // pass back to parent
    }

  }
</script>
