import axios from "axios";
import _ from "lodash";
import * as web3Service from "./web3Service";
import {Transaction} from "@solana/web3.js";

const axiosInstance = axios.create({
  baseURL: "https://quote-api.jup.ag/v6",
  timeout: 10000,
});

export async function getQuote(params: any) {
  return (await axiosInstance.get("/quote", { params })).data;
}

export async function getSwap(body: any) {
  return (await axiosInstance.post(`/swap`, body)).data;
}

export async function getSwapInstruction(body: any) {
  return (await axiosInstance.post(`/swap-instructions`, body)).data;
}
