<template>
  <div>
    <div class="text-monospace small text-break" style="white-space: pre-wrap">{{ rawDataText }}</div>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
  import _ from "lodash";
  import BaseComponent from "@/components/BaseComponent";

  @Component
  export default class DepositAddressDetailsModal extends BaseComponent {

    @Prop() modalId?: string;
    @Prop({ required: true }) addressDetails: any;

    get rawDataText() {
      try {
        return JSON.stringify(this.addressDetails.rawData, null, 2);

      } catch (e) {
        return this.addressDetails.rawData;
      }
    }

    mounted() {

    }

  }
</script>
