<template>
  <main>
    <div style="max-width: 1366px;" class="mx-auto px-3 py-5">
      <div class="d-flex flex-row align-items-center justify-content-between mb-4">
        <div>
          <h4 class="d-inline-block m-0 mr-4">Delist Trade Orders</h4>
          <b-form-radio-group buttons button-variant="outline-primary" class="d-inline-block"
                              :disabled="isLoading"
                              :options="delistExchangeOptions" v-model="delistExchange" @change="onChanceDelistExchange" />
        </div>
        <div>
<!--          <b-button variant="link" class="text-secondary mr-3" v-b-modal:account-settings-modal>Account settings</b-button>-->
          <b-button variant="link" class="text-secondary mr-3" @click="onClickCancelAll">Cancel all</b-button>
          <b-button variant="outline-primary" v-b-modal:quick-setup-modal>
            <b-icon-check2-all/> Place all
          </b-button>
        </div>
      </div>
      <b-row class="text-secondary border-bottom py-2">
        <b-col cols="3">Coin</b-col>
        <b-col cols="3">Binance Futures</b-col>
        <b-col cols="3">OKX Futures</b-col>
        <b-col cols="3">Bybit Futures</b-col>
      </b-row>
      <b-row v-for="coin in delistTradeOrdersByCoin" :key="coin.coingeckoId" class="border-bottom align-items-center">
        <b-col cols="3">
          <div class="d-flex flex-row">
            <img class="img-coin" :src="coin.logoUrl">
            <div>
              <div>{{ coin.cexAssets[delistExchange]?.asset.toUpperCase() }}</div>
              <a :href="'https://www.coingecko.com/en/coins/' + coin.coingeckoId" target="_blank" class="text-secondary">
                {{ coin.name }}
              </a>
            </div>
          </div>
        </b-col>
        <b-col cols="3" v-for="exchange in tradeExchanges" :key="exchange"
               class="py-2 small" :class="coin.futuresMarkets[exchange] && 'cell-item bg-hover'">
          <div v-if="coin.futuresMarkets[exchange]" class="d-flex flex-row align-items-center">
            <div class="cursor-pointer" @click="onClickCell(coin, exchange)">
              <div>{{ coin.futuresMarkets[exchange].baseAsset + 'USDT Perpetual' }}</div>
              <template v-if="coin.futuresMarkets[exchange].order">
                <template v-if="coin.futuresMarkets[exchange].order.status === 'pending'">
                  <div>
                    <span class="text-secondary">Vol: </span>
                    <span>{{ coin.futuresMarkets[exchange].order.displayNotional }}</span>
                  </div>
                  <div>
                    <small class="mr-2">
                      <span class="text-secondary">Max impact:</span> {{ coin.futuresMarkets[exchange].order.maxDropPercent }}%
                    </small>
                    <small v-if="coin.futuresMarkets[exchange].order.takeProfitPercent" class="text-success mr-2">
                      TP: {{ coin.futuresMarkets[exchange].order.takeProfitPercent }}%
                    </small>
                    <small v-if="coin.futuresMarkets[exchange].order.stopLossPercent" class="text-danger">
                      SL: {{ coin.futuresMarkets[exchange].order.stopLossPercent }}%
                    </small>
                  </div>
                </template>
                <div v-else>{{ coin.futuresMarkets[exchange].order.status }}</div>
              </template>
              <div v-else class="text-primary">Place order</div>
            </div>
            <div v-if="coin.futuresMarkets[exchange].order && coin.futuresMarkets[exchange].order.status === 'pending'"
                 class="action-btns-container ml-4">
              <b-button variant="link" class="text-secondary" size="sm" @click="onClickCancel(coin.futuresMarkets[exchange].order)">
                <b-icon-x-circle />
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-modal id="quick-setup-modal" title="Quick Setup" hide-footer no-fade>
      <QuickSetupDelistTradeOrdersModal modal-id="quick-setup-modal" @done="fetchAll" />
    </b-modal>
    <b-modal id="place-order-modal" title="Place order" hide-footer no-fade>
      <PlaceDelistTradeOrderModal
        modal-id="place-order-modal"
        :coin="placeOrderModalData.coin"
        :delistExchange="placeOrderModalData.delistExchange"
        :tradeExchange="placeOrderModalData.tradeExchange"
        @done="fetchAll" />
    </b-modal>
    <b-modal id="account-settings-modal" title="Account settings" hide-footer no-fade>
      <DelistTradeAccountSettingsModal modal-id="account-settings-modal" />
    </b-modal>
  </main>
</template>

<style lang="scss" scoped>
.img-coin {
  width: 36px;
  height: 36px;
  margin-right: 0.5em;
}
.cell-item .action-btns-container {
  display: none;
}
.cell-item:hover .action-btns-container {
  display: block;
}
</style>

<script lang="ts">
  import {Component, Vue} from 'vue-property-decorator';
  import _ from "lodash";
  import BaseComponent from "@/components/BaseComponent";
  import * as cexAccountService from "@/services/cexAccountService";
  import * as delistTradeWatchOrderService from "@/services/delistTradeWatchOrderService";
  import QuickSetupDelistTradeOrdersModal from "@/components/delist-trade/QuickSetupDelistTradeOrdersModal.vue";
  import PlaceDelistTradeOrderModal from "@/components/delist-trade/PlaceDelistTradeOrderModal.vue";
  import DelistTradeAccountSettingsModal from "@/components/delist-trade/DelistTradeAccountSettingsModal.vue";

  const numberFormatUsd = new Intl.NumberFormat("en", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  @Component({
    components: {DelistTradeAccountSettingsModal, PlaceDelistTradeOrderModal, QuickSetupDelistTradeOrdersModal}
  })
  export default class DelistTradeOrders extends BaseComponent {

    readonly tradeExchanges = ["binance-futures", "okx-futures", "bybit-futures"];

    delistExchange = "binance";
    delistExchangeOptions = [
      { value: "binance", text: "Binance" },
      { value: "upbit", text: "Upbit" },
      { value: "bybit", text: "Bybit" },
      { value: "bybit-futures", text: "Bybit Futures" },
    ];

    delistTradeOrdersByCoin = [];
    isLoading = true;

    placeOrderModalData = {
      coin: null,
      delistExchange: "",
      tradeExchange: ""
    };

    mounted() {
      document.title = "Delist Trade Orders";
      this.fetchAll();
    }

    async fetchAll() {
      try {
        this.isLoading = true;
        this.delistTradeOrdersByCoin = (await delistTradeWatchOrderService.getAllByCoins(this.delistExchange))
          .filter(c => !_.isEmpty(c.futuresMarkets));

        for (const coin of this.delistTradeOrdersByCoin) {
          for (const tradeExchange in coin.futuresMarkets) {
            const order = coin.futuresMarkets[tradeExchange].order;
            if (order) {
              order.displayNotional = numberFormatUsd.format(order.notional);
            }
          }
        }

      } catch (e) {
        console.error(e);
        this.toastError(e);

      } finally {
        this.isLoading = false;
      }
    }

    onChanceDelistExchange() {
      this.delistTradeOrdersByCoin = [];
      this.fetchAll();
    }

    onClickCell(coin, tradeExchange) {
      if (coin.futuresMarkets[tradeExchange]) {
        const order = coin.futuresMarkets[tradeExchange].order;
        if (!order || order.status === 'pending') {
          this.placeOrderModalData.coin = coin;
          this.placeOrderModalData.delistExchange = this.delistExchange;
          this.placeOrderModalData.tradeExchange = tradeExchange;
          this.$bvModal.show("place-order-modal");
        }
      }
    }

    async onClickCancel(order) {
      try {
        this.showLoading();
        await delistTradeWatchOrderService.cancel(order.id);
        await this.fetchAll();

      } catch (e) {
        console.error(e);
        this.toastError(e);

      } finally {
        this.hideLoading();
      }
    }

    async onClickCancelAll() {
      const ok = await this.$bvModal.msgBoxConfirm("Cancel all pending orders?", { noFade: true });
      if (ok) {
        try {
          this.showLoading();
          await delistTradeWatchOrderService.cancelAll();
          await this.fetchAll();

        } catch (e) {
          console.error(e);
          this.toastError(e);

        } finally {
          this.hideLoading();
        }
      }
    }

  }
</script>
