<template>
  <b-form @keydown.enter.prevent @submit.prevent="submitForm">
    <b-tabs content-class="mt-3">
      <b-tab title="PND" active>
        <b-row class="mb-3 align-items-center">
          <b-col :cols="leftColSize" class="text-right">
            <div>Exchanges</div>
            <div><small class="text-secondary">Spot</small></div>
          </b-col>
          <b-col :cols="rightColSize">
            <b-form-checkbox-group v-model="pndExchanges" :options="pndExchangeOptions"></b-form-checkbox-group>
          </b-col>
        </b-row>
        <b-row class="mb-3 align-items-center">
          <b-col :cols="leftColSize" class="text-right">
            <div>Quote coins</div>
            <div><small class="text-secondary">Binance only</small></div>
          </b-col>
          <b-col :cols="rightColSize">
            <b-form-checkbox-group v-model="pndQuoteAssets" :options="pndQuoteAssetOptions"></b-form-checkbox-group>
          </b-col>
        </b-row>
        <b-row class="mb-3 align-items-center">
          <b-col :cols="leftColSize" class="text-right">Min change (%)</b-col>
          <b-col :cols="2">
            <b-form-input type="number" min="3" step="0.01" v-model="pndMinChangePercent" required autocomplete="off"></b-form-input>
          </b-col>
          <b-col :cols="3" class="text-right">Min 5m volume</b-col>
          <b-col :cols="3">
            <b-form-input type="number" min="100" v-model="pndMin5mVolume" required autocomplete="off"></b-form-input>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="CEX updates">
        <b-row class="mb-3 align-items-center">
          <b-col :cols="leftColSize" class="text-right">
            <div>Exchanges</div>
          </b-col>
          <b-col :cols="rightColSize">
            <b-form-checkbox-group v-model="cexUpdateExchanges" :options="cexUpdateExchangesOptions"></b-form-checkbox-group>
          </b-col>
        </b-row>
        <b-row class="mb-3 align-items-center">
          <b-col :cols="leftColSize" class="text-right">
            <div>Update type</div>
          </b-col>
          <b-col :cols="rightColSize">
            <b-form-checkbox-group v-model="cexUpdateTypes" :options="cexUpdateTypesOptions"></b-form-checkbox-group>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
    <div class="text-center">
      <b-button type="submit" variant="primary">Save</b-button>
    </div>
  </b-form>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
  import _ from "lodash";
  import BaseComponent from "@/components/BaseComponent";
  import * as utils from "@/utils";

  @Component
  export default class FeedSettingsModal extends BaseComponent {

    @Prop({ required: true }) modalId!: string;
    @Prop() feedSettings;

    readonly leftColSize = 3;
    readonly rightColSize = 9;

    pndExchanges = ["binance", "huobi", "okx", "kucoin", "mexc", "bybit", "upbit"];
    pndExchangeOptions = [
      { value: "binance", text: "Binance" },
      { value: "huobi", text: "Huobi" },
      { value: "okx", text: "OKX" },
      { value: "kucoin", text: "Kucoin" },
      { value: "mexc", text: "MEXC" },
      { value: "bybit", text: "Bybit" },
      { value: "upbit", text: "Upbit (KRW)" },
    ];

    pndQuoteAssets = ["USDT", "BTC"];
    pndQuoteAssetOptions = ["USDT", "BTC"];

    pndMinChangePercent = 5;
    pndMin5mVolume = 1000;

    cexUpdateExchanges = ["binance", "huobi", "okx", "kucoin", "mexc", "bybit"];
    cexUpdateExchangesOptions = [
      { value: "binance", text: "Binance" },
      { value: "huobi", text: "Huobi" },
      { value: "okx", text: "OKX" },
      { value: "kucoin", text: "Kucoin" },
      { value: "mexc", text: "MEXC" },
      { value: "bybit", text: "Bybit" },
    ];

    cexUpdateTypes = ["cexAnnouncement", "newCexAsset", "dwStatusChange"];
    cexUpdateTypesOptions = [
      { value: "cexAnnouncement", text: "New coin (announcement)" },
      { value: "newCexAsset", text: "New coin (API)" },
      { value: "dwStatusChange", text: "Deposit/withdraw status change" },
    ];

    created() {
      Object.assign(this, utils.jsonClone(this.feedSettings));
    }

    mounted() {

    }

    submitForm() {
      const obj = {
        pndExchanges: this.pndExchanges,
        pndQuoteAssets: this.pndQuoteAssets,
        pndMinChangePercent: +this.pndMinChangePercent,
        pndMin5mVolume: +this.pndMin5mVolume,
        cexUpdateExchanges: this.cexUpdateExchanges,
        cexUpdateTypes: this.cexUpdateTypes
      };
      localStorage.setItem("feed", JSON.stringify(obj));
      this.$bvModal.hide(this.modalId);
      this.done(obj);
    }

    @Emit()
    done(obj) {
      return obj;
    }

  }
</script>
