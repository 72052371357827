<template>
  <b-form @keydown.enter.prevent @submit.prevent="submitForm">
    <b-form-group label="Label">
      <b-form-input type="text" v-model="formInput.label" minlength="0" maxlength="100"></b-form-input>
    </b-form-group>
    <b-form-group label="Address">
      <b-form-input type="text" class="text-monospace" v-model="formInput.address" minlength="0" maxlength="200" required></b-form-input>
    </b-form-group>
    <b-form-group label="Memo/tag" v-if="shouldShowMemoField">
      <b-form-input type="text" class="text-monospace" v-model="formInput.memo" minlength="0" maxlength="200"></b-form-input>
    </b-form-group>
    <div v-if="formErrorMsg" class="text-danger">{{ formErrorMsg }}</div>
    <div class="text-right">
      <b-button type="submit" variant="primary" @click="beforeSubmit">Save</b-button>
    </div>
  </b-form>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
  import _ from "lodash";
  import BaseComponent from "@/components/BaseComponent";
  import * as savedWalletsService from "@/services/savedWalletsService";

  @Component
  export default class SaveWalletModal extends BaseComponent {

    @Prop({ required: true }) modalId!: string;
    @Prop({ default: "" }) prefillAddress!: string;
    @Prop({ default: "" }) prefillMemo!: string;
    @Prop() type!: string;

    get shouldShowMemoField() {
      return !this.type || this.type === "all";
    }

    formInput = {
      label: "",
      address: "",
      memo: "",
    };

    formErrorMsg = "";

    mounted() {
      this.formInput.address = this.prefillAddress;
      this.formInput.memo = this.prefillMemo;
    }

    beforeSubmit() {
      for (const k in this.formInput) {
        this.formInput[k] = this.formInput[k].trim();
      }
    }

    submitForm() {
      try {
        savedWalletsService.addSavedWallet2(this.formInput, this.type);
        this.$bvModal.hide(this.modalId);
        this.done();

      } catch (e) {
        console.error(e);
        this.formErrorMsg = e.message;
      }
    }

    @Emit()
    done() {
      return this.formInput;
    }

  }
</script>
