<template>
  <div>
    <div v-for="savedWallet of savedWallets" :key="savedWallet.address"
         class="py-2 border-bottom bg-hover cursor-pointer d-flex flex-row align-items-center justify-content-between"
         @click="pickWallet(savedWallet)">
      <div class="flex-grow-1 min-width-0">
        <div class="text-secondary overflow-hidden text-overflow-ellipsis">{{ savedWallet.label }}</div>
        <div class="text-monospace text-break">{{ savedWallet.address }}</div>
        <div v-if="savedWallet.memo">
          <span class="text-secondary">Memo: </span>
          <span class="text-monospace">{{ savedWallet.memo }}</span>
        </div>
      </div>
      <div class="flex-shrink-0">
        <b-button variant="link" size="sm" class="text-danger" @click="onClickDelete($event, savedWallet)">
          <b-icon-trash/>
        </b-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
  import _ from "lodash";
  import BaseComponent from "@/components/BaseComponent";
  import * as savedWalletsService from "@/services/savedWalletsService";

  @Component
  export default class AddressBookModal extends BaseComponent {

    @Prop() modalId!: string;
    @Prop() type?: string;

    savedWallets: savedWalletsService.SavedWallet[] = [];

    mounted() {
      this.savedWallets = savedWalletsService.getAllSavedWallets2(this.type);
    }

    onClickDelete(event: Event, savedWallet: savedWalletsService.SavedWallet) {
      console.log("AddressBookModal onClickDelete", event, savedWallet);
      event.stopPropagation();
      savedWalletsService.deleteSavedWallet(savedWallet.address, savedWallet.memo);

      this.savedWallets = savedWalletsService.getAllSavedWallets2(this.type);
    }

    @Emit()
    pickWallet(savedWallet: savedWalletsService.SavedWallet) {
      return savedWallet;
    }

  }
</script>
