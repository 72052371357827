<template>
  <main>
    <b-container>
      <b-row>
        <b-col lg="5">
          <label class="text-success">Buy / Long</label>
          <SetupHedgingSide ref="buyHedgingSide" side="buy"></SetupHedgingSide>
        </b-col>
        <b-col lg="2" class="d-flex flex-row align-items-center justify-content-center">
          <b-button variant="link" @click="onClickReverseSides" :disabled="reverseButtonDisabled" title="Reverse">
            <b-icon-arrow-left-right />
          </b-button>
        </b-col>
        <b-col lg="5">
          <label class="text-danger">Sell / Short</label>
          <SetupHedgingSide ref="sellHedgingSide" side="sell"></SetupHedgingSide>
        </b-col>
      </b-row>
      <div class="text-center">
        <b-button variant="primary" @click="onClickSaveSettings">OK</b-button>
      </div>
    </b-container>
  </main>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import {Component, Vue} from 'vue-property-decorator';
  import _ from "lodash";
  import BaseComponent from "@/components/BaseComponent";
  import * as utils from "@/utils";
  import SetupHedgingSide from "@/components/hedging/SetupHedgingSide.vue";

  const sampleSwapSide = {
    name: "",
    address: "",
    isNative: false,
    decimals: 0,
    symbol: "",
    logo: "",
    walletBalance: "0",
    walletDisplayBalance: "0",
    displayAmount: "",
    displayPriceUsd: "--",
    displayPriceOther: "--",
  };

  const sampleHedgeSide = {
    accountType: "",
    swap: {
      chainId: 0,
      walletAddress: "",
      input: utils.jsonClone(sampleSwapSide),
      output: utils.jsonClone(sampleSwapSide),
      isRequestingQuote: false,
      quoteErrorMsg: "",
      isSwapping: false,
      swapErrorMsg: "",
      swapTxId: "",
      aggregator: "",
      slippageTolerance: 1,
      solanaOptions: {
        priorityFeeMultiplier: 1,
        customPriorityFeeSOL: 0,
        jupiterQuoteResponse: null,
      },
      evmOptions: {

      }
    },
    cex: {
      cexAccountId: 0,
      accExchange: "",
      type: "spot",
      marketExchange: "",
      searchMarket: "",
      markets: [],
      symbol: "",
      baseAsset: "",
      quoteAsset: "",
      priceMultiplier: 1,
      priceStep: 0,
      amountStep: 0,
      ws: null,
      wsIsAlive: false,
      wsHeartbeatIntervalHandler: null,
      orderBook: [],
      orderBookTs: 0,
    }
  };

  @Component({
    components: {SetupHedgingSide}
  })
  export default class Hedging2 extends BaseComponent {

    get reverseButtonDisabled() {
      return false;
    }

    solanaAggregatorOptions = [
      { value: "jupiter", text: "Jupiter", disabled: false },
    ];
    slippageToleranceOptions = [0.1, 0.5, 1, 3];

    isDestroyed = false;

    mounted() {

    }

    onClickReverseSides() {
      const buySide = this.$refs.buyHedgingSide as SetupHedgingSide;
      const sellSide = this.$refs.sellHedgingSide as SetupHedgingSide;

      const buyReversedFormData = buySide.getReversedSideFormData();
      const sellReversedFormData = sellSide.getReversedSideFormData();
      sellSide.setFormData(buyReversedFormData);
      buySide.setFormData(sellReversedFormData);
    }

    onClickSaveSettings() {
      const buySide = this.$refs.buyHedgingSide as SetupHedgingSide;
      const sellSide = this.$refs.sellHedgingSide as SetupHedgingSide;

      if (buySide.isValid() && sellSide.isValid()) {
        buySide.saveSettings();
        sellSide.saveSettings();


      }
    }

  }
</script>
