import axios from "@/axios";
import * as authService from "@/services/authService";

export async function getSavedQueries() {
  return (await axios.get("/arbitrage/savedQueries", {
    headers: await authService.getAuthHeaders()
  })).data.data;
}

export async function addSavedQuery(data) {
  return (await axios.post("/arbitrage/addSavedQuery", data, {
    headers: await authService.getAuthHeaders()
  })).data.data;
}

export async function deleteSavedQuery(id: number) {
  return (await axios.post("/arbitrage/deleteSavedQuery", { id }, {
    headers: await authService.getAuthHeaders()
  })).data.data;
}

export async function findArbitrage(filter) {
  return (await axios.post("/arbitrage/findArbitrage3", filter, {
    headers: await authService.getAuthHeaders()
  })).data;
}

export async function getAssets(filter) {
  return (await axios.post("/arbitrage/getAssets3", filter, {
    headers: await authService.getAuthHeaders()
  })).data;
}
