<template>
  <div>
    <div class="text-center">
      <b-button variant="link" class="text-decoration-none" v-b-modal:new-saved-query-modal>
        <b-icon-plus class="mr-1"/> Save new
      </b-button>
    </div>
    <div v-if="isLoading" class="text-center text-secondary py-3">
      Loading saved presets...
    </div>
    <template v-else>
      <div v-for="savedQuery in savedQueries" :key="savedQuery.id" class="py-2 border-bottom bg-hover d-flex flex-row align-items-center justify-content-between">
        <div class="min-width-0 text-break mr-3">
          <div>{{ savedQuery.label }}</div>
          <div class="text-secondary">{{ savedQuery.displayUpdatedAt }}</div>
        </div>
        <div class="flex-shrink-0 text-secondary">
          <b-button variant="link" size="sm" @click="onClickLoad(savedQuery)">Load</b-button>
          <b-button variant="link" size="sm" @click="onClickOverwrite(savedQuery)">Save</b-button>
<!--          <b-button variant="link" size="sm" @click="onClickEditAlerts(savedQuery)">Alerts</b-button>-->
          <b-button variant="link" class="text-danger" size="sm" @click="onClickDelete(savedQuery)">Delete</b-button>
        </div>
      </div>
    </template>
    <div v-if="errorMsg" class="text-danger">{{ errorMsg }}</div>

    <b-modal id="new-saved-query-modal" title="New saved query" hide-footer no-fade no-close-on-backdrop>
      <NewSavedQueryModal modal-id="new-saved-query-modal" :arbitrageSettings="arbitrageSettings" @done="fetchSavedQueries"></NewSavedQueryModal>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
  import _ from "lodash";
  import BaseComponent from "@/components/BaseComponent";
  import * as arbitrageService from "@/services/arbitrageService";
  import {DateTime} from "luxon";
  import NewSavedQueryModal from "@/components/arbitrage/NewSavedQueryModal.vue";
  import * as utils from "@/utils";

  @Component({
    components: {NewSavedQueryModal}
  })
  export default class SavedQueriesModal extends BaseComponent {

    @Prop({ required: true }) modalId!: string;
    @Prop({ required: true }) arbitrageSettings;

    isLoading = true;
    savedQueries = [];
    errorMsg = "";

    mounted() {
      this.fetchSavedQueries();
    }

    async fetchSavedQueries() {
      try {
        this.isLoading = true;
        this.savedQueries = await arbitrageService.getSavedQueries();
        for (const savedQuery of this.savedQueries) {
          savedQuery.displayCreatedAt = DateTime.fromISO(savedQuery.createdAt).toFormat("yyyy-MM-dd HH:mm:ss");
          savedQuery.displayUpdatedAt = DateTime.fromISO(savedQuery.updatedAt).toFormat("yyyy-MM-dd HH:mm:ss");
        }

      } catch (e) {

      } finally {
        this.isLoading = false;
      }
    }

    @Emit("onClickLoad")
    onClickLoad(savedQuery) {
      this.$bvModal.hide(this.modalId);
      return savedQuery;
    }

    async onClickOverwrite(savedQuery) {
      const ok = await this.$bvModal.msgBoxConfirm(`Overwrite ${savedQuery.label} with your current settings?`, {
        okTitle: "Overwrite"
      });

      if (ok) {
        const data = utils.jsonClone(this.arbitrageSettings);
        data.id = savedQuery.id;
        data.label = savedQuery.label;
        await arbitrageService.addSavedQuery(data);
        this.fetchSavedQueries();
      }
    }

    onClickEditAlerts(savedQuery) {

    }

    async onClickDelete(savedQuery) {
      const ok = await this.$bvModal.msgBoxConfirm("Delete this saved query?", {
        okVariant: "danger",
        okTitle: "Delete"
      });

      if (ok) {
        await arbitrageService.deleteSavedQuery(savedQuery.id);
        this.fetchSavedQueries();
      }
    }

  }
</script>
