<template>
  <main>
    <b-container class="mx-auto px-3 py-5">
      <div class="d-flex flex-row align-items-center justify-content-between mb-4">
        <h4 class="m-0">Private key wallets</h4>
        <div>
          <b-button variant="link" class="mr-3 text-decoration-none text-secondary" v-b-modal:arrange-wallets-modal>
            Move accounts
          </b-button>
          <b-button variant="outline-primary" v-b-modal:import-wallet-modal>
            <b-icon-plus/> Import wallet
          </b-button>
        </div>
      </div>
      <b-table :fields="tableFields" :items="privateKeyWallets" :show-empty="true">
        <template #empty>
          <div class="text-center py-3">
            <span class="text-secondary">No wallets</span>
          </div>
        </template>
        <template #cell(privateKey)="{ item }">
          <b-button variant="link" class="p-0 mr-3" size="sm" @click="onClickEditLabel(item)" v-b-tooltip title="Edit label"><b-icon-pencil /></b-button>
          <b-button variant="link" class="p-0 mr-3" @click="onClickShowPrivateKey(item)" v-b-tooltip title="Show private key"><b-icon-key /></b-button>
          <b-button variant="link" class="p-0 text-danger" size="sm" @click="onClickDelete(item)" v-b-tooltip title="Delete"><b-icon-trash/></b-button>
        </template>
      </b-table>
    </b-container>
    <b-modal id="arrange-wallets-modal" title="Arrange wallets" hide-footer no-fade no-close-on-backdrop>
      <ArrangePrivateKeyWalletsModal modalId="arrange-wallets-modal" @done="loadWallets" />
    </b-modal>
    <b-modal id="import-wallet-modal" title="Import wallet" size="lg" hide-footer no-fade no-close-on-backdrop>
      <ImportPrivateKeyWalletModal modalId="import-wallet-modal" @done="loadWallets" />
    </b-modal>
    <b-modal id="edit-wallet-label-modal" title="Edit label" hide-footer no-fade no-close-on-backdrop>
      <EditPrivateKeyWalletLabelModal modalId="edit-wallet-label-modal" :wallet="editWallet" @done="loadWallets" />
    </b-modal>
  </main>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import {Component, Vue} from 'vue-property-decorator';
  import _ from "lodash";
  import BaseComponent from "@/components/BaseComponent";
  import ImportPrivateKeyWalletModal from "@/components/private-key-wallets/ImportPrivateKeyWalletModal.vue";
  import * as privateKeyWalletService from "@/services/privateKeyWalletService";
  import {BvTableFieldArray} from "bootstrap-vue";
  import EditPrivateKeyWalletLabelModal from "@/components/private-key-wallets/EditPrivateKeyWalletLabelModal.vue";
  import ArrangePrivateKeyWalletsModal from "@/components/private-key-wallets/ArrangePrivateKeyWalletsModal.vue";

  @Component({
    components: {ArrangePrivateKeyWalletsModal, EditPrivateKeyWalletLabelModal, ImportPrivateKeyWalletModal}
  })
  export default class PrivateKeyWallets extends BaseComponent {

    privateKeyWallets = [];
    editWallet = null;

    tableFields: BvTableFieldArray = [
      { key: "label" },
      { key: "type", label: "Type", formatter: value => ({ evm: "EVM", solana: "Solana" }[value]) },
      { key: "address", tdClass: "text-break text-monospace" },
      { key: "privateKey", label: "" },
    ];

    mounted() {
      this.loadWallets();
    }

    loadWallets() {
      this.privateKeyWallets = privateKeyWalletService.getWallets();
    }

    onClickShowPrivateKey(item) {
      this.$bvModal.msgBoxOk(item.privateKey, {
        noFade: true,
        bodyClass: "text-monospace text-break"
      });
    }

    onClickEditLabel(item) {
      this.editWallet = item;
      this.$bvModal.show("edit-wallet-label-modal");
    }

    async onClickDelete(item) {
      const ok = await this.$bvModal.msgBoxConfirm("Delete this wallet?", {
        okTitle: "Delete",
        okVariant: "danger"
      });

      if (ok) {
        privateKeyWalletService.deleteWallet(item.type, item.address);
        this.loadWallets();
      }
    }

  }
</script>
