<template>
  <b-form @keydown.enter.prevent @submit.prevent="submitForm">
    <b-form-group label="Label">
      <b-form-input type="text" v-model="label" minlength="0" maxlength="200"></b-form-input>
    </b-form-group>
    <div class="text-right">
      <b-button type="submit" variant="primary" @click="beforeSubmit">Save</b-button>
    </div>
  </b-form>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
  import _ from "lodash";
  import BaseComponent from "@/components/BaseComponent";
  import * as privateKeyWalletService from "@/services/privateKeyWalletService";

  @Component
  export default class EditPrivateKeyWalletLabelModal extends BaseComponent {

    @Prop({ required: true }) modalId: string;
    @Prop({ required: true }) wallet;

    label = "";

    mounted() {
      this.label = this.wallet.label;
    }

    beforeSubmit() {
      this.label = this.label.trim();
    }

    submitForm() {
      privateKeyWalletService.editWalletLabel(this.wallet.type, this.wallet.address, this.label);
      this.$bvModal.hide(this.modalId);
      this.done();
    }

    @Emit()
    done() {

    }

  }
</script>
