import axios from "axios";
import _ from "lodash";
import BigNumber from "bignumber.js";
import * as constants from "@/constants";
import * as web3Service from "@/services/web3Service";
import {ethers} from "ethers";

import erc20Abi from "./abi/erc20Abi.json";
import multiCall3Abi from "./abi/multiCall3Abi.json";

const erc20Interface = new ethers.Interface(erc20Abi);
const multiCall3Interface = new ethers.Interface(multiCall3Abi);

const axiosInstance = axios.create({
  baseURL: "https://rot.endjgfsv.link",
  timeout: 5000,
});

export interface SwapRouterParams {
  fromToken: string;
  fromTokenDecimals: number;
  toToken: string;
  toTokenDecimals: number;
  amountIn: string;
  slippage: number;
  receiver: string;
}

export interface SwapRouterResponse extends SwapRouterParams {
  amountOut: string;
  minAmountOut: string;
  impact: string;
  tokens: string[];
  poolFees: string[];
  poolVersions: string[];
  smartRouterParams: any;
}

export async function checkBalanceAndAllowance(walletAddress: string, fromTokenAddress: string, amount: string) {
  const amountBigInt = BigInt(amount);

  if (fromTokenAddress === constants.ZERO_ADDRESS_TRON) {
    const balance = await web3Service.getTronWeb().trx.getBalance(walletAddress);
    if (balance < amountBigInt) {
      throw new Error(`Not enough TRX balance: have ${balance}, need ${amount}`);
    }
    return { balance, allowance: ethers.MaxUint256, amount };

  } else {
    const multiCall3Address = constants.MULTICALL3_CONTRACT[constants.CHAIN_ID_TRON];
    const multiCall3Contract = web3Service.getTronWeb().contract(multiCall3Abi, multiCall3Address);

    const fromTokenAddressHex = web3Service.tronAddressToHex(fromTokenAddress);
    const walletAddressHex = web3Service.tronAddressToHex(walletAddress);
    const routerAddressHex = web3Service.tronAddressToHex(constants.SUNSWAP_ROUTER);

    const calls = [
      [ fromTokenAddressHex, erc20Interface.encodeFunctionData("balanceOf", [walletAddressHex]) ],
      [ fromTokenAddressHex, erc20Interface.encodeFunctionData("allowance", [walletAddressHex, routerAddressHex]) ],
    ];
    const multiCallRet = (await multiCall3Contract.aggregate(calls).call())[1];

    const balance: bigint = erc20Interface.decodeFunctionResult("balanceOf", multiCallRet[0])[0];
    if (balance < amountBigInt) {
      throw new Error(`Not enough balance: token ${fromTokenAddress}, have ${balance}, need ${amount}`);
    }

    const allowance: bigint = erc20Interface.decodeFunctionResult("allowance", multiCallRet[1])[0];
    if (allowance < amountBigInt) {
      throw new Error(`Not enough allowance: token ${fromTokenAddress}, spender ${constants.SUNSWAP_ROUTER}, allowance ${allowance}, need ${amount}`);
    }

    return { balance, allowance, amount };
  }
}

export async function getSwapRouter(params: SwapRouterParams): Promise<SwapRouterResponse> {
  const queryParams = {
    fromToken: params.fromToken,
    toToken: params.toToken,
    amountIn: params.amountIn,
    typeList: "PSM,CURVE,CURVE_COMBINATION,WTRX,SUNSWAP_V1,SUNSWAP_V2,SUNSWAP_V3"
  };

  const router = (await axiosInstance.get("/swap/router", { params: queryParams })).data?.data?.[0];
  if (router) {
    const amountOutBN = BigNumber(router.amountOut).shiftedBy(params.toTokenDecimals);
    const minAmountOutBN = amountOutBN.multipliedBy(1 - params.slippage / 100).decimalPlaces(0);

    const smartRouterPoolVersionsParam = _.uniq<string>(router.poolVersions);

    const countDict = _.countBy(router.poolVersions);
    const smartRouterVersionLenParam = smartRouterPoolVersionsParam.map(v => countDict[v]);
    smartRouterVersionLenParam[0]++;

    const minAmountOut = minAmountOutBN.toFixed();
    const deadlineTs = Math.floor(Date.now() / 1000) + 60;

    const smartRouterParams = [
      router.tokens,
      smartRouterPoolVersionsParam,
      smartRouterVersionLenParam,
      router.poolFees.map(fee => +fee),
      [params.amountIn, minAmountOut, params.receiver, deadlineTs]
    ];

    const amountOut = amountOutBN.toFixed();
    return {
      ...params,
      amountOut,
      minAmountOut,
      impact: router.impact,
      tokens: router.tokens,
      poolFees: router.poolFees,
      poolVersions: router.poolVersions,
      smartRouterParams
    };
  }
}
