import axios from "@/axios";
import * as authService from "@/services/authService";
import * as constants from "@/constants";
import {PublicKey, SystemProgram, VersionedTransaction} from "@solana/web3.js";
import _ from "lodash";

export async function sendBundle(txs: VersionedTransaction[]) {
  const encodedTxs = txs.map(tx => Buffer.from(tx.serialize()).toString("base64"));
  return (await axios.post("/jito-bundle/send", encodedTxs)).data;
}

export function tipInstruction(fromPubkey: PublicKey, lamports: number | bigint) {
  const tipPk = new PublicKey(_.sample([
    'Cw8CFyM9FkoMi7K7Crf6HNQqf4uEMzpKw6QNghXLvLkY',
    'ADuUkR4vqLUMWXxW9gh6D6L8pMSawimctcNZ5pGwDcEt',
    '96gYZGLnJYVFmbjzopPSU6QiEV5fGqZNyN9nmNhvrZU5',
    'DfXygSm4jCyNCybVYYK6DwvWqjKee8pbDmJGcLWNDXjh',
    'HFqU5x63VTqvQss8hp11i4wVV8bD44PvwucfZ2bU7gRe',
    'ADaUMid9yfUytqMBgopwjb2DTLSokTSzL1zt6iGPaS49',
    'DttWaMuVvTiduZRnguLF7jNxTgiMBZ1hyAumKUiL2KRL',
    '3AVi9Tg9Uo68tJfuvoKvqKNWKkC5wPdSSdeBnizKZ6jT'
  ]));
  return SystemProgram.transfer({ fromPubkey: fromPubkey, toPubkey: tipPk, lamports });
}
