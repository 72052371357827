import _ from "lodash";
import {ethers} from "ethers";
import * as web3Service from "@/services/web3Service";
import * as constants from "@/constants";
import { DateTime } from "luxon";

export async function refreshAuth() {
  const ts = Date.now();
  const message = constants.AUTH_HEADER_PREFIX + ":" + ts;
  const signer = await web3Service.getBrowserProvider().getSigner();
  const address = await signer.getAddress();
  const signature = await signer.signMessage(message);

  const auth = { ts, message, signature };
  localStorage.setItem("auth-" + address, JSON.stringify(auth));
  return auth;
}


export async function refreshAuthIfNecessary() {
  try {
    const currentWalletAddress = await web3Service.getConnectedEvmAddress();
    const auth = JSON.parse(localStorage.getItem("auth-" + currentWalletAddress));
    const { ts, message, signature } = auth;
    const tsDiffToNowHours = DateTime.now().diff(DateTime.fromMillis(ts)).as("hours");
    if (_.inRange(tsDiffToNowHours, -1, 84)) {
      const walletAddress = ethers.verifyMessage(message, signature);
      if (currentWalletAddress === walletAddress) {
        return auth;
      }
    }

  } catch (e) {

  }

  return refreshAuth();
}

export async function getAuthHeaders() {
  const auth = await refreshAuthIfNecessary();

  return {
    "x-evm-auth-msg": auth.message,
    "x-evm-auth-signature": auth.signature,
  };
}


