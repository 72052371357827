import axios from "axios";
import _ from "lodash";

const axiosInstance = axios.create({
  baseURL: "https://apiv5.paraswap.io",
  timeout: 5000,
});

export async function getQuote(params: any) {
  return (await axiosInstance.get("/prices", { params })).data.priceRoute;
}

export async function buildTransaction(allParams: any) {
  const queryParamKeys = ["gasPrice", "ignoreChecks", "ignoreGasEstimate", "onlyParams"];
  const params = _.pick(allParams, queryParamKeys);
  const bodyKeys = ["srcToken", "srcDecimals", "destToken", "destDecimals", "srcAmount", "destAmount", "slippage", "userAddress", "txOrigin", "receiver", "partnerAddress", "partnerFeePercent", "partner", "permit", "deadline", "priceRoute"];
  const body = _.pick(allParams, bodyKeys);
  return (await axiosInstance.post(`/transactions/${allParams.network}`, body, { params })).data;
}

