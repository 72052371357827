<template>
  <div class="d-flex flex-row align-items-center flex-wrap">
    <div v-for="item in selectedItems" :key="item.coingeckoId"
         style="line-height: 1;" class="flex-shrink-0 d-flex flex-row align-items-center border rounded px-2 py-1 mr-2">
      <img :src="item.logoSmallUrl" :alt="item.name" style="width: 25px; height: 25px;">
      <div class="mx-2">
        <div><small>{{ item.symbol }}</small></div>
        <div><small class="text-secondary">{{ item.name }}</small></div>
      </div>
      <div class="cursor-pointer">
        <b-icon-x-circle></b-icon-x-circle>
      </div>
    </div>
    <div style="width: 280px;">
      <v-select class="vs-normalizer" label="name"
                :clearable="false"
                :filterable="false"
                :options="typeaheadOptions"
                :clearSearchOnBlur="() => false"
                @search="doSearchDebounced"
                v-on:option:selecting="onTypeaheadSelecting">
        <template v-slot:search="{attributes, events}">
          <input v-bind="attributes" v-on="events" placeholder="search for coins"
                 class="form-control" required minlength="2" maxlength="100"/>
        </template>
        <template #option="coin">
          <img style="width: 25px; height: 25px;" class="mr-2" :src="coin.thumb" :alt="coin.name">
          <span>{{ coin.name }} ({{ coin.symbol }})</span>
        </template>
      </v-select>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import {Component, Vue} from 'vue-property-decorator';
  import _ from "lodash";
  import BaseComponent from "@/components/BaseComponent";
  import axios from "@/axios";

  @Component
  export default class TestVueSelect extends BaseComponent {

    selectedItems = [];
    typeaheadOptions = [];

    mounted() {

    }

    async doSearch(query: string, loading: (p: boolean) => void) {
      try {
        if (query.length < 2) {
          this.typeaheadOptions = [];
        } else {
          loading(true);

          // Fetch and assign typeahead options
          this.typeaheadOptions = (await axios.get("https://api.coingecko.com/api/v3/search", {
            params: { query }
          })).data.coins;
        }

      } catch (e) {
        console.error(e);

      } finally {
        loading(false);
      }
    }

    doSearchDebounced = _.debounce(this.doSearch, 300);

    onTypeaheadSelecting(option) {
      this.selectedItems.push({
        coingeckoId: option.id,
        name: option.name,
        symbol: option.symbol,
        logoUrl: option.large,
        logoSmallUrl: option.thumb
      });
    }

  }
</script>
