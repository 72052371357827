import axios from "@/axios";
import * as authService from "@/services/authService";
import * as constants from "@/constants";
import * as utils from "@/utils";

export async function placeAll(body) {
  return (await axios.post(`/delistTradeWatchOrder/placeAll`, body, {
    headers: await authService.getAuthHeaders()
  })).data.data;
}

export async function placeOrder(body) {
  return (await axios.post(`/delistTradeWatchOrder/placeOrder`, body, {
    headers: await authService.getAuthHeaders()
  })).data.data;
}

export async function getAllByCoins(delistExchange: string) {
  return (await axios.get(`/delistTradeWatchOrder/getAllByCoins`, {
    headers: await authService.getAuthHeaders(),
    params: { delistExchange }
  })).data.data;
}

export async function cancelAll() {
  return (await axios.post(`/delistTradeWatchOrder/cancelAll`, undefined, {
    headers: await authService.getAuthHeaders()
  })).data.data;
}

export async function cancel(id: number) {
  return (await axios.post(`/delistTradeWatchOrder/cancel`, { id }, {
    headers: await authService.getAuthHeaders()
  })).data.data;
}


export async function getAccountSettings() {
  return (await axios.get(`/delistTradeWatchOrder/getAccountSettings`, {
    headers: await authService.getAuthHeaders(),
  })).data.data;
}

export async function updateAccountSettings(body) {
  return (await axios.post(`/delistTradeWatchOrder/updateAccountSettings`, body, {
    headers: await authService.getAuthHeaders()
  })).data.data;
}
