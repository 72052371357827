import { render, staticRenderFns } from "./FeedSettingsModal.vue?vue&type=template&id=3e8852be&scoped=true"
import script from "./FeedSettingsModal.vue?vue&type=script&lang=ts"
export * from "./FeedSettingsModal.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e8852be",
  null
  
)

export default component.exports