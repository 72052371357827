<template>
  <main role="main">
    <b-container class="py-5">
      <h4 class="text-center">CEX asset balances</h4>
      <div class="text-center mb-4">
        <b-form class="d-flex flex-row align-items-center justify-content-center" @submit.prevent="fetchBalances" inline>
          <b-form-select :options="cexAccountOptions" v-model="cexAccountId" :disabled="isLoading" @change="fetchBalances"
                         class="mr-3" style="width: 300px;" />
          <b-form-checkbox v-model="showAllBalances" class="mr-3" :disabled="isLoading" @change="fetchBalances">Show all balances</b-form-checkbox>
          <b-button type="submit" variant="link" :disabled="isLoading" style="width: 140px;">
            {{ isLoading ? "loading..." : "load balances" }}
            <b-spinner small v-if="isLoading"></b-spinner>
          </b-button>
        </b-form>
      </div>
      <b-table :items="balances" :fields="tableFields" thead-class="text-nowrap" hover>
        <template v-slot:table-busy>
          <div class="text-center py-4">Loading...</div>
        </template>
        <template v-slot:cell(actions)="{ item }">
          <span class="btn-link cursor-pointer mr-4" @click="onClickDeposit(item)">Deposit</span>
          <span class="btn-link cursor-pointer" @click="onClickWithdraw(item)">Withdraw</span>
        </template>
      </b-table>
    </b-container>

    <b-modal id="arbitrage-deposit-cex-asset-modal" :title="depositModalTitle" hide-footer no-fade>
      <ArbitrageDepositCexAssetModal
        v-if="selectedCexAccount && depositItem"
        modal-id="deposit-cex-asset-modal"
        :p_cexAccountId="cexAccountId"
        :exchange="selectedCexAccount.exchange"
        :asset="depositItem.asset" />
    </b-modal>

    <b-modal id="withdraw-cex-asset-modal" :title="withdrawModalTitle" hide-footer no-fade no-close-on-backdrop>
      <WithdrawCexAssetModal
        v-if="selectedCexAccount && withdrawItem"
        modal-id="withdraw-cex-asset-modal"
        :cex-account-id="cexAccountId"
        :cex-account-evm-address="selectedCexAccount && selectedCexAccount.evmAddress"
        :exchange="selectedCexAccount && selectedCexAccount.exchange"
        :asset="withdrawItem.asset"
        :available="withdrawItem.available"
        @done="fetchBalances" />
    </b-modal>
  </main>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import {Component, Vue} from 'vue-property-decorator';
  import _ from "lodash";
  import BigNumber from "bignumber.js";
  import {DateTime} from "luxon";
  import {BvTableFieldArray} from "bootstrap-vue";

  import BaseComponent from "@/components/BaseComponent";
  import * as cexAccountService from "@/services/cexAccountService";
  import * as utils from "@/utils";
  import WithdrawCexAssetModal from "@/components/WithdrawCexAssetModal.vue";
  import ArbitrageDepositCexAssetModal from '@/components/arbitrage/ArbitrageDepositCexAssetModal.vue';

  @Component({
    components: {ArbitrageDepositCexAssetModal, WithdrawCexAssetModal}
  })
  export default class CexWallet extends BaseComponent {

    cexAccounts = [];
    cexAccountOptions = [
      { value: null, text: "Loading accounts...", disabled: true }
    ];
    cexAccountId: number = null;
    get selectedCexAccount() {
      return this.cexAccounts.find(it => it.id === this.cexAccountId);
    }

    showAllBalances = false;

    isLoading = true;
    tableFields: BvTableFieldArray = [
      {
        key: "asset",
        label: "Asset",
        thClass: "text-nowrap",
        tdClass: "text-nowrap",
        sortable: true,
        formatter: value => value.toUpperCase()
      },
      {
        key: "balance",
        label: "Balance",
        thClass: "text-nowrap",
        tdClass: "text-nowrap",
        sortable: true,
        formatter: value => BigNumber(value).toFixed()
      },
      {
        key: "available",
        label: "Available",
        thClass: "text-nowrap",
        tdClass: "text-nowrap",
        sortable: true,
        formatter: value => BigNumber(value).toFixed()
      },
      {
        key: "usdValue",
        label: "USD value",
        thClass: "text-nowrap",
        tdClass: "text-nowrap",
        sortable: true,
        formatter: utils.formatUsdValue
      },
      {
        key: "actions",
        label: "",
        thClass: "text-nowrap",
        tdClass: "text-nowrap",
      },
    ];
    balances = [];

    depositItem = null;
    get depositModalTitle() {
      if (this.depositItem) {
        return "Deposit " + this.depositItem.asset.toUpperCase();
      }
    }

    withdrawItem = null;
    get withdrawModalTitle() {
      if (this.withdrawItem) {
        return "Withdraw " + this.withdrawItem.asset.toUpperCase();
      }
    }

    mounted() {
      document.title = "CEX wallet";
      this.setup();
    }

    loadSettings() {
      try {
        const settings = JSON.parse(localStorage.getItem("cexWallet"));
        this.cexAccountId = settings.cexAccountId;
        this.showAllBalances = !!settings.showAllBalances;

      } catch (e) {

      }
    }

    async setup() {
      this.loadSettings();

      this.cexAccounts = await cexAccountService.getAll();
      this.cexAccountOptions = this.cexAccounts.map(it => ({
        value: it.id, text: it.label || utils.getShortenedAddress(it.evmAddress), disabled: false
      }));

      if (!this.cexAccountOptions.length) {
        this.cexAccountOptions = [
          { value: null, text: "No accounts", disabled: true }
        ];
      } else {
        if (!this.cexAccountId || !this.cexAccountOptions.find(it => it.value === this.cexAccountId)) {
          this.cexAccountId = this.cexAccountOptions[0].value;
        }
        await this.fetchBalances();
      }

    }

    async fetchBalances() {
      try {
        this.isLoading = true;
        this.balances = await cexAccountService.getBalances(this.cexAccountId, null, this.showAllBalances);
        this.saveSettings();

      } catch (e) {
        console.error(e);
        this.toastError(e);

      } finally {
        this.isLoading = false;
      }
    }

    saveSettings() {
      localStorage.setItem("cexWallet", JSON.stringify({
        cexAccountId: this.cexAccountId,
        showAllBalances: !!this.showAllBalances
      }));
    }

    onClickDeposit(item) {
      this.depositItem = item;
      this.$bvModal.show("arbitrage-deposit-cex-asset-modal");
    }

    onClickWithdraw(item) {
      this.withdrawItem = item;
      this.$bvModal.show("withdraw-cex-asset-modal");
    }

  }
</script>
