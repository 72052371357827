import _ from "lodash";
import {ethers} from "ethers";
import * as savedWalletsService from "@/services/savedWalletsService";
import * as utils from "@/utils";

export interface CustomToken {
  [key: string]: any;
  address: string;
  decimals: number;
  name: string;
  symbol: string;
}


function getCustomTokensListStorageKey(chainId: number) {
  return "customTokens-" + chainId;
}

export function getAllCustomTokens(chainId: number): CustomToken[] {
  try {
    const data = JSON.parse(localStorage.getItem(getCustomTokensListStorageKey(chainId)));
    return Array.isArray(data)? data : [];

  } catch (e) {
    return [];
  }
}

export function addCustomToken(chainId: number, token: CustomToken) {
  if (utils.isEvmChain(chainId)) {
    token.address = ethers.getAddress(token.address);
  }

  const tokens = getAllCustomTokens(chainId);

  const index = tokens.findIndex(t => t.address.toLowerCase() === token.address.toLowerCase());
  if (index >= 0) {
    tokens.splice(index, 1, token);
  } else {
    tokens.push(token);
  }

  const sortedTokens = _.orderBy(tokens, ["symbol"])
    .map(token => _.pick(token, ["address", "decimals", "name", "symbol", "logo"])
  );
  localStorage.setItem(getCustomTokensListStorageKey(chainId), JSON.stringify(sortedTokens));
}

export function deleteCustomToken(chainId: number, address: string) {
  const tokens = getAllCustomTokens(chainId);
  const filteredTokens = tokens.filter(t => t.address.toLowerCase() !== address.toLowerCase());
  localStorage.setItem(getCustomTokensListStorageKey(chainId), JSON.stringify(filteredTokens));
}

// ======================================================================================================

function getPinnedTokensListStorageKey(chainId: number) {
  return "pinnedTokens-" + chainId;
}

export function getAllPinnedTokens(chainId: number): CustomToken[] {
  try {
    const data = JSON.parse(localStorage.getItem(getPinnedTokensListStorageKey(chainId)));
    return Array.isArray(data)? data : [];

  } catch (e) {
    return [];
  }
}

export function addPinnedToken(chainId: number, token: CustomToken) {
  if (utils.isEvmChain(chainId)) {
    token.address = ethers.getAddress(token.address);
  }

  const tokens = getAllPinnedTokens(chainId);

  const index = tokens.findIndex(t => t.address.toLowerCase() === token.address.toLowerCase());
  if (index >= 0) {
    tokens.splice(index, 1, token);
  } else {
    tokens.push(token);
  }

  const savePinnedTokens = tokens.map(token => _.pick(token, ["address", "decimals", "name", "symbol", "logo"]));
  localStorage.setItem(getPinnedTokensListStorageKey(chainId), JSON.stringify(savePinnedTokens));
}

export function deletePinnedToken(chainId: number, address: string) {
  const tokens = getAllPinnedTokens(chainId);
  const filteredTokens = tokens.filter(t => t.address.toLowerCase() !== address.toLowerCase());
  localStorage.setItem(getPinnedTokensListStorageKey(chainId), JSON.stringify(filteredTokens));
}


// ======================================================================================================

function getSwapDestReceiverByTokenStorageKey(chainId: number) {
  return "swapDestReceiverByToken-" + chainId;
}

export function getSwapDestReceiverByToken(chainId: number, tokenAddress: string) {
  try {
    const storageKey = getSwapDestReceiverByTokenStorageKey(chainId);
    const isEvmChain = utils.isEvmChain(chainId);
    const destReceiver = JSON.parse(localStorage.getItem(storageKey))[isEvmChain ? tokenAddress.toLowerCase() : tokenAddress];
    const destReceiverLabel = destReceiver ?
      savedWalletsService.getAllSavedWallets2().find(it =>
        isEvmChain ?
          it.address.toLowerCase() === destReceiver.toLowerCase() :
          it.address === destReceiver
      )?.label :
      undefined;

    return { destReceiver, destReceiverLabel };

  } catch (e) {

  }
}

export function setSwapDestReceiverByToken(chainId: number, tokenAddress: string, destReceiver?: string | void) {
  let obj = {};
  const storageKey = getSwapDestReceiverByTokenStorageKey(chainId);

  try {
    obj = JSON.parse(localStorage.getItem(storageKey)) || {};
  } catch (e) {}

  if (utils.isEvmChain(chainId)) {
    obj[ethers.getAddress(tokenAddress).toLowerCase()] = destReceiver ? ethers.getAddress(destReceiver) : undefined;
  } else {
    obj[tokenAddress] = destReceiver || undefined;
  }
  localStorage.setItem(storageKey, JSON.stringify(obj));
}
