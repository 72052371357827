import axios from "@/axios";
import * as authService from "@/services/authService";
import * as constants from "@/constants";

export async function getAll() {
  return (await axios.get("/cexBalanceWatchOrder/all", {
    headers: await authService.getAuthHeaders()
  })).data.data;
}

export async function create(data) {
  return (await axios.post("/cexBalanceWatchOrder/create", data, {
    headers: await authService.getAuthHeaders()
  })).data.data;
}

export async function cancel(id) {
  return (await axios.post("/cexBalanceWatchOrder/cancel", { id }, {
    headers: await authService.getAuthHeaders()
  })).data.data;
}
