<template>
  <main role="main">
    <b-container>
      <div class="text-right">
        <b-dropdown text="Switch network" class="m-md-2" variant="link">
          <template #button-content>
            <img :alt="currentChainInfo.name" style="width: 20px; height: 20px;" :src="currentChainInfo.logo" class="mr-2">
            <span class="text-decoration-none">{{ currentChainInfo.name }}</span>
          </template>
          <b-dropdown-item-button v-for="switchChain in switchChains" :key="switchChain.chainId" @click="onClickSwitchChain(switchChain)">
            <img :alt="switchChain.name" style="width: 20px; height: 20px;" :src="switchChain.logo" class="mr-2">
            <span>{{ switchChain.name }}</span>
          </b-dropdown-item-button>
        </b-dropdown>
      </div>
      <b-row>
        <section class="px-3 col-lg-6 mb-5">
          <div class="border rounded mb-3 p-2">
            <div>
              <div class="label-row">
                <div>You sell</div>
                <div class="cursor-pointer text-secondary" @click="onClickAllIn">
                  <small>Balance: {{ swapInput.walletDisplayBalance }}</small>
                </div>
              </div>
              <div class="d-flex flex-row align-items-center justify-content-between">
                <div @click="onClickInputAsset" class="asset-btn">
                  <div class="flex-shrink-0 mr-2" style="width: 30px; height: 30px;">
                    <img v-if="swapInput.logo" :src="swapInput.logo" class="w-100 h-100">
                  </div>
                  <div class="overflow-hidden text-overflow-ellipsis">{{ swapInput.symbol }}</div>
                </div>
                <b-input type="text" v-model.trim="swapInput.displayAmount" @input="inputAmountChangedDebounced"
                         class="border-0 text-right flex-grow-1" size="lg"
                         autocomplete="off" inputmode="decimal"></b-input>
              </div>
              <div class="label-row small text-secondary">
                <div>
                  <span class="mr-2">{{ swapInput.name || swapInput.symbol }}</span>
                  <template v-if="!swapInput.isNative">
                    <b-icon-info-circle id="icon-info-input"></b-icon-info-circle>
                    <b-tooltip target="icon-info-input">
                      <a class="small text-light" :href="swapInput.contract | explorerUrl(chainId, 'token')" target="_blank">
                        {{ swapInput.contract }}
                      </a>
                    </b-tooltip>
                  </template>
                </div>
              </div>
              <div class="label-row small text-secondary">
                <div>
                  <span class="mr-2 cursor-pointer"
                        @click="onClickSetWebTitlePriceType('input')"
                        :class="{ 'font-weight-500': webTitlePriceType === 'input' }">
                    1 {{ swapInput.symbol }} = {{ swapInput.displayPriceOther }} {{ swapOutput.symbol }}
                  </span>
                  <b-icon-info-circle id="input-unit-price-info"></b-icon-info-circle>
                  <b-tooltip target="input-unit-price-info">Quoted unit price when swapping with the specified volume</b-tooltip>
                </div>
              </div>
            </div>
            <div class="text-center">
              <span class="text-primary cursor-pointer" @click="reverseInputOutput" title="Reverse">
                <b-icon-arrow-down-up/>
              </span>
            </div>
            <div>
              <div class="label-row">
                <div>You buy</div>
                <div class="text-secondary"><small>Balance: {{ swapOutput.walletDisplayBalance }}</small></div>
              </div>
              <div class="d-flex flex-row align-items-center justify-content-between">
                <div @click="onClickOutputAsset" class="asset-btn">
                  <div class="flex-shrink-0 mr-2" style="width: 30px; height: 30px;">
                    <img v-if="swapOutput.logo" :src="swapOutput.logo" class="w-100 h-100">
                  </div>
                  <div class="overflow-hidden text-overflow-ellipsis">{{ swapOutput.symbol }}</div>
                </div>
                <div class="flex-grow-1 px-3 py-2 text-right overflow-hidden" style="font-size: 20px;"
                     :class="{'text-secondary': isRequestingQuote}">
                  {{ swapOutput.displayAmount }}
                </div>
              </div>
              <div class="label-row small text-secondary">
                <div>
                  <span class="mr-2">{{ swapOutput.name || swapOutput.symbol }}</span>
                  <template v-if="!swapOutput.isNative">
                    <b-icon-info-circle id="icon-info-output"></b-icon-info-circle>
                    <b-tooltip target="icon-info-output">
                      <a class="small text-light" :href="swapOutput.contract | explorerUrl(chainId, 'token')" target="_blank">
                        {{ swapOutput.contract }}
                      </a>
                    </b-tooltip>
                  </template>
                </div>
                <div>
                  Price impact: {{ displayPriceImpact }}
                </div>
              </div>
              <div class="label-row small text-secondary">
                <div>
                  <span class="mr-2 cursor-pointer"
                        @click="onClickSetWebTitlePriceType('output')"
                        :class="{ 'font-weight-500': webTitlePriceType === 'output' }">
                    1 {{ swapOutput.symbol }} = {{ swapOutput.displayPriceOther }} {{ swapInput.symbol }}
                  </span>
                  <b-icon-info-circle id="output-unit-price-info"></b-icon-info-circle>
                  <b-tooltip target="output-unit-price-info">Quoted unit price when swapping with the specified volume</b-tooltip>
                </div>
              </div>
            </div>
          </div>
          <div v-if="quoteErrorMsg" class="mb-3 text-danger border border-danger d-flex flex-row align-items-center px-1 py-1">
            <div class="flex-grow-1 text-break">
              Error requesting quote: {{ quoteErrorMsg }}
            </div>
            <div class="cursor-pointer p-1" @click="quoteErrorMsg = ''"><b-icon-x-circle/></div>
          </div>
          <div class="d-flex flex-row flex-wrap align-items-center justify-content-between mb-2">
            <label class="m-0" for="inputSendToAddress">Send to address</label>
            <div class="text-primary">
              <small @click="onClickTransferAllToDestReceiver" class="mr-3" v-b-tooltip.hover
                     :class="destReceiverPk && !isSendingToDestReceiver ? 'text-primary cursor-pointer' : 'text-secondary'"
                     :title="'Send ' + swapOutput.walletDisplayBalance + ' ' + swapOutput.symbol + ' to the address below'">
                <b-spinner v-if="isSendingToDestReceiver" small />
                <b-icon-cursor v-else />
                Send now
              </small>
              <small class="cursor-pointer mr-3" v-b-modal:address-book-modal><b-icon-bookmark/> Address book</small>
              <small class="cursor-pointer" v-b-modal:save-wallet-modal><b-icon-plus-lg/> Save</small>
            </div>
          </div>
          <v-select class="vs-normalizer mb-3" :hidden="!!destReceiverLabel"
                    label="label"
                    :getOptionKey="s => s.address + ':' + s.memo"
                    :filter="destReceiverSelectDropdownFilter"
                    :options="savedWallets"
                    :clearSearchOnBlur="() => false"
                    v-on:option:selecting="onPickSavedWallet">
            <template v-slot:search="{attributes, events}">
              <input v-bind="attributes" v-on="events" v-model.trim="destReceiver"
                     class="form-control text-monospace" required minlength="2" maxlength="100"/>
            </template>
            <template v-slot:option="{ label, address }">
              <div class="py-1">
                <div>{{ label }}</div>
                <div class="text-secondary text-monospace">
                  <span class="d-inline d-md-none">{{ address | shortened }}</span>
                  <span class="d-none d-md-inline">{{ address }}</span>
                </div>
              </div>
            </template>
          </v-select>
          <div v-if="destReceiverLabel" class="border rounded d-flex flex-row align-items-center mb-3 p-2">
            <div class="flex-grow-1 min-width-0">
              <div class="overflow-hidden text-overflow-ellipsis">{{ destReceiverLabel }}</div>
              <div class="text-monospace">
                <a class="text-break" :href="destReceiverExplorerUrl" target="_blank">{{ destReceiver }}</a>
              </div>
            </div>
            <div class="flex-shrink-0">
              <b-button variant="link" class="text-secondary" @click="clearDestReceiver"><b-icon-x-circle/></b-button>
            </div>
          </div>
          <div class="mb-4 text-right">
            <b-button variant="link mr-3" @click="refreshQuote" :disabled="isRequestingQuote">Refresh quote</b-button>
            <b-button variant="primary" style="width: 160px;" @click="doSwap" :disabled="isSwapping">Swap</b-button>
          </div>
          <div class="border rounded mb-3 p-2" v-if="reviewInfo">
            <h6 class="mb-3 pb-2 border-bottom">Review</h6>
            <table class="table table-borderless">
              <tbody>
              <tr>
                <td>Price: {{ reviewInfo.fromTokenSymbol }} / {{ reviewInfo.toTokenSymbol }}</td>
                <td class="text-right">{{ reviewInfo.displayPrice }}</td>
              </tr>
              <tr>
                <td>Price: {{ reviewInfo.toTokenSymbol }} / {{ reviewInfo.fromTokenSymbol }}</td>
                <td class="text-right">{{ reviewInfo.displayInversePrice }}</td>
              </tr>
              <tr>
                <td>You spend</td>
                <td class="text-right">{{ reviewInfo.displayInputAmount }} {{ reviewInfo.fromTokenSymbol }}</td>
              </tr>
              <tr>
                <td>Estimated received</td>
                <td class="text-right">{{ reviewInfo.displayOutputAmount }} {{ reviewInfo.toTokenSymbol }}</td>
              </tr>
              <tr>
                <td>Minimum received</td>
                <td class="text-right">{{ reviewInfo.displayMinimumReceiveAmount }} {{ reviewInfo.toTokenSymbol }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-if="swapErrorMsg" class="mb-3 text-danger border border-danger d-flex flex-row align-items-center px-1 py-1">
            <div class="flex-grow-1 text-break">
              <div class="text-break small" style="max-height: 100px; overflow-y: scroll;">
                Error requesting swap: {{ swapErrorMsg }}
              </div>
            </div>
            <div class="cursor-pointer p-1" @click="swapErrorMsg = ''"><b-icon-x-circle/></div>
          </div>
          <SolanaTxStatusBanner v-if="swapTxSignature" :txSignature="swapTxSignature" @onClickClose="swapTxSignature = ''" />
          <div v-if="jitoBundleId" class="mb-3 text-info border border-info d-flex flex-row align-items-center px-1 py-1">
            <div class="flex-grow-1 text-break">
              Bundle sent. <a :href="'https://explorer.jito.wtf/bundle/'+jitoBundleId" target="_blank">View on explorer</a>
            </div>
            <div class="cursor-pointer p-1" @click="jitoBundleId = ''"><b-icon-x-circle/></div>
          </div>
          <div v-if="sendToDestReceiverTxSignature" class="mb-3 text-info border border-info d-flex flex-row align-items-center px-1 py-1">
            <div class="flex-grow-1 text-break">
              Transfer transaction sent. <a :href="sendToDestReceiverTxSignature | explorerUrl(chainId, 'tx')" target="_blank">View on explorer</a>
            </div>
            <div class="cursor-pointer p-1" @click="sendToDestReceiverTxSignature = ''"><b-icon-x-circle/></div>
          </div>
          <div class="border rounded mb-3 px-3 py-2">
            <h6 class="mb-3 pb-2 border-bottom">Swap options</h6>
            <div class="d-flex flex-row align-items-center justify-content-between mb-3">
              <label class="m-0">Aggregator</label>
              <b-form-radio-group v-model="aggregator" :options="aggregatorOptions"></b-form-radio-group>
            </div>
            <div class="mb-2">
              <div class="d-flex flex-row align-items-center justify-content-between mb-2">
                <div>Fee mode</div>
                <b-form-radio-group :options="feeModeOptions" v-model="feeMode" @change="onFeeModeChange" />
              </div>
              <div v-if="feeMode === 'normal'" class="d-flex flex-row flex-wrap align-items-center">
                <div v-for="multiplier in priorityFeeMultiplierOptions" :key="multiplier"
                     class="flex-shrink-0 small text-center mr-2 mb-2 px-2 py-1 cursor-pointer border rounded-pill"
                     :class="{ 'bg-primary': multiplier === priorityFeeMultiplier, 'text-white': multiplier === priorityFeeMultiplier }"
                     style="min-width: 50px;" @click="onClickGasPriceMultiplier(multiplier)">
                  {{ multiplier }}<template v-if="multiplier !== 'custom'">x</template>
                </div>
                <b-input-group v-if="priorityFeeMultiplier === 'custom'" class="flex-grow-1 mb-2" style="min-width: 120px; flex-basis: 120px;">
                  <b-form-input type="number" v-model="customPriorityFeeSOL" class="text-right"
                                min="0" step="0.000005" max="2"
                                @blur="onInputGasPriceBlur" :disabled="priorityFeeMultiplier !== 'custom'" />
                  <b-input-group-append>
                    <b-input-group-text>SOL</b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div v-if="feeMode === 'jitoBundle'" class="d-flex flex-row align-items-center">
                <b-input-group class="flex-grow-1 mb-2" style="min-width: 120px; flex-basis: 120px;">
                  <b-input-group-prepend>
                    <b-input-group-text>Validator tip</b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="number" v-model="customPriorityFeeSOL" class="text-right" min="0.00001" step="0.00001" max="1000"
                                @blur="onInputGasPriceBlur" />
                  <b-input-group-append>
                    <b-input-group-text>SOL</b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>
            <div class="mb-2">
              <div><label>Slippage tolerance</label></div>
              <div class="d-flex flex-row flex-wrap align-items-center">
                <div v-for="slippageOption in slippageToleranceOptions" :key="slippageOption"
                     class="flex-shrink-0 small text-center mr-2 mb-2 px-2 py-1 cursor-pointer border rounded-pill"
                     :class="{ 'bg-primary': slippageOption === slippageTolerance, 'text-white': slippageOption === slippageTolerance }"
                     style="width: 64px;" @click="slippageTolerance = slippageOption">
                  {{ slippageOption }}%
                </div>
                <b-input-group class="flex-grow-1 mb-2" style="min-width: 120px; flex-basis: 120px;">
                  <b-form-input type="number" v-model="slippageTolerance" class="text-right" @blur="onInputSlippageBlur"
                                min="0" step="0.001" max="50"></b-form-input>
                  <b-input-group-append>
                    <b-input-group-text>%</b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div v-if="slippageTolerance >= 10" class="text-danger text-right mt-2 small">Warning: high slippage</div>
            </div>
            <div v-if="aggregator === 'jupiter'" class="mb-2">
              <b-form-checkbox v-model="restrictIntermediateTokens" switch>
                Restrict immediate tokens
                <span class="text-secondary ml-1" v-b-tooltip.hover title="[Quote] Restrict intermediate tokens to a top token set that has stable liquidity. This will help to ease potential high slippage error rate when swapping with minimal impact on pricing.">
                  <b-icon-info-circle />
                </span>
              </b-form-checkbox>
              <b-form-checkbox v-model="onlyDirectRoutes" switch>
                Direct route only
                <span class="text-secondary ml-1" v-b-tooltip.hover title="[Quote] Direct Routes limits Jupiter routing to single hop routes only.">
                  <b-icon-info-circle />
                </span>
              </b-form-checkbox>
              <b-form-checkbox v-model="useSharedAccounts" switch>
                Use shared accounts
                <span class="text-secondary ml-1" v-b-tooltip.hover title="[Swap] This enables the usage of shared program accountns. That means no intermediate token accounts or open orders accounts need to be created for the users. But it also means that the likelihood of hot accounts is higher.">
                  <b-icon-info-circle />
                </span>
              </b-form-checkbox>
            </div>
          </div>
        </section>

        <section class="px-3 col-lg-6">
          <div class="border rounded mb-3 px-3 py-2">
            <div class="d-flex flex-row align-items-center justify-content-between">
              <div>
                <span v-if="!showBotOrderForm" class="text-primary cursor-pointer" @click="showBotOrderForm = true">Create bot order</span>
                <span v-if="showBotOrderForm" class="text-primary cursor-pointer" @click="showBotOrderForm = false">Hide form</span>
              </div>
              <div v-if="showBotOrderForm">
                <router-link to="/cex-balance-watch-orders">Manage orders</router-link>
              </div>
            </div>
            <div v-if="showBotOrderForm" class="border-top mt-2 pt-2">
              <NewCexBalanceWatchOrderModal
                ref="cex-balance-watch-form"
                :p_base-asset="swapOutput && swapOutput.symbol"
                :p_quote-asset="swapInput && swapInput.symbol"
              />
            </div>
          </div>
        </section>
      </b-row>
    </b-container>

    <b-modal id="pick-swap-token-modal" title="Select a token" hide-footer no-fade no-close-on-backdrop>
      <PickTokenModal :chainId="chainId" :walletAddress="walletAddress" @pick-token="onPickToken"></PickTokenModal>
    </b-modal>
    <b-modal id="address-book-modal" title="Address book" hide-footer no-fade no-close-on-backdrop @hidden="reloadSavedWallets">
      <AddressBookModal type="solana" @pick-wallet="onPickSavedWallet"></AddressBookModal>
    </b-modal>
    <b-modal id="save-wallet-modal" title="Save new wallet" hide-footer no-fade no-close-on-backdrop @hidden="reloadSavedWallets">
      <SaveWalletModal modal-id="save-wallet-modal" :prefillAddress="destReceiver" type="solana" @done="onPickSavedWallet"></SaveWalletModal>
    </b-modal>
    <b-modal id="wait-then-send-to-dest-receiver" hide-header hide-footer no-fade no-close-on-backdrop>
      <WaitThenSendOutputToDestReceiverModal
        v-if="waitAndSendModalParams"
        modalId="wait-then-send-to-dest-receiver"
        :txSignature="waitAndSendModalParams.txSignature"
        :mint="waitAndSendModalParams.mint"
        :decimals="waitAndSendModalParams.decimals"
        :balanceBeforeSwap="waitAndSendModalParams.balanceBeforeSwap"
        :minimumTransferAmount="waitAndSendModalParams.minimumTransferAmount"
        :destReceiver="waitAndSendModalParams.destReceiver"
        @done="sendToDestReceiverTxSignature = $event"/>
    </b-modal>
  </main>
</template>

<style lang="scss" scoped>
  .label-row {
    height: 1.375rem;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .asset-btn {
    flex-shrink: 0;
    max-width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 8px;
    margin-right: 16px;

    &:hover {
      background-color: #e8e8e8;
    }
  }

  table {
    tr, td {
      padding: 8px 16px;
    }
  }
</style>

<script lang="ts">
  import {Component} from 'vue-property-decorator';
  import _ from "lodash";
  import BigNumber from "bignumber.js";
  import BaseComponent from "@/components/BaseComponent";
  import * as web3Service from "@/services/web3Service";
  import * as jupiterService from "@/services/jupiterService";
  import * as savedWalletsService from "@/services/savedWalletsService";
  import * as customTokensService from "@/services/customTokensService";
  import * as jitoBundleService from "@/services/jitoBundleService";
  import * as utils from "@/utils";
  import * as constants from "@/constants";
  import PickTokenModal from "@/components/PickTokenModal.vue";
  import SaveWalletModal from "@/components/SaveWalletModal.vue";
  import AddressBookModal from "@/components/AddressBookModal.vue";
  import NewCexBalanceWatchOrderModal from "@/components/NewCexBalanceWatchOrderModal.vue";
  import {
    AddressLookupTableAccount, ComputeBudgetInstruction, ComputeBudgetProgram, LAMPORTS_PER_SOL,
    PublicKey, SystemProgram,
    Transaction,
    TransactionInstruction, TransactionMessage,
    VersionedTransaction
  } from "@solana/web3.js";
  import {
    createAssociatedTokenAccountIdempotentInstruction,
    createTransferCheckedInstruction,
    getAssociatedTokenAddressSync, unpackAccount
  } from "@solana/spl-token";
  import WaitThenSendOutputToDestReceiverModal from "@/components/WaitThenSendOutputToDestReceiverModal.vue";
  import SolanaTxStatusBanner from "@/components/SolanaTxStatusBanner.vue";

  @Component({
    components: {
      SolanaTxStatusBanner,
      WaitThenSendOutputToDestReceiverModal,
      NewCexBalanceWatchOrderModal, AddressBookModal, SaveWalletModal, PickTokenModal
    }
  })
  export default class SwapSolana extends BaseComponent {

    readonly chainId = constants.CHAIN_ID_SOLANA;
    walletAddress = "";

    switchChains = [
      constants.CHAIN_ID_ETH,
      constants.CHAIN_ID_BSC,
      constants.CHAIN_ID_POLYGON,
      constants.CHAIN_ID_ARBITRUM,
      constants.CHAIN_ID_OPTIMISM,
      constants.CHAIN_ID_BASE,
      constants.CHAIN_ID_FANTOM,
      constants.CHAIN_ID_SOLANA,
      constants.CHAIN_ID_TRON,
    ].map(chainId => ({
      chainId,
      name: constants.chainNames[chainId],
      logo: constants.NETWORK_LOGO[chainId]
    }));
    get currentChainInfo() {
      return { chainId: this.chainId, name: constants.chainNames[this.chainId], logo: constants.NETWORK_LOGO[this.chainId] };
    }

    swapInput = {
      name: "",
      contract: "",
      isNative: false,
      decimals: 0,
      symbol: "",
      logo: "",
      walletBalance: "0",
      walletDisplayBalance: "0",
      displayAmount: "",
      displayPriceUsd: "--",
      displayPriceOther: "--",
    };

    swapOutput = {
      name: "",
      contract: "",
      isNative: false,
      decimals: 0,
      symbol: "",
      logo: "",
      walletBalance: "0",
      walletDisplayBalance: "0",
      displayAmount: "",
      displayPriceUsd: "--",
      displayPriceOther: "--",
    };
    displayPriceImpact = "--";

    webTitlePriceType = "input";

    destReceiverLabel = "";
    destReceiver = "";
    get destReceiverPk() {
      return utils.solanaPublicKeyOrNull(this.destReceiver);
    }
    savedWallets = [];
    isSendingToDestReceiver = false;

    pickingTokenMode: "input" | "output" = "input";

    reviewInfo: {
      fromTokenSymbol: string,
      toTokenSymbol: string,
      displayInputAmount: string,
      displayOutputAmount: string,
      displayMinimumReceiveAmount: string,
      displayPrice: string,
      displayInversePrice: string,
    } = null;

    isRequestingQuote = false;
    quoteErrorMsg = "";

    isSwapping = false;
    swapErrorMsg = "";
    swapTxSignature = "";
    jitoBundleId = "";
    waitAndSendModalParams = {
      txSignature: "",
      mint: "",
      decimals: 0,
      balanceBeforeSwap: "",
      minimumTransferAmount: "",
      destReceiver: ""
    };
    sendToDestReceiverTxSignature = "";

    feeMode = "normal";
    feeModeOptions = [
      { value: "normal", text: "Priority fee" },
      { value: "jitoBundle", text: "Jito Bundle" },
    ];
    priorityFeeMultiplierOptions = [1, 5, 10, "custom"];
    priorityFeeMultiplier: number | "custom" = 1;
    customPriorityFeeSOL: number | string = 0;
    dynamicComputeUnitLimit = true;

    slippageTolerance: number | string = 1;
    slippageToleranceOptions = [0.1, 0.5, 1, 3];

    aggregatorOptions = [
      { value: "jupiter", text: "Jupiter", disabled: false },
    ];
    aggregator = "jupiter";
    jupiterQuoteResponse;

    onlyDirectRoutes = false;
    useSharedAccounts = false;
    restrictIntermediateTokens = false;

    showBotOrderForm = false;

    refreshSwapTokensAndBalancesTimeoutHandler = null;
    refreshQuoteTimeoutHandler = null;
    refreshWalletTokenBalancesTimeoutHandler = null;

    isDestroyed = false;


    async mounted() {
      document.title = "Swap";
      await web3Service.waitForSolanaProvider();
      await web3Service.getSolanaProvider().connect();
      this.walletAddress = await web3Service.getConnectedSolanaAddress();

      this.loadSettings();
      this.reloadSavedWallets();

      this.refreshWalletTokenBalances();
      await this.refreshSwapTokensAndBalances();
      this.refreshQuote();
    }

    loadSettings() {
      try {
        const savedObj = JSON.parse(localStorage.getItem(`swap-${this.chainId}`));
        Object.assign(this, _.pick(savedObj, [
          "aggregator", "feeMode", "priorityFeeMultiplier", "slippageTolerance", "allowPartialFill", "customPriorityFeeSOL", "restrictIntermediateTokens", "onlyDirectRoutes", "useSharedAccounts"
        ]));
        Object.assign(this.swapInput, _.pick(savedObj.swapInput, ["name", "logo", "contract", "symbol", "decimals", "displayAmount"]));
        Object.assign(this.swapOutput, _.pick(savedObj.swapOutput, ["name", "logo", "contract", "symbol", "decimals"]));
        // Object.assign(this.swapInput, _.pick(savedObj.swapInput, ["contract", "displayAmount"]));
        // Object.assign(this.swapOutput, _.pick(savedObj.swapOutput, ["contract"]));

        const destReceiverObj = customTokensService.getSwapDestReceiverByToken(this.chainId, this.swapOutput.contract);
        this.destReceiver = destReceiverObj?.destReceiver || "";
        this.destReceiverLabel = destReceiverObj?.destReceiverLabel || "";

      } catch (e) {
        console.error(e);
      }
    }

    reloadSavedWallets() {
      this.savedWallets = savedWalletsService.getAllSavedWallets2("solana");
    }

    async refreshSwapTokensAndBalances() {
      clearTimeout(this.refreshSwapTokensAndBalancesTimeoutHandler);
      if (this.isDestroyed) return;

      try {
        this.walletAddress = await web3Service.getConnectedSolanaAddress();
        const listedTokensKeyed = await web3Service.getListedTokens(this.chainId);

        // input token metadata
        if (!this.swapInput.contract) {
          this.swapInput.contract = constants.WSOL;
          this.swapInput.displayAmount = "1";
        }
        this.swapInput.isNative = this.swapInput.contract === constants.WSOL;

        const inputTokenDetailsFromListed = listedTokensKeyed[this.swapInput.contract];
        if (inputTokenDetailsFromListed) {
          this.swapInput.name = inputTokenDetailsFromListed.name;
          this.swapInput.decimals = inputTokenDetailsFromListed.decimals;
          this.swapInput.symbol = inputTokenDetailsFromListed.symbol;
          this.swapInput.logo = inputTokenDetailsFromListed.logoURI;
        } else if (!this.swapInput.name || !this.swapInput.logo || !utils.isTokenDecimalsValid(this.swapInput.decimals) || !this.swapInput.symbol) {
          const inputTokenDetailsFromProvider = await web3Service.getSolanaTokenMetadata(this.swapInput.contract);
          this.swapInput.name = inputTokenDetailsFromProvider.name;
          this.swapInput.decimals = inputTokenDetailsFromProvider.decimals;
          this.swapInput.symbol = inputTokenDetailsFromProvider.symbol;
          this.swapInput.logo = inputTokenDetailsFromProvider.logo;
        }

        // output token metadata
        if (!this.swapOutput.contract) {
          this.swapOutput.contract = constants.USDT_TOKEN[this.chainId].address;
        }
        this.swapOutput.isNative = this.swapOutput.contract === constants.WSOL;

        const outputTokenDetailsFromListed = listedTokensKeyed[this.swapOutput.contract];
        if (outputTokenDetailsFromListed) {
          this.swapOutput.name = outputTokenDetailsFromListed.name;
          this.swapOutput.decimals = outputTokenDetailsFromListed.decimals;
          this.swapOutput.symbol = outputTokenDetailsFromListed.symbol;
          this.swapOutput.logo = outputTokenDetailsFromListed.logoURI;
        } else if (!this.swapOutput.name || !this.swapOutput.logo || !utils.isTokenDecimalsValid(this.swapOutput.decimals) || !this.swapOutput.symbol) {
          const outputTokenDetailsFromProvider = await web3Service.getSolanaTokenMetadata(this.swapOutput.contract);
          this.swapOutput.name = outputTokenDetailsFromProvider.name;
          this.swapOutput.decimals = outputTokenDetailsFromProvider.decimals;
          this.swapOutput.symbol = outputTokenDetailsFromProvider.symbol;
          this.swapOutput.logo = outputTokenDetailsFromProvider.logo;
        }

        // input and output token balances
        const walletBalances = await web3Service.getSolanaWalletTokenBalances(this.walletAddress);

        this.swapInput.walletBalance = (walletBalances[this.swapInput.contract] || 0).toString();
        this.swapInput.walletDisplayBalance = BigNumber(this.swapInput.walletBalance)
          .shiftedBy(-this.swapInput.decimals)
          .decimalPlaces(8, BigNumber.ROUND_DOWN)
          .toFixed();

        this.swapOutput.walletBalance = (walletBalances[this.swapOutput.contract] || 0).toString();
        this.swapOutput.walletDisplayBalance = BigNumber(this.swapOutput.walletBalance)
          .shiftedBy(-this.swapOutput.decimals)
          .decimalPlaces(8, BigNumber.ROUND_DOWN)
          .toFixed();

      } catch (e) {
        console.error(e);
      }

      clearTimeout(this.refreshSwapTokensAndBalancesTimeoutHandler);
      this.refreshSwapTokensAndBalancesTimeoutHandler = setTimeout(() => this.refreshSwapTokensAndBalances(), 3500);
    }

    async refreshWalletTokenBalances() {
      clearTimeout(this.refreshWalletTokenBalancesTimeoutHandler);
      if (this.isDestroyed) return;

      try {
        await Promise.allSettled([
          web3Service.getSolanaWalletTokenBalances(this.walletAddress),
          web3Service.getListedTokens(this.chainId)
        ]);
      } catch (e) {

      }

      clearTimeout(this.refreshWalletTokenBalancesTimeoutHandler);
      this.refreshWalletTokenBalancesTimeoutHandler = setTimeout(() => this.refreshWalletTokenBalances(), 10000);
    }


    onClickInputAsset() {
      this.pickingTokenMode = "input";
      this.$bvModal.show("pick-swap-token-modal");
    }

    onClickOutputAsset() {
      this.pickingTokenMode = "output";
      this.$bvModal.show("pick-swap-token-modal");
    }

    onPickToken(tokenObj: any) {
      if (this.pickingTokenMode === "input") {
        if (tokenObj.address === this.swapOutput.contract) {
          this.reverseInputOutput();
        } else {
          this.swapInput.name = tokenObj.name;
          this.swapInput.contract = tokenObj.address;
          this.swapInput.isNative = tokenObj.isNative;
          this.swapInput.decimals = tokenObj.decimals;
          this.swapInput.symbol = tokenObj.symbol;
          this.swapInput.logo = tokenObj.logo;
          this.swapInput.walletBalance = tokenObj.balance;
          this.swapInput.walletDisplayBalance = tokenObj.displayBalance;
        }

      } else if (this.pickingTokenMode === "output") {
        if (tokenObj.address === this.swapInput.contract) {
          this.reverseInputOutput();
        } else {
          this.swapOutput.name = tokenObj.name;
          this.swapOutput.contract = tokenObj.address;
          this.swapOutput.isNative = tokenObj.isNative;
          this.swapOutput.decimals = tokenObj.decimals;
          this.swapOutput.symbol = tokenObj.symbol;
          this.swapOutput.logo = tokenObj.logo;
          this.swapOutput.walletBalance = tokenObj.balance;
          this.swapOutput.walletDisplayBalance = tokenObj.displayBalance;

          const destReceiverObj = customTokensService.getSwapDestReceiverByToken(this.chainId, this.swapOutput.contract);
          this.destReceiver = destReceiverObj?.destReceiver || "";
          this.destReceiverLabel = destReceiverObj?.destReceiverLabel || "";
        }
      }

      this.swapInput.displayPriceUsd = "--";
      this.swapInput.displayPriceOther = "--";
      this.swapOutput.displayPriceUsd = "--";
      this.swapOutput.displayPriceOther = "--";
      this.displayPriceImpact = "--";

      this.$bvModal.hide("pick-swap-token-modal");
      this.refreshQuote();
      this.refreshSwapTokensAndBalances();
    }

    onPickSavedWallet(savedWallet: savedWalletsService.SavedWallet) {
      this.destReceiver = savedWallet.address;
      this.destReceiverLabel = savedWallet.label;
      this.$bvModal.hide("address-book-modal");
    }

    clearDestReceiver() {
      this.destReceiverLabel = "";
      this.destReceiver = "";
    }

    get destReceiverExplorerUrl() {
      return utils.getExplorerUrl(this.chainId, "address", this.destReceiver);
    }

    destReceiverSelectDropdownFilter(options: any[], search: string) {
      search = utils.sanitizeSearchText(search).toLowerCase();
      return options.filter(savedWallet =>
        savedWallet.label.toLowerCase().includes(search) ||
        savedWallet.address.toLowerCase().includes(search)
      );
    }

    onClickAllIn() {
      this.swapInput.displayAmount = BigNumber(this.swapInput.walletBalance)
        .shiftedBy(-this.swapInput.decimals)
        .decimalPlaces(8, BigNumber.ROUND_DOWN)
        .toFixed();
      this.refreshQuote();
    }

    inputAmountChangedDebounced = _.debounce(this.inputAmountChanged, 300);
    inputAmountChanged() {
      this.refreshQuote();
    }

    reverseInputOutput() {
      const temp = this.swapInput;
      this.swapInput = this.swapOutput;
      this.swapOutput = temp;

      this.swapInput.displayPriceUsd = "--";
      this.swapInput.displayPriceOther = "--";
      this.swapOutput.displayPriceUsd = "--";
      this.swapOutput.displayPriceOther = "--";
      this.displayPriceImpact = "--";

      const destReceiverObj = customTokensService.getSwapDestReceiverByToken(this.chainId, this.swapOutput.contract);
      this.destReceiver = destReceiverObj?.destReceiver || "";
      this.destReceiverLabel = destReceiverObj?.destReceiverLabel || "";

      this.refreshQuote();
    }

    onClickSetWebTitlePriceType(type: string) {
      if (this.webTitlePriceType !== type) {
        this.webTitlePriceType = type;
        this.refreshQuote();
      }
    }

    onFeeModeChange() {
      this.onInputGasPriceBlur();
    }

    onClickGasPriceMultiplier(multiplier) {
      this.priorityFeeMultiplier = multiplier;
      this.onInputGasPriceBlur();
    }

    onInputGasPriceBlur() {
      if (this.feeMode === "normal") {
        if (this.priorityFeeMultiplier === "custom") {
          const customPriorityFeeSolBN = BigNumber(this.customPriorityFeeSOL);
          if (customPriorityFeeSolBN.isNaN() || customPriorityFeeSolBN.lt(0)) {
            this.customPriorityFeeSOL = 0; // fix: default priority fee
          } else if (customPriorityFeeSolBN.gt(2)) {
            this.customPriorityFeeSOL = 2; // fix: maximum priority fee
          } else if (customPriorityFeeSolBN.decimalPlaces() > 9) {
            // fix: round to 9 decimal places
            this.customPriorityFeeSOL = customPriorityFeeSolBN.decimalPlaces(9).toFixed();
          }
        }

      } else if (this.feeMode === "jitoBundle") {
        const customPriorityFeeSolBN = BigNumber(this.customPriorityFeeSOL);
        if (customPriorityFeeSolBN.isNaN() || customPriorityFeeSolBN.lt(0.00001)) {
          this.customPriorityFeeSOL = 0.00001; // fix: minimum tip
        } else if (customPriorityFeeSolBN.gt(1000)) {
          this.customPriorityFeeSOL = 1000; // fix: maximum tip
        } else if (customPriorityFeeSolBN.decimalPlaces() > 9) {
          // fix: round to 9 decimal places
          this.customPriorityFeeSOL = customPriorityFeeSolBN.decimalPlaces(9, BigNumber.ROUND_UP).toFixed();
        }
      }
    }

    onInputSlippageBlur() {
      const slippageToleranceBN = BigNumber(this.slippageTolerance);

      if (slippageToleranceBN.isNaN()) {
        this.slippageTolerance = 1; // fix: default slippage
      } else if (slippageToleranceBN.lt(0)) {
        this.slippageTolerance = 0; // fix: min slippage
      } else if (slippageToleranceBN.gt(40)) {
        this.slippageTolerance = 40; // fix: max slippage
      } else if (slippageToleranceBN.decimalPlaces() > 3) {
        // fix: round to 3 decimal places
        this.slippageTolerance = slippageToleranceBN.decimalPlaces(3).toNumber();
      }
    }

    async refreshQuote() {
      clearTimeout(this.refreshQuoteTimeoutHandler);
      if (this.isDestroyed) return;

      const displayInputAmount = this.swapInput.displayAmount;
      const fromRealTokenAmountBN = BigNumber(displayInputAmount);
      if (!fromRealTokenAmountBN.gt(0)) {
        this.swapOutput.displayAmount = "";
        this.swapInput.displayPriceUsd = "--";
        this.swapInput.displayPriceOther = "--";
        this.swapOutput.displayPriceUsd = "--";
        this.swapOutput.displayPriceOther = "--";
        this.displayPriceImpact = "--";
        return;
      }

      try {
        this.isRequestingQuote = true;
        this.quoteErrorMsg = "";

        let inputPriceBN: BigNumber, outputPriceBN: BigNumber;

        if (this.aggregator === "jupiter") {
          const getQuoteParams = {
            inputMint: this.swapInput.contract,
            outputMint: this.swapOutput.contract,
            amount: fromRealTokenAmountBN
              .shiftedBy(this.swapInput.decimals)
              .decimalPlaces(0, BigNumber.ROUND_DOWN)
              .toFixed(),
            slippageBps: BigNumber(this.slippageTolerance)
              .multipliedBy(100) // multiply 100, convert % to bps
              .decimalPlaces(0, BigNumber.ROUND_DOWN)
              .toNumber(),
            swapMode: "ExactIn",
            restrictIntermediateTokens: this.restrictIntermediateTokens,
            onlyDirectRoutes: this.onlyDirectRoutes,
            maxAccounts: this.feeMode === "jitoBundle" ? 63 : 64
          };

          const quoteRes = await jupiterService.getQuote(getQuoteParams);
          if ( // make sure params haven't changed on ui
            displayInputAmount === this.swapInput.displayAmount &&
            quoteRes.inputMint === this.swapInput.contract &&
            quoteRes.outputMint === this.swapOutput.contract
          ) {
            const toRealTokenAmountBN = BigNumber(quoteRes.outAmount).shiftedBy(-this.swapOutput.decimals);
            inputPriceBN = toRealTokenAmountBN.dividedBy(fromRealTokenAmountBN);
            this.swapInput.displayPriceOther = inputPriceBN.precision(6).toFixed();
            outputPriceBN = fromRealTokenAmountBN.dividedBy(toRealTokenAmountBN);
            this.swapOutput.displayPriceOther = outputPriceBN.precision(6).toFixed();
            this.swapOutput.displayAmount = toRealTokenAmountBN.decimalPlaces(8).toFixed();

            const priceImpactPctBN = BigNumber(quoteRes.priceImpactPct).multipliedBy(100);
            if (priceImpactPctBN.lt(0.1)) {
              this.displayPriceImpact = "< 0.1%";
            } else {
              this.displayPriceImpact = BigNumber(quoteRes.priceImpactPct).multipliedBy(100).decimalPlaces(4).toFixed() + "%";
            }

            this.jupiterQuoteResponse = quoteRes;
          }
        }

        if (!this.isDestroyed && inputPriceBN && outputPriceBN) {
          if (this.webTitlePriceType === "input") {
            document.title = `${inputPriceBN.precision(6).toString()} ${this.swapInput.symbol}/${this.swapOutput.symbol} | Swap`;
          } else {
            document.title = `${outputPriceBN.precision(6).toString()} ${this.swapOutput.symbol}/${this.swapInput.symbol} | Swap`;
          }
        }

      } catch (e) {
        this.quoteErrorMsg = e.response?.data?.description || e.response?.data?.error || e.message;
        console.error(e);

      } finally {
        this.isRequestingQuote = false;
      }

      this.saveSettings();

      clearTimeout(this.refreshQuoteTimeoutHandler);
      this.refreshQuoteTimeoutHandler = setTimeout(() => this.refreshQuote(), 10000);
    }

    async doSwap() {
      if (this.destReceiver && !this.destReceiverPk) {
        this.swapErrorMsg = "Invalid receiver address";
        return;
      }

      try {
        this.isSwapping = true;
        this.swapErrorMsg = "";
        this.swapTxSignature = "";
        this.jitoBundleId = "";

        if (!this.walletAddress) {
          throw new Error("Wallet not connected");
        }

        let fromTokenAmountBN = BigNumber(this.swapInput.displayAmount)
          .shiftedBy(this.swapInput.decimals)
          .decimalPlaces(0, BigNumber.ROUND_DOWN);

        if (!fromTokenAmountBN.gt(0)) {
          throw new Error("Zero input amount");
        }

        const inputWalletBalanceBN = BigNumber(this.swapInput.walletBalance);
        if (inputWalletBalanceBN.gt(0)) {
          const divByBalance = fromTokenAmountBN.div(inputWalletBalanceBN);
          if (divByBalance.gt(1) && divByBalance.lt(1.0001)) {
            fromTokenAmountBN = inputWalletBalanceBN;
          }
        }

        const fromTokenAmount = fromTokenAmountBN.toFixed();
        const slippage = +this.slippageTolerance || 1;

        try {
          customTokensService.setSwapDestReceiverByToken(this.chainId, this.swapOutput.contract, this.destReceiver);
        } catch (e) {
          console.error(e);
        }

        if (this.aggregator === "jupiter") {
          const quoteResponse = utils.jsonClone(this.jupiterQuoteResponse);

          if (
            !quoteResponse ||
            this.swapInput.contract !== quoteResponse.inputMint &&
            this.swapOutput.contract !== quoteResponse.outputMint &&
            fromTokenAmount !== quoteResponse.inAmount
          ) {
            await this.refreshQuote();
          }

          if (
            quoteResponse &&
            this.swapInput.contract === quoteResponse.inputMint &&
            this.swapOutput.contract === quoteResponse.outputMint &&
            fromTokenAmount === quoteResponse.inAmount
          ) {

            const swapParams: Record<string, any> = {
              userPublicKey: this.walletAddress,
              wrapAndUnwrapSol: true,
              useSharedAccounts: this.useSharedAccounts,
              prioritizationFeeLamports: "auto",
              dynamicComputeUnitLimit: this.dynamicComputeUnitLimit,
              quoteResponse,
            };
            if (this.feeMode === "normal") {
              if (this.priorityFeeMultiplier === "custom") {
                swapParams.prioritizationFeeLamports = BigNumber(this.customPriorityFeeSOL).multipliedBy(LAMPORTS_PER_SOL).decimalPlaces(0).toNumber();
              } else if (this.priorityFeeMultiplier === 1) {
                swapParams.prioritizationFeeLamports = "auto";
              } else if (this.priorityFeeMultiplier > 1) {
                swapParams.prioritizationFeeLamports = {
                  autoMultiplier: this.priorityFeeMultiplier
                };
              }
            } else if (this.feeMode === "jitoBundle") {
              swapParams.prioritizationFeeLamports = 0;
            }

            const fromRealTokenAmountBN = BigNumber(quoteResponse.inAmount).shiftedBy(-this.swapInput.decimals);
            const toRealTokenAmountBN = BigNumber(quoteResponse.outAmount).shiftedBy(-this.swapOutput.decimals);

            this.reviewInfo = {
              displayPrice: toRealTokenAmountBN.dividedBy(fromRealTokenAmountBN).precision(6).toFixed(),
              displayInversePrice: fromRealTokenAmountBN.dividedBy(toRealTokenAmountBN).precision(6).toFixed(),
              displayInputAmount: fromRealTokenAmountBN.decimalPlaces(8).toFixed(),
              displayOutputAmount: toRealTokenAmountBN.decimalPlaces(8).toFixed(),
              displayMinimumReceiveAmount: toRealTokenAmountBN.multipliedBy(1 - slippage / 100).decimalPlaces(8).toFixed(),
              fromTokenSymbol: this.swapInput.symbol,
              toTokenSymbol: this.swapOutput.symbol,
            };

            if (this.feeMode === "normal") {
              const res = await jupiterService.getSwap(swapParams);
              const swapTransactionBuf = Buffer.from(res.swapTransaction, "base64");
              const transaction = VersionedTransaction.deserialize(swapTransactionBuf);
              const signedTransaction = await web3Service.getSolanaProvider().signTransaction(transaction);
              const signature = await web3Service.solanaSpamSendTx(signedTransaction);
              console.log("sent transaction", signature);
              this.swapTxSignature = signature;

              if (this.destReceiver) {
                this.waitAndSendModalParams = {
                  txSignature: signature,
                  mint: this.swapOutput.contract,
                  decimals: this.swapOutput.decimals,
                  balanceBeforeSwap: this.swapOutput.walletBalance,
                  minimumTransferAmount: quoteResponse.otherAmountThreshold,
                  destReceiver: this.destReceiver
                };
                await utils.delay(1);
                this.$bvModal.show("wait-then-send-to-dest-receiver");
              }

            } else if (this.feeMode === "jitoBundle") {
              if (this.destReceiver) {
                throw new Error(`"Send to address" can't be used with Jito Bundle`);
              }
              const {
                tokenLedgerInstruction, // If you are using `useTokenLedger = true`.
                computeBudgetInstructions, // The necessary instructions to setup the compute budget.
                setupInstructions, // Setup missing ATA for the users.
                swapInstruction, // The actual swap instruction.
                cleanupInstruction, // Unwrap the SOL if `wrapAndUnwrapSol = true`.
                addressLookupTableAddresses, // The lookup table addresses that you can use if you are using versioned transaction.
              } = await jupiterService.getSwapInstruction(swapParams);

              const deserializeInstruction = instruction => new TransactionInstruction({
                programId: new PublicKey(instruction.programId),
                keys: instruction.accounts.map((key) => ({
                  pubkey: new PublicKey(key.pubkey),
                  isSigner: key.isSigner,
                  isWritable: key.isWritable,
                })),
                data: Buffer.from(instruction.data, "base64"),
              });

              const addressLookupTableAccounts = await web3Service.getAddressLookupTableAccounts(
                addressLookupTableAddresses.map(address => new PublicKey(address))
              );

              const blockhash = (await web3Service.solanaWeb3.getLatestBlockhash("confirmed")).blockhash;
              const walletPk = web3Service.getSolanaProvider().publicKey;

              const instructions: TransactionInstruction[] = [];
              if (computeBudgetInstructions?.length) {
                const ixs = computeBudgetInstructions.map(deserializeInstruction);
                instructions.push(...ixs);
                // without SetComputeUnitPrice ix, wallets set their own fee. so here we have to explicitly set it to zero
                if (!ixs.some(i => ComputeBudgetInstruction.decodeInstructionType(i) === "SetComputeUnitPrice")) {
                  instructions.push(ComputeBudgetProgram.setComputeUnitPrice({
                    microLamports: 0
                  }));
                }
              }
              if (setupInstructions?.length) {
                instructions.push(...setupInstructions.map(deserializeInstruction));
              }
              instructions.push(deserializeInstruction(swapInstruction));
              if (cleanupInstruction) {
                instructions.push(deserializeInstruction(cleanupInstruction));
              }
              instructions.push(jitoBundleService.tipInstruction(
                walletPk,
                BigNumber(this.customPriorityFeeSOL).multipliedBy(LAMPORTS_PER_SOL).toNumber()
              ));

              const transaction = new VersionedTransaction(new TransactionMessage({
                payerKey: walletPk,
                recentBlockhash: blockhash,
                instructions,
              }).compileToV0Message(addressLookupTableAccounts));
              const signedTransaction = await web3Service.getSolanaProvider().signTransaction(transaction);
              const { bundleId } = await jitoBundleService.sendBundle([signedTransaction]);
              this.jitoBundleId = bundleId;
            }
          }
        }


      } catch (e) {
        console.error(e);
        this.swapErrorMsg = e.response?.data?.description || e.response?.data?.error || e.response?.data?.message || e.message;

      } finally {
        this.isSwapping = false;
        this.reviewInfo = null;
      }
    }

    async onClickTransferAllToDestReceiver() {
      if (this.isSendingToDestReceiver || !this.destReceiverPk) return;

      const walletPk = web3Service.getSolanaProvider().publicKey;
      const destReceiverPk = this.destReceiverPk;

      try {
        this.isSendingToDestReceiver = true;

        if (this.swapOutput.contract === constants.WSOL) {
          const walletAccount = await web3Service.solanaWeb3.getAccountInfo(walletPk);
          const walletBalance = walletAccount?.lamports || 0;
          const transferAmount = walletBalance - 0.01e9;

          if (transferAmount > 0.01e9) {
            const blockhash = (await web3Service.solanaWeb3.getLatestBlockhash()).blockhash;
            const transaction = new VersionedTransaction(
              new TransactionMessage({
                recentBlockhash: blockhash,
                payerKey: walletPk,
                instructions: [
                  SystemProgram.transfer({
                    fromPubkey: walletPk,
                    toPubkey: destReceiverPk,
                    lamports: transferAmount
                  })
                ]
              }).compileToV0Message()
            );
            const signedTransaction = await web3Service.getSolanaProvider().signTransaction(transaction);
            const sendToDestReceiverTxSignature = await web3Service.solanaSpamSendTx(signedTransaction);
            this.sendToDestReceiverTxSignature = sendToDestReceiverTxSignature;
          } else {
            this.toastSuccess("Alert", "Min wallet balance 0.02 SOL");
          }

        } else {
          const mintPk = new PublicKey(this.swapOutput.contract);
          const walletTokenAccountPk = getAssociatedTokenAddressSync(mintPk, walletPk);
          console.log("walletTokenAccountPk", walletTokenAccountPk.toString());
          const destReceiverTokenAccountPk = getAssociatedTokenAddressSync(mintPk, destReceiverPk);
          console.log("destReceiverTokenAccountPk", destReceiverTokenAccountPk.toString());

          const fetchAccountPks = [walletTokenAccountPk, destReceiverTokenAccountPk];
          const [walletTokenAccount, destReceiverTokenAccount] = (await web3Service.solanaWeb3.getMultipleAccountsInfo(fetchAccountPks))
            .map((accountInfo, i) => accountInfo && unpackAccount(fetchAccountPks[i], accountInfo));

          const walletTokenAccountBalance = walletTokenAccount?.amount || 0n;

          if (walletTokenAccountBalance > 0n) {
            const instructions: TransactionInstruction[] = [];
            if (!destReceiverTokenAccount) {
              instructions.push(createAssociatedTokenAccountIdempotentInstruction(
                web3Service.getSolanaProvider().publicKey,
                destReceiverTokenAccountPk,
                destReceiverPk,
                mintPk
              ));
            }
            instructions.push(createTransferCheckedInstruction(
              walletTokenAccountPk,
              mintPk,
              destReceiverTokenAccountPk,
              walletPk,
              walletTokenAccountBalance,
              this.swapOutput.decimals
            ));
            const blockhash = (await web3Service.solanaWeb3.getLatestBlockhash()).blockhash;
            const messageV0 = new TransactionMessage({
              payerKey: web3Service.getSolanaProvider().publicKey,
              recentBlockhash: blockhash,
              instructions,
            }).compileToV0Message();
            const transaction = new VersionedTransaction(messageV0);
            const signedTransaction = await web3Service.getSolanaProvider().signTransaction(transaction);
            const sendToDestReceiverTxSignature = await web3Service.solanaSpamSendTx(signedTransaction);
            this.sendToDestReceiverTxSignature = sendToDestReceiverTxSignature;

          } else {
            this.toastSuccess("Alert",`Zero ${this.swapOutput.symbol} balance`);
          }
        }

      } catch (e) {
        this.toastError(e);

      } finally {
        this.isSendingToDestReceiver = false;
      }
    }

    saveSettings() {
      const savedObj = _.pick<any>(this, [
        "aggregator", "feeMode", "priorityFeeMultiplier", "slippageTolerance", "allowPartialFill", "customPriorityFeeSOL", "restrictIntermediateTokens", "onlyDirectRoutes", "useSharedAccounts"
      ]);
      savedObj.swapInput = _.pick(this.swapInput, ["name", "logo", "contract", "symbol", "decimals", "displayAmount"]);
      savedObj.swapOutput = _.pick(this.swapOutput, ["name", "logo", "contract", "symbol", "decimals"]);
      localStorage.setItem(`swap-${this.chainId}`, JSON.stringify(savedObj));
    }

    onClickSwitchChain(chainInfo) {
      if (utils.isEvmChain(chainInfo.chainId)) {
        this.$router.push("/swap");
        web3Service.switchEvmChain(chainInfo.chainId);
      } else if (chainInfo.chainId === constants.CHAIN_ID_TRON) {
        this.$router.push("/swap-tron");
      }
    }

    destroyed() {
      clearTimeout(this.refreshSwapTokensAndBalancesTimeoutHandler);
      clearTimeout(this.refreshQuoteTimeoutHandler);
      clearTimeout(this.refreshWalletTokenBalancesTimeoutHandler);
      this.isDestroyed = true;
    }

  }
</script>
