<template>
  <div>
    <div class="text-secondary">Name</div>
    <div class="mb-2 text-monospace">{{ errorObj.name }}</div>
    <div class="text-secondary">Message</div>
    <div class="mb-2 text-monospace">{{ errorObj.message }}</div>
    <div class="text-secondary">Stack</div>
    <div class="text-monospace small text-break" style="white-space: pre-wrap">{{ errorObj.stack }}</div>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import {Component, Prop, Vue} from 'vue-property-decorator';
  import _ from "lodash";
  import BaseComponent from "@/components/BaseComponent";

  @Component
  export default class OrderDetailsModal extends BaseComponent {

    @Prop() modalId?: string;
    @Prop({ required: true }) order: any;

    get errorObj() {
      return JSON.parse(this.order.error);
    }

    mounted() {

    }

  }
</script>
