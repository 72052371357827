import {ethers} from "ethers";
import * as utils from "@/utils";
import * as constants from "@/constants";
import * as web3Service from "@/services/web3Service";
import _ from "lodash";
import axios from "@/axios";
import BigNumber from "bignumber.js";

import liquidityBootstrapPoolAbi from "./abi/liquidityBootstrapPoolAbi.json";
import erc20Abi from "./abi/erc20Abi.json";
import multiCall3Abi from "./abi/multiCall3Abi.json";

const liquidityBootstrapPoolInterface = new ethers.Interface(liquidityBootstrapPoolAbi);
const erc20Interface = new ethers.Interface(erc20Abi);
const multiCall3Interface = new ethers.Interface(multiCall3Abi);

export async function getPoolMetadata(chainId: number, address: string) {
  const readFunctions = liquidityBootstrapPoolAbi.filter(fragmentAbi =>
    fragmentAbi.type === "function" &&
    fragmentAbi.inputs.length === 0 &&
    ["pure", "view"].includes(fragmentAbi.stateMutability)
  );
  const getLbpInfoCalls = readFunctions.map(functionAbi => ({
    target: address,
    callData: liquidityBootstrapPoolInterface.encodeFunctionData(functionAbi.name)
  }));

  const multiCall3Contract = new ethers.Contract(
    constants.MULTICALL3_CONTRACT[chainId],
    multiCall3Interface,
    await web3Service.getProviderForChain(chainId, false)
  );

  const getLbpInfoMultiCallRet = await multiCall3Contract.tryAggregate(false, getLbpInfoCalls);

  const ret: Record<string, any> = { chainId, address };
  for (let i = 0; i < getLbpInfoCalls.length; i++) {
    if (getLbpInfoMultiCallRet[i][0]) {
      const name = readFunctions[i].name;
      const decoded = liquidityBootstrapPoolInterface.decodeFunctionResult(name, getLbpInfoMultiCallRet[i][1]);
      ret[name] = decoded.length === 1 ? decoded[0] : decoded;
    }
  }

  // asset, share
  const getTokensInfoCalls = [
    { target: ret.asset, callData: erc20Interface.encodeFunctionData("name") },
    { target: ret.asset, callData: erc20Interface.encodeFunctionData("symbol") },
    { target: ret.asset, callData: erc20Interface.encodeFunctionData("decimals") },
    { target: ret.share, callData: erc20Interface.encodeFunctionData("name") },
    { target: ret.share, callData: erc20Interface.encodeFunctionData("symbol") },
    { target: ret.share, callData: erc20Interface.encodeFunctionData("decimals") },
  ];

  const getTokensInfoMultiCallRet = (await multiCall3Contract.aggregate(getTokensInfoCalls))[1];

  ret.assetTokenInfo = {
    address: ret.asset,
    name: erc20Interface.decodeFunctionResult("name", getTokensInfoMultiCallRet[0])[0],
    symbol: erc20Interface.decodeFunctionResult("symbol", getTokensInfoMultiCallRet[1])[0],
    decimals: Number(erc20Interface.decodeFunctionResult("decimals", getTokensInfoMultiCallRet[2])[0])
  };

  ret.shareTokenInfo = {
    address: ret.share,
    name: erc20Interface.decodeFunctionResult("name", getTokensInfoMultiCallRet[3])[0],
    symbol: erc20Interface.decodeFunctionResult("symbol", getTokensInfoMultiCallRet[4])[0],
    decimals: Number(erc20Interface.decodeFunctionResult("decimals", getTokensInfoMultiCallRet[5])[0])
  };

  return ret;
}

export async function getCurrentPriceAndPreviewSharesOut(poolMetadata, walletAddress: string, assetsInUiAmount?: BigNumber.Value) {
  const ret: { priceBN?: BigNumber, purchasedSharesBN: BigNumber, walletAssetBalanceBN: BigNumber, walletNativeBalanceBN: BigNumber, sharesOutBN?: BigNumber } = {
    purchasedSharesBN: BigNumber(0),
    walletAssetBalanceBN: BigNumber(0),
    walletNativeBalanceBN: BigNumber(0)
  };

  const multiCall3Contract = new ethers.Contract(
    constants.MULTICALL3_CONTRACT[poolMetadata.chainId],
    multiCall3Interface,
    await web3Service.getProviderForChain(poolMetadata.chainId, false)
  );

  const sharesOutParam = 10n ** BigInt(poolMetadata.shareTokenInfo.decimals);
  const calls = [
    {
      target: poolMetadata.address,
      callData: liquidityBootstrapPoolInterface.encodeFunctionData("previewAssetsIn", [sharesOutParam])
    },
    {
      target: poolMetadata.address,
      callData: liquidityBootstrapPoolInterface.encodeFunctionData("purchasedShares", [walletAddress])
    },
    {
      target: poolMetadata.asset,
      callData: erc20Interface.encodeFunctionData("balanceOf", [walletAddress])
    },
    {
      target: multiCall3Contract.target,
      callData: multiCall3Interface.encodeFunctionData("getEthBalance", [walletAddress])
    }
  ];
  const applyResultFunctions = [
    data => {
      const assetIn: bigint = liquidityBootstrapPoolInterface.decodeFunctionResult("previewAssetsIn", data)[0];
      ret.priceBN = BigNumber(assetIn.toString()).shiftedBy(-poolMetadata.assetTokenInfo.decimals);
    },
    data => {
      const purchasedShares: bigint = liquidityBootstrapPoolInterface.decodeFunctionResult("purchasedShares", data)[0];
      ret.purchasedSharesBN = BigNumber(purchasedShares.toString()).shiftedBy(-poolMetadata.shareTokenInfo.decimals);
    },
    data => {
      const walletAssetBalance: bigint = erc20Interface.decodeFunctionResult("balanceOf", data)[0];
      ret.walletAssetBalanceBN = BigNumber(walletAssetBalance.toString()).shiftedBy(-poolMetadata.assetTokenInfo.decimals);
    },
    data => {
      const walletNativeBalance: bigint = multiCall3Interface.decodeFunctionResult("getEthBalance", data)[0];
      ret.walletNativeBalanceBN = BigNumber(ethers.formatEther(walletNativeBalance));
    }
  ];

  const assetsInUiAmountBN = BigNumber(assetsInUiAmount);
  if (!assetsInUiAmountBN.isNaN() && assetsInUiAmountBN.gt(0)) {
    const assetsInParam = assetsInUiAmountBN
      .shiftedBy(poolMetadata.assetTokenInfo.decimals)
      .decimalPlaces(0, BigNumber.ROUND_DOWN)
      .toFixed();
    calls.push({
      target: poolMetadata.address,
      callData: liquidityBootstrapPoolInterface.encodeFunctionData("previewSharesOut", [assetsInParam])
    });
    applyResultFunctions.push(data => {
      const sharesOut: bigint = liquidityBootstrapPoolInterface.decodeFunctionResult("previewSharesOut", data)[0];
      ret.sharesOutBN = BigNumber(sharesOut.toString()).shiftedBy(-poolMetadata.shareTokenInfo.decimals);
    });
  }

  const multiCallRet = await multiCall3Contract.tryAggregate(false, calls);
  for (let i = 0; i < calls.length; i++) {
    if (multiCallRet[i][0]) {
      applyResultFunctions[i](multiCallRet[i][1]);
    }
  }

  return ret;
}


export async function checkRequirements(poolMetadata, walletAddress: string, totalSpendAssetUiAmount: BigNumber.Value) {
  const calls = [
    { target: poolMetadata.asset, callData: erc20Interface.encodeFunctionData("balanceOf", [walletAddress]) },
    { target: poolMetadata.asset, callData: erc20Interface.encodeFunctionData("allowance", [walletAddress, poolMetadata.address]) },
  ];

  const multiCall3Contract = new ethers.Contract(
    constants.MULTICALL3_CONTRACT[poolMetadata.chainId],
    multiCall3Interface,
    await web3Service.getProviderForChain(poolMetadata.chainId, false)
  );

  const multiCallRet = (await multiCall3Contract.aggregate(calls))[1];

  const totalSpend = BigInt(
    BigNumber(totalSpendAssetUiAmount)
      .shiftedBy(poolMetadata.assetTokenInfo.decimals)
      .decimalPlaces(0, BigNumber.ROUND_UP)
      .toFixed()
  );

  const balance: bigint = erc20Interface.decodeFunctionResult("balanceOf", multiCallRet[0])[0];
  const allowance: bigint = erc20Interface.decodeFunctionResult("allowance", multiCallRet[1])[0];

  return {
    totalSpend, balance, allowance,
    balanceIsOk: balance >= totalSpend,
    allowanceIsOk: allowance >= totalSpend,
    // balanceIsOk: true,
    // allowanceIsOk: false
  };
}


export async function createApproveTx(poolMetadata, walletAddress: string, amount: ethers.BigNumberish) {
  const assetTokenContract = new ethers.Contract(poolMetadata.asset, erc20Interface);
  const tx = await assetTokenContract.approve.populateTransaction(poolMetadata.address, amount);
  tx.from = walletAddress;
  tx.chainId = poolMetadata.chainId;
  return tx;
}


export async function createApproveTotalSpendTx(poolMetadata, walletAddress: string, uiAmount: BigNumber.Value) {
  const amount = BigNumber(uiAmount)
    .shiftedBy(poolMetadata.assetTokenInfo.decimals)
    .decimalPlaces(0)
    .toFixed();
  return createApproveTx(poolMetadata, walletAddress, amount);
}

export async function createApproveUnlimitedTx(poolMetadata, walletAddress: string) {
  return createApproveTx(poolMetadata, walletAddress, ethers.MaxUint256);
}


export async function createRevokeTx(poolMetadata, walletAddress: string) {
  return createApproveTx(poolMetadata, walletAddress, 0);
}


export async function createBuyTx(poolMetadata, walletAddress: string, assetsInUiAmount: BigNumber.Value, minSharesOutUiAmount: BigNumber.Value) {
  const assetsIn = BigNumber(assetsInUiAmount)
    .shiftedBy(poolMetadata.assetTokenInfo.decimals)
    .decimalPlaces(0, BigNumber.ROUND_DOWN)
    .toFixed();

  const minSharesOut = BigNumber(minSharesOutUiAmount)
    .shiftedBy(poolMetadata.shareTokenInfo.decimals)
    .decimalPlaces(0, BigNumber.ROUND_UP)
    .toFixed();

  const lbpContract = new ethers.Contract(poolMetadata.address, liquidityBootstrapPoolInterface);
  const tx = await lbpContract.swapExactAssetsForShares.populateTransaction(assetsIn, minSharesOut, walletAddress);
  tx.from = walletAddress;
  tx.chainId = poolMetadata.chainId;
  return tx;
}


export interface LBPTradeApiItem {
  walletAddress: string;
  type: string;
  blockNumber: number;
  blockHash: string;
  txHash: string;
  logIndex: number;
  status: string;
  timestamp: string;
  tokenIn: string;
  tokenInSym: string;
  tokenOut: string;
  tokenOutSym: string;
  tokenAmountIn: string;
  tokenAmountOut: string;
  swapFee: string;
  assetPriceValue: string;
  assetPriceProxyRoundId: string;
  assetPriceTimestamp: string;
  poolId: string;
  version: number;
  sharePriceInAsset: string;
  sharePriceInUsd: string;
}
export async function getTrades(chainId: number, poolAddress: string, walletAddress: string): Promise<LBPTradeApiItem[]> {
  try {
    const fetchTradesRet = await axios.get("/proxy/app.v2.fjordfoundry.com/api/trpc/swap.list", {
      params: {
        input: JSON.stringify({
          json: {
            limit: 1000,
            page: 1,
            address: poolAddress,
            walletAddress
          }
        })
      },
    });

    return fetchTradesRet.data.result.data.json.items;

  } catch (e) {
    return [];
  }
}
