import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from "@/views/Home.vue";
import Swap from "@/views/Swap.vue";
import CexAccounts from "@/views/CexAccounts.vue";
import CexWallet from "@/views/CexWallet.vue";
import CexBalanceWatchOrders from "@/views/CexBalanceWatchOrders.vue";
import Arbitrage2 from "@/views/Arbitrage2.vue";
import TestVueSelect from "@/views/TestVueSelect.vue";
import ArbitrageFocus from "@/views/ArbitrageFocus.vue";
import Feed from "@/views/Feed.vue";
import Debug from "@/views/Debug.vue";
import CexHedging from "@/views/CexHedging.vue";
import SwapSolana from "@/views/SwapSolana.vue";
import ArbitrageFocusSolana from "@/views/ArbitrageFocusSolana.vue";
import SwapTron from "@/views/SwapTron.vue";
import Hedging2 from "@/views/Hedging2.vue";
import PrivateKeyWallets from "@/views/PrivateKeyWallets.vue";
import WatchSwapLiquidityBootstrapPool from "@/views/extra/WatchSwapLiquidityBootstrapPool.vue";
import DelistTradeOrders from "@/views/DelistTradeOrders.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // { path: '/', name: 'home', component: Home },
  { path: '/', redirect: "/swap" },
  { path: '/swap', name: 'Swap', component: Swap },
  { path: '/swap-solana', name: 'SwapSolana', component: SwapSolana },
  { path: '/swap-tron', name: 'SwapTron', component: SwapTron },
  { path: '/cex-wallet', name: 'CexWallet', component: CexWallet },
  { path: '/cex-accounts', name: 'CexAccounts', component: CexAccounts },
  { path: '/cex-balance-watch-orders', name: 'CexBalanceWatchOrders', component: CexBalanceWatchOrders },
  { path: '/delist-trade-orders', name: 'DelistTradeOrders', component: DelistTradeOrders },
  { path: '/arbitrage', name: 'Arbitrage', component: Arbitrage2 },
  { path: '/arbitrage2', name: 'Arbitrage2', component: Arbitrage2 },
  { path: '/test-vue-select', name: 'TestVueSelect', component: TestVueSelect },
  { path: '/arbitrage-focus', name: 'ArbitrageFocus', component: ArbitrageFocus },
  { path: '/arbitrage-focus-solana', name: 'ArbitrageFocusSolana', component: ArbitrageFocusSolana },
  { path: '/feed', name: 'Feed', component: Feed },
  { path: '/debug', name: 'Debug', component: Debug },
  { path: '/cex-hedging', name: 'CexHedging', component: CexHedging },
  { path: '/simple-hedging', redirect: "/cex-hedging" },
  { path: '/hedging2', name: 'Hedging2', component: Hedging2 },
  { path: '/private-key-wallets', name: 'PrivateKeyWallets', component: PrivateKeyWallets },
  { path: '/extra/watch-swap-lbp', name: 'WatchSwapLiquidityBootstrapPool', component: WatchSwapLiquidityBootstrapPool },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
