<template>
  <div class="p-2">
    <small class="text-secondary">build at: {{ buildAt }}</small>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import {Component, Vue} from 'vue-property-decorator';
  import _ from "lodash";
  import BaseComponent from "@/components/BaseComponent";
  import {DateTime} from "luxon";

  @Component
  export default class Footer extends BaseComponent {

    buildAt = DateTime.fromISO("2024-09-28T04:00:00.000Z").toFormat("yyyy-MM-dd HH:mm");

    mounted() {

    }

  }
</script>
