<template>
  <div>
    <div v-if="isLoading" class="text-center text-secondary">Loading...</div>
    <template v-else>
      <draggable v-model="cexAccounts" ghost-class="bg-ghost" class="mb-3">
        <div v-for="acc in cexAccounts" :key="acc.id" class="border-bottom py-1" style="cursor: move;">
          <img :src="exchangeLogos[acc.exchange]" :alt="exchangeNames[acc.exchange]" class="mr-1" style="width: 20px; height: 20px;">
          {{ acc.label }}
        </div>
      </draggable>
      <div class="text-center">
        <b-button variant="primary" :disabled="isSubmitting" @click="submit">Save</b-button>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.bg-ghost {
  background-color: #fffeaf;
}
</style>

<script lang="ts">
  import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
  import _ from "lodash";
  import draggable from "vuedraggable";
  import BaseComponent from "@/components/BaseComponent";
  import * as cexAccountService from "@/services/cexAccountService";
  import * as constants from "@/constants";

  @Component({
    components: { draggable }
  })
  export default class ArrangeCexAccountsModal extends BaseComponent {

    @Prop({ required: true }) modalId!: string;

    exchangeNames = constants.exchangeNames;
    exchangeLogos = constants.EXCHANGE_LOGO;

    isLoading = true;
    cexAccounts = [];

    isSubmitting = false;

    mounted() {
      this.fetchAll();
    }

    async fetchAll() {
      try {
        this.isLoading = true;
        this.cexAccounts = await cexAccountService.getAll();

      } catch (e) {
        console.error(e);
        this.toastError(e);

      } finally {
        this.isLoading = false;
      }
    }

    async submit() {
      try {
        this.isSubmitting = true;
        const updateAccs = this.cexAccounts.map((acc, index) => ({
          id: acc.id,
          showOrder: index
        }));
        await cexAccountService.editShowOrder(updateAccs);
        this.$bvModal.hide(this.modalId);
        this.done();

      } catch (e) {
        console.error(e);
        this.toastError(e);

      } finally {
        this.isLoading = false;
      }
    }

    @Emit()
    done() {

    }

  }
</script>
